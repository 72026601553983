/*=====================
Page Landing Area 
=======================*/
.landing-hero-area{
    &.hero-bg-area{
        background-image: url(/assets/images/preview/landing-hero-bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
    }
    .landing-hero-inner{
        .spacing--1{
            height: 182px;
        }
    }
}

/* Header Area  */


.pre-header-area{
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    top: 0;
    padding: 0 100px;
    padding-top: 30px;
    z-index: 22;
    @media #{$md-layout} {
        padding: 0 30px;
        padding-top: 10px;
    }
    @media #{$sm-layout} {
        padding: 0 30px;
        padding-top: 30px;
    }
    button {
        &.ar-button {
            @media #{$sm-layout} {
                padding: 0 20px;
            }
        }
    }
    .mainmenu{
        ul {
            &.menu{
                @extend %liststyle;
                display: flex;
                align-items: center;
                justify-content: center;
                li{
                    padding: 50px 26px;
                    a{
                        color: #fff;
                        position: relative;
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        &::after {
                            content: "";
                            width: 0;
                            height: 1px;
                            bottom: -3px;
                            position: absolute;
                            left: auto;
                            right: 0;
                            z-index: -1;
                            background-color: currentColor;
                            -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
                            transition: all .3s cubic-bezier(.645,.045,.355,1);
                        }
                    }
                    &:hover {
                        a{
                            &::after{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}










/*=====================
    Filter Button 
======================*/


.demo-tab-button{
    text-align: center;
    li{
        &.react-tabs__tab{
            padding: 0;
            border: 0 none;
            position: relative;
            border: 0 none;
            outline: none;
            background: transparent;
            border-radius: inherit;
            span{
                &.filter-text{
                    font-weight: 600;
                    line-height: 1.58;
                    font-size: 16px;
                    color: #333333;
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    position: relative;
                    display: inline-block;
                    padding: 5px 26px;
                }
                &.filter-counter{
                    position: absolute;
                    top: 0;
                    left: 50%;
                    visibility: hidden;
                    margin: 0 auto;
                    min-width: 34px;
                    height: 24px;
                    line-height: 25px;
                    border-radius: 3px;
                    font-size: 12px;
                    background-color: $theme-color;
                    color: #fff;
                    text-align: center;
                    opacity: 0;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    font-weight: 600;

                    &::before{
                        position: absolute;
                        right: 0;
                        bottom: -4px;
                        left: 0;
                        display: block;
                        margin: 0 auto;
                        width: 0;
                        height: 0;
                        border-top: 4px solid $theme-color;
                        border-right: 4px solid transparent;
                        border-left: 4px solid transparent;
                        content: "";
                        border-top-color: $theme-color;
                        border-top: 4px solid $theme-color;
                        border-top-color: $theme-color;
                    }
                }
            }
            &.react-tabs__tab--selected ,
            &:hover {
                span{
                    &.filter-text{
                        color: $theme-color;
                    }
                    &.filter-counter{
                        visibility: visible;
                        opacity: 1;
                        -webkit-transform: translate(-50%, -100%);
                        -ms-transform: translate(-50%, -100%);
                        transform: translate(-50%, -100%);
                    }
                }
            }
        }
    }
}


/*=================
Demo Single 
=================*/
.demo-single{
    margin-top: 50px;
    @media #{$sm-layout} {
        margin-top: 20px;
    }
    img{
        position: relative;
        margin-bottom: 29px;
        box-shadow: 0 5px 43px rgba(51,51,51,.1);
        border-radius: 8px;
        @extend %transition;
    }
    .title{
        text-align: center;
        text-transform: capitalize;
        a{
            font-size: 20px;
            line-height: 1.39;
            margin-bottom: 0;
            color: #2e3280;
        }
    }
    &:hover{
        img{
            box-shadow: 0 41px 43px rgba(51,51,51,.1);
            transform: translateY(-20px);
        }
    }
}


/*----------------------
    Footer Area  
------------------------*/

.pre-footer-area {
    &.footer-bg {
        background: url(/assets/images/preview/preview-footer.jpg);
        background-size: cover;
        background-repeat: no-repeat;
    }
    .inner{
        h2{

        }
        h6 {
            letter-spacing: 7px;
            font-weight: 700;
        }
    }
}
















