/*====================
    Demo Option 
=====================*/

.demo-option {
    position: fixed;
    top: 0;
    left: 100%;
    background: #fff;
    height: 100%;
    width: 350px;
    max-width: calc(100% - 45px);
    z-index: 100000;
    -webkit-transition: all .5s;
    transition: all .5s;
    .demo-optiontoolbar{
        position: absolute;
        top: 200px;
        right: 100%;
        display: block;
        width: 40px;
        line-height: 60px;
        border: 1px solid #eee;
        border-right: 0;
        border-radius: 5px 0 0 5px;
        background: #fff;
        text-align: center;
        box-shadow: -3px 0 10px -2px rgba(0,0,0,.1);
    }

    .demo-panel{
        height: 100%;
        overflow-y: auto;
        padding-bottom: 55px;
    }
    &.active{
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        box-shadow: -3px 0 50px -2px rgba(0,0,0,.14);  
    }

}









