/*========================
    Button Area 
==========================*/

a ,
button{
    &.ar-button{
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0em;
        border-color: transparent;
        color: #fff;
        background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
        background-size: 200% auto;
        background-position: left center;
        padding: 0 36px;
        height: 56px;
        line-height: 56px;
        border-width: 0;
        border-radius: 5px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        display: inline-block;
        svg{
            font-size: 20px;
        }
        &:hover{
            border-color: transparent;
            color: #fff;
            background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
            background-position: right center;
            color: #fff;
            transform: translateY(-3px);
        }
        &.style-white {
            color: $theme-color;
            background-image: inherit;
            background: #fff;
        }

        &.button-wih-border{
            background: transparent;
            border-width: 1px;
            line-height: 46px;
            color: #5945e6;
            border-color:#5945e6;
            &.border--2 {
                border-width: 2px;
            }
            &:hover{
                border-color: transparent;
                background-color: #5945e6;
                background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
                background-size: 200% auto;
                background-position: left center;
                color: #ffffff;
            }
        }

        &.xs-small {
            padding: 0 20px;
            height: 40px;
            line-height: 40px;
            font-size: 13px;
        }
        &.sm-size {
            padding: 0 30px;
            height: 48px;
            line-height: 48px;
            font-size: 14px;
        }
        &.large-size {
            padding: 0 40px;
            height: 64px;
            line-height: 64px;
            font-size: 16px;
        }
        &.custom-size{
            padding: 0 36px;
            height: 56px;
            line-height: 56px;
            border-width: 0;
            border-radius: 5px;
            min-width: 280px;
        }
        &.full-width{
            width: 100%;
            @media #{$sm-layout} {
                width: auto;
            }
        }

        &.solid-style{
            border-color: #5945e6;
            background: transparent;
            border-style: solid;
            border-width: 2px;
            border-radius: 5px;
            background-position: left center;
            background-origin: border-box;
            .button-text{
                color: $theme-color;
            }
            &:hover{
                background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
                border-color: transparent;
                background-size: 200% auto;
                background-position: left center;
                .button-text{
                    color: #fff;
                }
            }
        }

        &.rectangle{
            -moz-border-radius: 0;
            -webkit-border-radius: 0;
            border-radius: 0;
        }
        &.buttet{
            -moz-border-radius: 30px;
            -webkit-border-radius: 30px;
            border-radius: 30px;
        }

        &.primary{
            border-color: #5945e6;
            background: #5945e6;
        }

        &.secondary{
            border-color: #38cb89;
            background: #38cb89;
        }

        &.custom{
            background: #222;
        }

        &.white{
            -moz-box-shadow: 0 0 30px 5px rgba(0,0,0,0.07);
            -webkit-box-shadow: 0 0 30px 5px rgba(0,0,0,0.07);
            box-shadow: 0 0 30px 5px rgba(0,0,0,0.07);
            color: #222;
            background: #fff;
        }

        &.gradient{
            background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#FF3F85),color-stop(100%,#FAA720));
            background: -moz-linear-gradient(150deg,#FF3F85 0,#FAA720 100%);
            background: -webkit-linear-gradient(150deg,#FF3F85 0,#FAA720 100%);
            background: -o-linear-gradient(150deg,#FF3F85 0,#FAA720 100%);
            background: -ms-linear-gradient(150deg,#FF3F85 0,#FAA720 100%);
            background: linear-gradient(150deg,#FF3F85 0,#FAA720 100%);
        }

        &.with-icon{
            i{
                margin-left: 7px;
                font-size: 15px;
            }
        }

        &.with-left{
            i{
                margin-right: 7px;
                font-size: 15px;
            }
        }

    }

    &.download-btn{
        border-color: #38cb89;
        background: #38cb89;
        font-size: 15px;
        font-weight: 600;
        padding: 0 36px;
        height: 56px;
        line-height: 56px;
        border-radius: 5px;
        display: inline-block;
        color: #fff;
        .icon{
            font-size: 15px;
            display: inline-block;
            margin-right: 7px;
        }
        span{
            font-size: 15px;
        }
        &:hover{
            transform: translateY(-3px);
        }
    }
}

.pricing-table button.ar-button{ 
    border-width: 1px;
    line-height: 46px;
    padding: 0 30px;
    height: 48px;
}




button{
    &.video-button {
        border: 0 none;
        outline: none;
        @extend %transition;
        svg{
            color: #38cb89;
            font-size: 18px;
        }
        span{
            margin-top: 0;
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            font-size: 15px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 3px;
        }
        &:hover{
            color: $theme-color;
        }
    }
}


/*======================
    Button Group 
========================*/
.button-group {
    margin: -10px;

    .button-image {
        border: 0 none;
        padding: 0;
        margin: 10px;
        @extend %transition;
        img{
            border-radius: 5px;
        }
        &:hover {
            transform: translateY(-3px);
        }
    }
}


/*=======================
    Video Popup 
=========================*/

.video-popup-button {
    .video-popup {
        min-width: 260px;
        border-color: transparent;
        color: #fff;
        background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
        background-size: 200% auto;
        background-position: left center;
        padding: 0 36px;
        height: 56px;
        line-height: 56px;
        border-width: 0;
        border-radius: 5px;
        @extend %transition;
        svg {
            font-size: 15px;
            margin-right: 10px;
            
        }
        &:hover {
            background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
            background-position: right center;
            color: #fff;
        }
    }
    &.button-transparent{
        .video-popup {
            min-width: 260px;
            border-color: transparent;
            color: $body-color;
            background-image: inherit;
            padding: 0 6px;
            height: inherit;
            line-height: inherit;
            border-radius: 0;
            box-shadow: inherit;

            svg {
                color: #FF4D24;
            }
            span{
                margin-top: 0;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                font-size: 15px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 3px;
            }
            &:hover {
                background-image: inherit;
                background-position: right center;
                color: $theme-color;
                transform: inherit;
            }
        }
    }
}


/*====================
    Button Group 
======================*/

.button-group {
    margin: -10px;
    .ar-button{
        margin: 10px;
    }
}














