/*=================
Blog Area 
===================*/

.blog {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 32px rgba(51, 51, 51, 0.07);
    margin-bottom: 30px;
    .thumbnail{
        a{
            img{
                max-width: 100%;
            }
        }
    }
    .content {
        padding: 24px 30px 50px;
        span {
            &.category {
                margin-bottom: 6px;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 2px;
                display: block;
                color: #b6b7d2;
            }
        }
        h4{
            &.title{
                a{
                    font-size: 24px;
                    line-height: 1.5;
                    margin-bottom: 60px;
                    color: $heading-color;
                }
            }
        }
        .post-meta{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .meta-info{
                display: flex;
                align-items: center;
                img{
                    width: 32px;
                    vertical-align: middle;
                    margin-right: 6px;
                    border-radius: 50%;
                }
                h6{
                    &.author{
                        margin-bottom: 0;
                        color: #7e7e7e;
                        font-weight: 400;
                    }
                }
            }
            .date{
                span{
                    color: #7e7e7e;
                }
            }
        }

    }
}









