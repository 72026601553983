/*==========================
    Message Box Area 
=============================*/

.message-box{
    background: rgba(91, 99, 254, 0.1);
    position: relative;
    padding: 27px 30px 27px 50px;
    font-weight: 600;
    border-radius: 5px;
    .icon {
        position: absolute;
        top: 30px;
        left: 20px;
        font-size: 20px;
        line-height: 1;
        i {
            color: #5b63fe;
        }
    }
    p {
        color: #5b63fe;
    }

    &.success{
        background: rgba(56, 203, 137, 0.1);
        .icon {
            i {
                color: #38cb89;
            }
        }
        p {
            color: #38cb89;
        }
    }

    &.danger{
        background: rgba(254, 40, 84, 0.1);
        .icon {
            i {
                color: #fe2854;
            }
        }
        p {
            color: #fe2854;
        }
    }

    &.warning{
        background: rgba(255, 187, 0, 0.1);
        .icon {
            i {
                color: #fb0;
            }
        }
        p {
            color: #fb0;
        }
    }
}










