/*====================
    Link Css 
=====================*/
.theme-gradient {
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#5E60E7),color-stop(100%,#9C7AF2));
    background: -moz-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
    background: -webkit-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
    background: -o-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
    background: -ms-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
    background: linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
}
.theme-gradient--2 {
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#8383ED),color-stop(65%,#B095F5));
    background: -moz-linear-gradient(-259deg,#8383ED 0,#B095F5 65%);
    background: -webkit-linear-gradient(-259deg,#8383ED 0,#B095F5 65%);
    background: -o-linear-gradient(-259deg,#8383ED 0,#B095F5 65%);
    background: -ms-linear-gradient(-259deg,#8383ED 0,#B095F5 65%);
    background: linear-gradient(-259deg,#8383ED 0,#B095F5 65%);
}


.backto-top{
    div{
        color: #fff;
        background-color: #9C7AF2;
        background-image: linear-gradient(-218deg,#9C7AF2 0,#5E61E7 50%,#9C7AF2 100%);
        background-size: 200% auto;
        background-position: left center;
        position: fixed;
        right: 30px;
        bottom: -60px;
        z-index: 9999;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        display: block;
        padding: 0;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        text-align: center;
        font-size: 25px;
        line-height: 54px;
        cursor: pointer;
        i {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #ffffff;
            transform: translate(-50%, -50%);
            
        }
    }
}


a{
    &.link-hover {
        display: inline-block;
        position: relative;
        z-index: 1;
        &::after{
            content: '';
            width: 0;
            height: 1px;
            bottom: 0;
            position: absolute;
            left: auto;
            right: 0;
            z-index: -1;
            transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
            background: currentColor;
        }
        &:hover{
            &::after{
                width: 100%;
                left: 0;
                right: auto;
            }
        }
        
    }
}








