/*----------------------------
Newsletter Area 
----------------------------*/

.branding-newsletter {
    background: linear-gradient(-259deg,#5E60E7 0,#9C7AF2 65%);
}

.branding-newsletter button.ar-button {
    background: #ffffff;
    color: #5E60E7;
}

.newsletter-form--1{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -10px 0;
    @media #{$sm-layout} {
        text-align: center;
    }
    .form-item {
        margin: 10px;
        @media #{$sm-layout} {
            display: block;
            margin: 10px 0;
        }
        input {
            border-color: transparent;
            background: rgba(0, 0, 0, 0.07);
            min-width: 275px;
            width: 100%;
            outline: none;
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 3px 20px;
            height: 56px;
            border: 0 none;
            outline: none;
            @media #{$md-layout} {
                min-width: 200px;
            }
            
        }
        &.button {
            background: #fff;
            min-width: 180px;
            color: #5945e6;
            padding: 0 41px;
            height: 56px;
            line-height: 54px;
            outline: none;
            border-width: 1px;
            border-style: solid;
            border-radius: 5px;
            cursor: pointer;
            outline: none;
            font-weight: 600;
            @media #{$md-layout} {
                margin: 0 auto;
            }
            @media #{$sm-layout} {
                margin: 0 auto;
            }
            &.ar-button {
                @media #{$md-layout} {
                    margin: 0 auto;
                }
                @media #{$sm-layout} {
                    margin: 0 auto;
                }
            }
        }
    }
}

.newsletter-form--1 .form-item input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ffffff;
}
.newsletter-form--1 .form-item input::-moz-placeholder { /* Firefox 19+ */
    color: #ffffff;
}
.newsletter-form--1 .form-item input:-ms-input-placeholder { /* IE 10+ */
    color: #ffffff;
}

.branding-newsletter .newsletter-form--1 .form-item input{
    color: #ffffff;
}


.newsletter-area  {
    .next-arrowland {
        &.title {
            margin-bottom: 0;
        }
    }
}

/*==========================
    Appshowcase Newsletter 
============================*/
.appshowcase-newsletter .newsletter-form--1 .form-item input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #7e7e7e;
}
.appshowcase-newsletter .newsletter-form--1 .form-item input::-moz-placeholder { /* Firefox 19+ */
    color: #7e7e7e;
}
.appshowcase-newsletter .newsletter-form--1 .form-item input:-ms-input-placeholder { /* IE 10+ */
    color: #7e7e7e;
}
