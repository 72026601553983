/*=======================
    Newsletter Area 
=========================*/

.next-arrowland {
    &.title {
        margin-top: 45px;
        font-size: 18px;
        a {
            color: #38cb89;
        }
    }
}
