/*===========================
    Wavify Area 
============================*/

/*===========================
    Sass Wavify Area 
============================*/
.section-wrapper-wayvify {
    position: relative;
    z-index: 3;
    background: -webkit-linear-gradient(top,#FFF 0,#F6F5F9 74%,#F6F5F9 100%);
    .service-wavify {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        svg{
            height: 79px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin-bottom: -49px;
            z-index: -1;
        }
    }

    &.wayvify-style--2 {
        margin-bottom: 60px;
        .service-wavify{
            svg{
                margin-bottom: -78px;
            }
        }
    }

}


.wayvify-style--3 {
    position: relative;
    z-index: 3;
    top: 33px;
    @media #{$sm-layout} {
        display: none;
    }
    .service-wavify {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 103%;
        transform: rotate(-180deg);
        svg{
            height: 79px;
            position: absolute;
            left: 0;
            right: 0;
            z-index: -1;
            margin-top: -79px;
        }
    }
   
}

.brand-pricing-table-area  {
    &.wayvify-style--3  {
        top: 0;
    }
}


