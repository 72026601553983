/* Banner Area */

.slider-fixed-height{
    height: 950px;
    @media #{$lg-layout} {
        height: 750px;
    }
    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
    }
    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
    }
}

.slide {
    &.slider--style--1 {
        margin-top: -80px;
        padding-left: 150px;
        padding-right: 150px;
        display: flex;
        align-items: center;
        @media #{$lg-layout}{
            margin-top: 0;
            padding-left: 100px;
            padding-right: 100px;
        }
        @media #{$md-layout}{
            margin-top: 0;
            padding-left: 50px;
            padding-right: 50px;
        }
        @media #{$sm-layout}{
            margin-top: 0;
            padding: 40px 30px;
        }

        .slide-text-centent{
            h2 {
                &.title {
                    font-size: 72px;
                    line-height: 1.17;
                    color: #333;
                    margin-bottom: 30px;
                    @media #{$lg-layout} {
                        font-size: 60px;
                    }
                    @media #{$md-layout} {
                        font-size: 49px;
                    }
                    @media #{$sm-layout} {
                        font-size: 36px;
                    }
                    @media #{$large-mobile} {
                        font-size: 27px;
                        line-height: 38px;
                        margin-bottom: 20px;
                    }
                }
            }
            p {
                &.description {
                    margin-bottom: 36px;
                    max-width: 490px;
                    line-height: 1.78;
                    color: #696969;
                    font-size: 18px;
                }
            }
        }
    }
}



// Slider Sass Styles
.slider--sass {
    .slider-fixed-height {
        height: 1200px;
        @media #{$md-layout} {
            height: auto;
        }
        @media #{$sm-layout} {
            height: auto;
        }
    }
    .content {
        padding-top: 180px;
        
        @media #{$md-layout} {
            padding-top: 40px;
        }
        @media #{$sm-layout} {
            padding-top: 40px;
        }
        h2 {
            color: #fff;
            line-height: 1.3;
            font-weight: 300;
        }
        p {
            color: #fff;
            font-weight: 400;
            padding-top: 0px;
            padding-bottom: 19px;
            font-size: 18px;
        }
    }
    .thumbnail {
        padding: 95px 15px 0;
        -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
        transition: all .3s cubic-bezier(.645,.045,.355,1);
        @media #{$md-layout} {
            padding: 30px 15px 0;
        }
        @media #{$sm-layout} {
            padding: 30px 15px 0;
        }
    }
}

.slick-active {
    .slider--sass {
        .content {
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
            -webkit-animation-delay: .5s;
            animation-delay: .5s;
        }
        .thumbnail{
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
            -webkit-animation-delay: .5s;
            animation-delay: .5s;
        }
    }
}



// Slider Start Up 
.slider--startups {
    padding-left: 150px;
    position: relative;
    z-index: 2;
    padding-top: 193px;
    padding-bottom: 97px;
    @media #{$sm-layout} {
        padding-left: 30px;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-right: 30px;
    }
    @media #{$md-layout} {
        padding-left: 30px;
        padding-top: 100px;
        padding-bottom: 76px;
        padding-right: 30px;
    }
    @media #{$lg-layout} {
        padding-left: 30px;
        padding-top: 100px;
        padding-bottom: 200px;
        padding-right: 30px;
    }
    .slider-content{
        width: 530px;
        max-width: 100%;
        .inner {
            margin-top: 110px;
            @media #{$md-layout} {
                margin-top: 40px;
            }
            @media #{$sm-layout} {
                margin-top: 40px;
            }
            h1 {
                &.title {
                    font-size: 56px;
                    line-height: 1.29;
                    color: #fff;
                    margin-bottom: 15px;
                    @media #{$sm-layout} {
                        font-size: 36px;
                    }
                }
            }
            p {
                &.description {
                    font-size: 18px;
                    line-height: 1.78;
                    color: #fff;
                    margin-bottom: 45px;
                }
            }
        }
    }
    .thumbnail{
        padding-right: 50px;
        @media #{$sm-layout} {
            padding-right: 0;
            margin-top: 30px;
        }
        @media #{$md-layout} {
            padding-right: 0;
            margin-top: 30px;
        }
        @media #{$lg-layout} {
            padding-right: 0;
            margin-top: 30px;
        }
    }
    .wavify-position {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        .service-wavify {
            svg {
                height: 150px;
            }
        }
    }
    .button-group {
        .aro-btn-second {
            margin-left: 20px;
            h6 {
                padding-right: 10px;
            }
        }
    }
}


/* Slider App Showcase */

.slider--appshowcase {
    position: relative;
    .svg-separator {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
        line-height: 0;
        bottom: 0;
        @media #{$md-layout} {
            display: none;
        }
        @media #{$sm-layout} {
            display: none;
        }
        svg{
            height: 178px;
            fill: #fff;
            width: 100%;
        }
    }
    .slider-content {
        .inner {
            h2 {
                &.title {
                    font-size: 56px;
                    line-height: 1.29;
                    font-weight: 300;
                    color: #fff;
                    margin-bottom: 15px;
                    @media #{$sm-layout} {
                        font-size: 34px;
                    }
                }
            }
            p {
                &.description {
                    font-size: 18px;
                    line-height: 1.78;
                    color: #fff;
                }
            }
        }
        .thumbnail {
            padding: 95px 15px 0;
            @media #{$md-layout} {
                padding: 30px 15px 0;
            }
            @media #{$sm-layout} {
                padding: 30px 15px 0;
            }
        }
    }
    &.fullscreen {
        height: 1292px;
        padding-top: 90px;
        @media #{$md-layout} {
            min-height: auto;
            height: auto;
            padding: 50px 0;
            padding-top: 90px;
        }
        @media #{$sm-layout} {
            min-height: auto;
            height: auto;
            padding: 50px 0;
            padding-top: 90px;
        }
    }
}




/* Slider Software */
.slider--software{
    .slider-text{
        max-width: 500px;
        margin: 0 auto;
        padding-left: 54px;
        @media #{$md-layout} {
            max-width: 100%;
            padding-top: 50px;
        }
        @media #{$sm-layout} {
            max-width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 50px;
        }
        h2{
            &.title{
                font-weight: 500;
                font-size: 56px;
                line-height: 72px;
                margin-bottom: 26px;
                @media #{$sm-layout} {
                    font-size: 31px;
                    line-height: 48px;
                    margin-bottom: 16px;
                }
                @media #{$lg-layout} {
                    font-size: 44px;
                    line-height: 53px;
                    margin-bottom: 10px;
                }
                @media #{$md-layout} {
                    font-size: 44px;
                    line-height: 53px;
                    margin-bottom: 10px;
                }
            }
        }
        p{
            &.description{
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 56px;
                @media #{$sm-layout} {
                    margin-bottom: 26px;
                }
            }
        }
    }
    .slider-wrapper {
        position: relative;
        z-index: 2;
        padding-top: 100px;
        .bg-shape-images{
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    &.fullscreen {
        height: 970px;
        margin-bottom: -80px;
        @media #{$lg-layout} {
            height: auto;
            margin-bottom: 40px;
            min-height: auto;
        }
        @media #{$md-layout} {
            height: auto;
            margin-bottom: 40px;
            min-height: auto;
        }
        @media #{$sm-layout} {
            height: auto;
            margin-bottom: 40px;
            min-height: auto;
        }
    }
}


/* Slider Payment */
.slider--payment{
    min-height: 1108px;
    position: relative;
    z-index: 2;
    @media #{$lg-layout} {
        min-height: 900px;
    }
    @media #{$md-layout} {
        min-height: 927px;
    }
    @media #{$sm-layout} {
        min-height: auto;
        padding-bottom: 241px;
    }
    .slider-content{
        padding-top: 312px;
        padding-left: 140px;
        max-width: 765px;
        @media #{$lg-layout} {
            padding-top: 150px;
            padding-left: 50px;
            padding-right: 30px;
        }
        @media #{$md-layout} {
            padding-top: 150px;
            padding-left: 50px;
            padding-right: 30px;
        }
        @media #{$sm-layout} {
            padding-top: 150px;
            padding-left: 30px;
            padding-right: 30px;
        }
        h1{
            &.title{
                font-size: 56px;
                font-weight: 500;
                margin-bottom: 20px;
                @media #{$sm-layout} {
                    font-size: 32px;
                    margin-bottom: 20px;
                    line-height: 39px;
                }
            }
        }
        p{
            &.description{
                font-weight: 400;
                font-size: 18px;
                margin-bottom: 50px;
            }
        }
    }
    .slider-thumbnail{
        position: absolute;
        right: -28px;
        top: 138px;
        z-index: -1;
        @media #{$sm-layout} {
            display: none;
        }
    }
}


// Slider App Landing 

.slider--applanding{
    .fullscreen {
        @media #{$sm-layout} {
            min-height: auto;
            width: 100%;
            padding-top: 150px;
            padding-bottom: 100px;
        }
        @media #{$md-layout} {
            min-height: auto;
            width: 100%;
            padding-top: 150px;
            padding-bottom: 100px;
        }
    }
}

.slide-app-landing {
    padding: 0 150px!important;
    @media #{$md-layout} {
        padding: 0 100px !important;
    }
    @media #{$sm-layout} {
        padding: 0 30px !important;
    }
    h1{
        &.title{
            font-size: 56px;
            line-height: 1.2;
            color: #fff;
            font-weight: 500;
            @media #{$sm-layout} {
                font-size: 38px;
            }
            @media #{$md-layout} {
                font-size: 38px;
            }
        }
    }
    h6{
        font-size: 18px;
        margin-top: 20px;
        color: #fff;
        padding-right: 40%;
        font-weight: 400;
        line-height: 1.74;
        @media #{$sm-layout} {
            padding-right: 0;
        }
        @media #{$md-layout} {
            padding-right: 0;
        }
    }
    button {
        &.ar-button {
            margin-top: 30px;
        }
    }
}






// Ebook Slider 
.slider--ebook {
    height: 970px;
    @media #{$lg-layout} {
        height: auto;
        padding: 100px 0;
    }
    @media #{$md-layout} {
        height: auto;
        padding: 100px 0;
        margin-top: 20px;
    }
    @media #{$sm-layout} {
        height: auto;
        padding: 100px 0;
        margin-top: 20px;
    }
    .content {
        width: 490px;
        padding-left: 250px;
        box-sizing: content-box;
        @media #{$lg-layout} {
            width: 500px;
            padding-left: 50px;
        }
        @media #{$md-layout} {
            width: 500px;
            padding-left: 50px;
        }
        @media #{$sm-layout} {
            width: 500px;
            padding-left: 50px;
        }
        @media #{$large-mobile} {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
        }
        .inner {
            > span {
                color: #6d70a6;
                font-size: 14px;
                font-weight: 500;
                padding-bottom: 40px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
            }
            h2{
                &.title{
                    font-weight: 500;
                    color: #2e3280;
                    line-height: 1.3;
                    font-size: 56px;
                    margin-bottom: 50px;
                    @media #{$lg-layout} {
                        font-size: 41px;
                        margin-bottom: 30px;
                        margin-top: 15px;
                    }
                    @media #{$md-layout} {
                        font-size: 42px;
                        margin-bottom: 20px;
                    }
                    @media #{$sm-layout} {
                        font-size: 42px;
                        margin-bottom: 20px;
                    }
                    @media #{$large-mobile} {
                        font-size: 33px;
                    }
                }
            }
        }
    }
    .thumbnail {
        padding-right: 100px;
        @media #{$lg-layout} {
            padding-right: 30px;
        }
        @media #{$md-layout} {
            padding-right: 30px;
        }
        @media #{$sm-layout} {
            padding-right: 30px;
        }
    }
}


/* Slider Clinic  */

.slider--clinic {
    position: relative;
    .clinic-hero-wrapper {
        overflow: hidden;
        padding: 205px 0 110px;
        background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#fff),color-stop(80%,#eceefa));
        @media #{$md-layout} {
            padding: 100px 0 110px;
        }
        @media #{$sm-layout} {
            padding: 100px 0 110px;
        }
        @media #{$lg-layout} {
            padding: 100px 0 110px;
        }
        .content {
            .inner {
                h1 {
                    line-height: 1.29;
                    font-weight: 700;
                    margin-bottom: 10px;
                    font-size: 56px;
                    @media #{$sm-layout} {
                        font-size: 38px;
                    }
                }
                p {
                    line-height: 1.78;
                    color: #6d70a6;
                    font-size: 18px;
                }
                .form-wrapper {
                    display: flex;
                    justify-content: center;
                    background: #fff;
                    box-shadow: 0 18px 40px rgba(51,51,51,.1);
                    padding: 12px 10px;
                    border-radius: 8px;
                    display: -webkit-box;
                    -webkit-box-flex: 1;
                    flex-grow: 1;
                    flex-basis: 0;
                    width: 970px;
                    justify-content: space-between;
                    margin: 0 auto;
                    margin-top: 50px;
                    @media #{$lg-layout} {
                        width: 100%;
                    }
                    @media #{$md-layout} {
                        width: 100%;
                    }
                    @media #{$sm-layout} {
                        width: 100%;
                        display: block;
                    }

                    .input-box {
                        padding: 10px;
                        position: relative;
                        -webkit-box-flex: 1;
                        flex-grow: 1;
                        input {
                            background-color: #f8f8f8;
                            font-size: 15px;
                            font-weight: 400;
                            letter-spacing: 0;
                            background: #f6f5f9;
                            border-color: #f6f5f9;
                            color: inherit;
                            width: 100%;
                            outline: none;
                            border: 1px solid #eee;
                            border-radius: 5px;
                            padding: 3px 20px;
                            padding-left: 45px;
                            height: 56px;
                            width: 370px;
                            
                            @media #{$lg-layout} {
                                width: 350px;
                            }
                            @media #{$md-layout} {
                                width: 233px;
                            }
                            @media #{$sm-layout} {
                                width: 100%;
                            }
                        }
                        i {
                            left: 28px;
                            right: auto;
                            top: 32px;
                            z-index: 2;
                            font-size: 15px;
                            position: absolute;
                        }
                    }
                }
            }
        }
        .clinic-slide-bottom {
            margin-bottom: -115px;
            position: relative;
            padding-top: 90px;
            @media #{$sm-layout} {
                display: none;
            }
            .svg-bottom-image {
                position: absolute;
                width: 100%;
                left: 0;
                z-index: 1;
                line-height: 0;
                bottom: 0;
                svg {
                    height: 220px;
                    fill: #fff;
                    z-index: 0;
                    width: 100%;
                    overflow: hidden;
                }
            }
            .clinic-hero-image {
                img {
                    position: relative;
                    z-index: 4;
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}




// Digital Store 
.slider--digitalstore{
    padding: 200px 0;
    padding-bottom: 120px;
    @media #{$lg-layout} {
        padding: 100px 0;
        padding-top: 170px;
    }
    @media #{$md-layout} {
        padding: 100px 0;
        padding-top: 150px;
    }
    @media #{$sm-layout} {
        padding: 100px 0;
        padding-top: 100px;
    }
    .content {
        @media #{$lg-layout} {
            padding-top: 0;
        }
        @media #{$md-layout} {
            padding-top: 0;
        }
        @media #{$sm-layout} {
            padding-top: 0;
        }
      
        .inner {
            h3 {
                line-height: 1.29;
                color: #fff;
                font-size: 50px;
                margin-bottom: 35px;

                @media #{$sm-layout} {
                    font-size: 31px;
                    font-weight: 700;
                    margin-bottom: 22px;
                    br {
                        display: none;
                    }
                }
            }
            .slide-image-wrapepr {
                justify-content: center;
                .single-box{
                    padding: 10px;
                    p {
                        line-height: 1.78;
                        color: #fff;
                        font-size: 18px;
                    }
                    img {

                    }
                }
            }
        }
        .thumbnail {
            margin-top: 95px;
            @media #{$lg-layout} {
                margin-top: 30px;
            }
            @media #{$md-layout} {
                margin-top: 20px;
            }
            @media #{$sm-layout} {
                margin-top: 20px;
            }
           
        }
    }
}


// Digital Agency 
.slider--digitalagency{
    padding: 230px 0;
    @media #{$sm-layout} {
        padding: 100px 0;
        margin-top: 20px;
    }
    @media #{$md-layout} {
        padding: 100px 0;
        margin-top: 20px;
    }
    @media #{$lg-layout} {
        padding: 150px 0;
        padding-top: 200px;
    }
    .wrapper {
        .inner{
            h2 {
                color: #fff;
                font-size: 27px;
                margin-bottom: 0;
            }
            h1 {
                color: #fff;
                font-size: 60px;
                font-weight: 700;
                margin-bottom: 40px;
                @media #{$sm-layout} {
                    font-size: 31px;
                    font-weight: 700;
                    margin-bottom: 22px;
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}
