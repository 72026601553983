/*===================== 
    List Style Area 
=======================*/

ul{
    &.list {
        @extend %liststyle;
        li {
            margin: 10px 0;
            svg {    
                color: #38cb89;
            }
            span {
                font-size: 14px;
                line-height: inherit;
                font-weight: 400;
                color: #696969;
                margin-left: 11px;
            }
        }
    }
}









