/*====================
    Portfolio Area 
======================*/

.portfolio{
    position: relative;
    @extend %transition;
    z-index: 2;
    margin-bottom: 30px;
    .thumbnail{
        img{
            border-radius: 5px;
            width: 100%;
        }
    }
    .content{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        &::before {
            background-color: #9C7AF2;
            background-image: linear-gradient(-129deg,#9C7AF2 0,#5E61E7 100%);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            visibility: hidden;
            opacity: 0;
            border-radius: inherit;
            @extend %transition;
            z-index: -1;
            content: "";
            border-radius: 5px;
        }
        .inner {
            position: absolute;
            top: 50%;
            width: 100%;
            text-align: center;
            transform: translateY(-50%);
            padding: 0 15px;
            h4 {
                &.title {
                    opacity: 0;
                    transition: .3s ease-out 0s;
                    transform: translateY(10px);
                    font-size: 24px;
                    line-height: 1.5;
                    margin: 0;
                    @media #{$sm-layout} {
                        font-size: 17px;
                    }
                    @media #{$md-layout} {
                        font-size: 17px;
                    }
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    &:hover{
        border-radius: 5px;
        .content{
            &::before{
                visibility: visible;
                opacity: 1;
            }
            .inner {
                h4 {
                    &.title {
                        opacity: 1;
                        transition: .5s ease-out .2s;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}

/*=========================
Portfolio With Caption 
===========================*/

.portfolio-with-caption{
    @extend %transition;
    background: #ffffff;
    margin-bottom: 30px;
    .thumbnail{
        img{
            border-radius: 5px;
            width: 100%;
        }
    }
    .content{
        padding: 32px 30px 39px;
        @media #{$sm-layout} {
            padding: 20px;
        }
        .inner {
            p{
                &.category{
                    margin-bottom: 13px;
                    color: #b6b7d2;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
            }
            h4 {
                &.title {
                    margin-bottom: 0;
                    a {
                        font-size: 24px;
                        margin: 0;
                        color: $heading-color;
                        @media #{$sm-layout} {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
    &:hover{
        transform: translateY(-5px);
    }
}

.portfolio-metro-grid{
    .row{
        grid-template-columns: repeat( 3,1fr);
        display: grid;
        .portfolio-metor-grid{
            .portfolio{
                margin-bottom: 0;
            }

            &.grid-height-2{
                grid-column: span 2;
            }
            &.grid-height-2{
                grid-row: span 2;
            }
        }
    }
}


/*========================
Portfolio Details Area 
=========================*/

.portfolio-details-area {
    .thumbnail{
        img{
            max-width: 540px;
            @media #{$sm-layout} {
                max-width: initial;
            }
            @media #{$md-layout} {
                max-width: initial;
            }
        }
    }
    .content {
        span {
            &.category {
                color: #b6b7d2;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin: 3px 0 12px;
                display: inline-block;
            }
        }
        h2 {
            &.title {
                font-size: 34px;
                line-height: 1.42;
                margin-bottom: 25px;
            }
        }
        p{
            &.description{
                margin-bottom: 22px;
            }
        }
        .portfolio-details {
            margin-top: 38px;
            table {
                tbody {
                    tr {
                        td {
                            background: none;
                            padding: 17px 10px;
                            text-align: left;
                            &.label {
                                text-transform: uppercase;
                                font-weight: 500;
                                font-size: 14px;
                                letter-spacing: 1px;
                                color: #333;
                                margin-bottom: 12px;
                                padding-left: 0;
                            }
                            a {
                                color: #7e7e7e;
                            }
                            &:last-child{
                                padding-left: 0;
                                text-align: right;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}



















