/*=====================
    Clinic Area 
======================*/



/*=======================
    Clinic Portfolio 
========================*/
.clinic-bg-gradient{
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#F5F7FD),color-stop(100%,#fff));
    background: -moz-linear-gradient(-259deg,#F5F7FD 0,#fff 100%);
    background: -webkit-linear-gradient(-259deg,#F5F7FD 0,#fff 100%);
    background: -o-linear-gradient(-259deg,#F5F7FD 0,#fff 100%);
    background: -ms-linear-gradient(-259deg,#F5F7FD 0,#fff 100%);
    background: linear-gradient(-259deg,#F5F7FD 0,#fff 100%);
}


.clinic-portfolio {
    .thumbnail {
        margin-left: -115px;
        text-align: left;
        @media #{$md-layout} {
            margin-left: 0;
        }
        @media #{$sm-layout} {
            margin-left: 0;
        }
    }
    .description{
        p{
            font-size: 18px;
            line-height: 1.78;
            color: #696969;
        }
    }
    &.clinic-portfolio--right{
        .thumbnail {
            margin-left: 0;
            margin-right: -115px;
            text-align: right;
            @media #{$md-layout} {
                margin-right: 0;
            }
            @media #{$sm-layout} {
                margin-right: 0;
            }
        }
    }
}









