/*======================
    Call To Action 
========================*/
.call-to-action--1{
    box-shadow: 0 15px 40px rgba(89, 69, 230, 0.05);
    background: url(/assets/images/mokup/aeroland-macbook-02.png) 55% 100% no-repeat, linear-gradient(-138deg, #5E60E7 0, #9C7AF2 50%, #5E60E7 100%);
    border-radius: 5px;
    .inner{
        padding-left: 72px;
        @media #{$sm-layout} {
            padding-left: 15px;
        }
        .content{
            h3{
                line-height: 1.88;
                font-size: 34px;
                font-weight: 600;
                letter-spacing: 0em;
                margin-bottom: 4px;
                @media #{$lg-layout} {
                    font-size: 26px;
                    margin-bottom: 0;
                }
                @media #{$md-layout} {
                    font-size: 26px;
                    margin-bottom: 0;
                }

                @media #{$sm-layout} {
                    font-size: 26px;
                    margin-bottom: 0;
                }
            }
            h5{
                line-height: 1.78;
                color: #fff;
                font-size: 18px;
            }
        }
    }
    .thumbnail {
        margin-top: -53px;
        margin-right: -90px;
        margin-bottom: -112px;
        text-align: right;

        @media #{$lg-layout} {
            margin-top: 37px;
            margin-right: 0;
            margin-bottom: 0;
        }
        @media #{$md-layout} {
            margin-top: 37px;
            margin-right: 0;
            margin-bottom: 0;
        }
        @media #{$sm-layout} {
            margin-top: 37px;
            margin-right: 0;
            margin-bottom: 0;
        }
    }
    &.variation--2 {
        .thumbnail {
            margin-top: 0;
            margin-right: 0;
            margin-bottom: -75px;
            text-align: right;
        }
    }
}


/*=========================
    Call To Action Two 
===========================*/
.call-to-action-bg{
    background-image: url(/assets/images/call-to-action/call-to-action-03-bg.png);
    background-repeat: no-repeat;
    background-position: top right 200px;
}



.call-to-action-four-bg{
    .wrapper{
        background-image: url(/assets/images/aeroland/startup-image-01.png);
        background-repeat: no-repeat;
        background-position: top right 170px;
    }
    
}












