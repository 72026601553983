/*======================
    Seo Marketing 
========================*/

.seo-content {
    width: 600px;
    max-width: 100%;
    float: right;
    p {
        max-width: 370px;
        color: #696969;
        margin-top: 28px;
    }
    .inner{
        ul{
            &.list {
                @extend %liststyle;
                li {
                    margin: 10px 0;
                    svg {    
                        color: #38cb89;
                    }
                    span {
                        font-size: 14px;
                        line-height: inherit;
                        font-weight: 400;
                        color: #696969;
                        margin-left: 11px;
                    }
                }
            }
        }
    }
}


.meeting-seo-wrapper{
    position: relative;

    @media #{$lg-layout} {
        padding: 120px 50px;
    }

    @media #{$md-layout} {
        padding: 80px 50px;
    }

    @media #{$sm-layout} {
        padding: 60px 30px;
    }

    .thumbnail{
        margin-top: -90px;
        margin-left: -116px;

        @media #{$lg-layout} {
            margin-top: 0px;
            margin-left: -10px;
        }

        @media #{$md-layout} {
            margin-top: 0px;
            margin-left: -10px;
        }

        @media #{$sm-layout} {
            margin-top: 0px;
            margin-left: -10px;
        }

    }
}

