/*===================
    Not Found Page 
======================*/
.page-not-found {
    background-image: url(/assets/images/bg/bg-image-13.jpg);
    background-color: #681F9D;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .content {
        padding-top: 100px;
        padding-bottom: 100px;
        min-height: 100vh;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .title{
        color: #fff;
        font-size: 56px;
        font-weight: 400;
        line-height: 1.28;
        @media #{$sm-layout} {
            font-size: 44px;
        }
    }
}










