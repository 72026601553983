/*======================
    Social Share 
=======================*/

ul {
    &.social-share {
        padding: 0;
        margin: 0;
        list-style: none;
        margin: -10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media #{$sm-layout} {
            margin: -5px;
        }
        
        li {
            margin: 10px;
            @media #{$sm-layout} {
                margin: 5px;
            }
            a {
                font-size: 18px;
                display: block;
                text-align: center;
                height: 48px;
                width: 48px;
                background: transparent;
                color: #696969;
                border: 1px solid #ededed;
                border-radius: 50%;
                position: relative;
                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &:hover {
                    color: #fff;
                    border-color: transparent;
                    background-color: $theme-color;
                }
            }
        }

        &.flat-rounded {
            li {
                a {
                    font-size: 18px;
                    display: block;
                    text-align: center;
                    height: 48px;
                    width: 48px;
                    background: #ededed;
                    color: #696969;
                    border-radius: 50%;
                    position: relative;
                    @extend %transition;
                    &:hover{
                        background-color: $theme-color;
                        color: $white;
                    }
                }
            }
        }

        &.text-white{
            li{
                a{
                    color: #ffffff;
                    &:hover{
                        background-color :#ffffff;
                        color: $theme-color;
                    }
                }
            }
        }


    }
}


// Social Text Link
ul {
    &.social-text-link {
        @extend %liststyle;
        margin: 0 -14px;
        line-height: 1;
        li {
            display: inline-block;
            a {
                padding: 10px 14px;
                display: inline-block;
                border-color: rgba(255,255,255,0.2);
                span {
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 500;
                    color: #cdbcf8;
                    position: relative;
                    padding: 5px 0;
                    z-index: 1;
                    @extend %transition;
                    &::before {
                        content: '';
                        height: 2px;
                        bottom: 0;
                        position: absolute;
                        left: 0;
                        right: 0;
                        z-index: -2;
                        background-color: rgba(255,255,255,0.2);
                    }
                    &::after {
                        content: '';
                        height: 2px;
                        width: 0;
                        bottom: 0;
                        position: absolute;
                        left: auto;
                        right: 0;
                        z-index: -1;
                        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                        background-color: #38cb89;
                    }
                }
            }
            &:hover {
                a {
                    span {
                        color: #fff;
                        &::after {
                            width: 100%;
                            left: 0;
                            right: auto;
                        }
                    }
                }
            }
        }
    }
}


// Social Share
ul{
    &.social-icon{
        @extend %liststyle;
        margin: 0 -8px;
        li{
            display: inline-block;
            a{
                display: inline-block;
                padding: 5px 8px;
                i{
                    color: #7e7e7e;
                    @extend %transition;
                }
                &:hover{
                    i{
                        color: $theme-color;
                    }
                }
            }
        }
        &.large-icon{
            margin: 0 -13px;
            li{
                a{
                    padding: 5px 13px;
                    font-size: 20px;
                }
            }
        }
        &.exlarge-icon {
            margin: 0 -15px;
            @media #{$sm-layout} {
                margin: 0 -10px;
            }
            li{
                a{
                    padding: 5px 15px;
                    font-size: 30px;
                    @media #{$sm-layout} {
                        padding: 3px 10px;
                        font-size: 20px;
                    }
                }
            }
        }
        
        &.text-white{
            li{
                a{
                    i{
                        color: #ffffff;
                    }  
                }
            }
        }



    }
}


// icon And Title
ul{
    &.icon-title {
        @extend %liststyle;
        margin: 0 -8px;
        li {
            display: inline-block;
            a {
                display: inline-block;
                padding: 5px 8px;
                color: #7e7e7e;
                @extend %transition;
                text-transform: capitalize;
                i {
                    font-size: 16px;
                    min-width: 16px;
                    margin-right: 10px;
                }
                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
}

// Title Style
ul{
    &.title-style{
        @extend %liststyle;
        margin: 0 -8px;
        li{
            display: inline-block;
            a{    
                padding: 10px 14px;
                position: relative;
                display: inline-block;
                @media #{$sm-layout} {
                    padding: 5px 9px;
                }
                span{
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 500;
                    color: #333;
                    position: relative;
                    padding: 5px 0;
                    z-index: 1;
                    @extend %transition;

                    &::before{
                        content: '';
                        height: 2px;
                        bottom: 0;
                        position: absolute;
                        left: 0;
                        right: 0;
                        z-index: -2;
                        background: rgba(0, 0, 0, 0.2);
                    }

                    &::after{
                        content: '';
                        height: 2px;
                        width: 0;
                        bottom: 0;
                        position: absolute;
                        left: auto;
                        right: 0;
                        z-index: -1;
                        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                        background-color: #38cb89;
                    }
                }
                &:hover{
                    span{
                        color: $theme-color;
                        &::after{
                            width: 100%;
                            left: 0;
                            right: auto;
                        }
                    }
                }
            }
        }
        &.customskin-color{
            li {
                a {
                    span {
                        color: #cdbcf8;
                        &::before{
                            background-color: rgba(255,255,255,0.2);
                        }
                    }
                    &:hover {
                        span {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
















