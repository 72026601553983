/*======================
    Digital Store 
========================*/

.digital-store{
    height: 100%;
    @extend %transition;
    .digital-inner{
        margin-top: 30px;
        position: relative;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
        overflow: hidden;
        @extend %transition;
    }
    .thumbnail{
        a{
            img{
                width: 100%;
                border-radius: 8px 8px 0 0;
            }
        }
    }
    .content{
        padding: 30px 40px 40px;
        @media #{$lg-layout} {
            padding: 30px 20px 30px;
        }
        @media #{$md-layout} {
            padding: 30px 20px 30px;
        }
        @media #{$sm-layout} {
            padding: 15px 10px;
        }
        .inner{
            h3{
                &.title{
                    font-size: 24px;
                    line-height: 1.5;
                    margin-bottom: 6px;
                    a{
                        color: #2e3280;
                    }
                }
            }
            span{
                &.category{
                    color: #6d70a6;
                    font-size: 14px;
                    line-height: 1.29;
                }
            }
            p{
                margin-top: 16px;
                color: #696969;
            }
            .store-footer{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 26px;
                @media #{$sm-layout} {
                    display: block;
                }
                .pricelist{
                    @extend %liststyle;
                    margin-left: 12px;
                    @media #{$sm-layout} {
                        margin-top: 20px;
                    }
                    li{
                        display: inline-block;
                        vertical-align: middle;
                        position: relative;
                        font-size: 18px;
                        color: #ababab;
                        padding: 0 5px;
                        margin-right: 10px;
                        &.oldprice {
                            &::after {
                                content: '';
                                height: 1px;
                                width: 100%;
                                position: absolute;
                                top: 50%;
                                left: 0;
                                margin-top: -1px;
                                background: #979797;
                            }
                        }
                        &.newprice{
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 24px;
                            color: #38cb89;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    &:hover{
        .digital-inner{
            transform: translateY(-5px);
        }
    }
}


.digital-store-area{
    .space-separator{
        height: 271px;
        @media #{$lg-layout} {
            height: 100px;
        }
        @media #{$md-layout} {
            height: 100px;
        }
        @media #{$sm-layout} {
            height: 100px;
        }
    }
}


.digitalstore-bg{
    background-image: url(/assets/images/bg/bg-image-11.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom 180px center;
}









