/*=====================
    Dividers 
========================*/

.thine-line {
    border-bottom: 1px solid #ddd;
}

.thine-line-gray {
    border-bottom: 1px solid rgba(255,255,255,0.2);
}

.dash-line {
    border-bottom: 1px dashed #ddd;
}
.thick-line {
    border-bottom: 2px solid #ddd;
}
.thin-short-line {
    display: inline-block;
    height: 2px;
    width: 120px;
    background: #222;
}

.thick-short-line {
    display: inline-block;
    height: 3px;
    width: 120px;
    background: #222;

}


/*======================
Basic Modern Dots 
========================*/

.basic-modern-dots{
    display: inline-block;
    padding: 3px 10px;
    cursor: pointer;
    .dot{
        background-color: $theme-color;
        border-radius: 50%;
        box-shadow: 0 0 12px #def0ff;
        -webkit-animation: separator-bounce 1.4s ease-in-out 0s infinite both;
        animation: separator-bounce 1.4s ease-in-out 0s infinite both;
        width: 14px;
        height: 14px;
        &.second-circle{
            margin-top: 18px;
            -webkit-animation-delay: -.16s;
            animation-delay: -.16s;
        }
        &.third-circle{
            margin-top: 14px;
            -webkit-animation-delay: -.32s;
            animation-delay: -.32s;
        }
    }
    &.white-dots{
        .dot{
            background-color: #ffffff;
            box-shadow: 0 0 12px #ffffff;
        }
    }
}












