/*=========================
    Feature Area 
===========================*/

.business-analitics-area {
    .feature-wrapper {
        width: 600px;
        padding: 0 30px;
        max-width: 100%;
        @media #{$md-layout} {
            margin: 0 auto;
        }
        @media #{$sm-layout} {
            width: auto;
            max-width: 100%;
            margin: 0;
            padding: 0 15px;
            margin: 0 auto;
        }
    }
    .thumbnail {
        text-align: right;
        @media #{$lg-layout} {
            text-align: center;
        }
        @media #{$md-layout} {
            text-align: center;
        }
        @media #{$sm-layout} {
            text-align: center;
        }
    }
}

.feature {
    display: flex;
    padding: 41px 22px 45px;
    box-shadow: 0 10px 40px 5px rgba(89, 69, 230, 0.05);
    background: #fff;
    border-radius: 5px;
    @extend %transition;
    align-items: center;
    @media #{$large-mobile} {
        display: block;
    }
    .icon{
        position: relative;
        flex-shrink: 0;
        margin: -19px 20px 0 0;
        @media #{$large-mobile} {
            margin-bottom: 20px;
        }
        img{

        }
    }
    .content{
        flex-grow: 1;
        h5{
            &.heading{
                font-size: 18px;
                color: #333;
                margin-bottom: 14px;
            }
        }
        p{

        }
    }
    &:hover{
        transform: translateY(-5px);
        box-shadow: 0 10px 50px 5px rgba(89, 69, 230, 0.1);
    }
}

.single-feature{
    &:nth-child(2){
        .feature{
            margin-left: 70px;
            @media #{$md-layout} {
                margin-left: 0;
            }
            @media #{$sm-layout} {
                margin-left: 0;
            }
            @media #{$lg-layout} {
                margin-left: 0;
            }
        }
    }
}

