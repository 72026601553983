/*======================
    Slick Dots 
=======================*/


.slick-default-dots {
    position: relative;
    .slick-dots{
        bottom: -70px;
        @media #{$sm-layout} {
            bottom: -30px;
        }
        @media #{$md-layout} {
            bottom: -40px;
        }
        li{
            button{
                box-sizing: border-box;
                margin: 0 5px;
                width: 20px;
                height: 20px;
                border: 0;
                border-radius: 0;
                background: transparent;
                vertical-align: middle;
                opacity: 1;
                position: relative;
                outline: none;
                &::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 100%;
                    width: 8px;
                    height: 8px;
                    background: #d8d8d8;
                    opacity: .4;
                    z-index: 1;
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                }
                &::before{
                    display: none;
                }
            }
            &.slick-active ,
            &:hover{
                button{
                    &::after{
                        background-color: #5945e6;
                        width: 12px;
                        height: 12px;
                        opacity: 1;
                    }
                }
                
            }
            
        }
    }
    &.dots-position-right{
        .slick-dots{
            bottom: auto;
            right: 50px;
            top: 50%;
            left: auto;
            text-align: right;
            z-index: 2;
            width: auto;
            li{
                position: relative;
                display: block;
                width: 20px;
                height: 20px;
                margin: 5px 0;
                padding: 0;
                cursor: pointer;
            }
        }
    }

    &.button--white{
        .slick-dots{
            li{
                button{
                    &::after{
                        background: #ffffff;
                    }
                }
                &.slick-active ,
                &:hover{
                    button{
                        &::after{
                            background-color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}

.slick-slide img {
    display: inline-block;
}


/*========================
    Slick Custom Arrow 
==========================*/
.slick-custom-arrow{
    position: relative;
    @extend %transition;
}

















