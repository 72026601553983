/*==========================
Maintenance Area 
============================*/

.maintenane-inner {
    width: 100%;
    height: 100vh;
    text-align: center;
    background: #ffffff;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    @media #{$md-layout} {
        height: auto;
    }
    @media #{$sm-layout} {
        height: auto;
    }

    .left-bg{
        background-image: url(/assets/images/bg/bg-image-12.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .right-bg{
        padding: 50px 0;
        .inner{
            width: 660px;
            max-width: 100%;
            margin: 0 auto;
            @media #{$md-layout} {
                padding: 0 30px;
            }
            @media #{$sm-layout} {
                padding: 0 30px;
            }
            @media #{$lg-layout} {
                padding: 0 30px;
            }
            @media #{$laptop-device} {
                padding: 0 30px;
            }
            .content{
                .aeroland-logo{
                    margin-bottom: 152px;
                    @media #{$md-layout} {
                        margin-bottom: 50px;
                    }
                    @media #{$sm-layout} {
                        margin-bottom: 50px;
                    }
                }
                .title{
                    font-size: 40px;
                    line-height: 1.4;
                    margin: 0 auto 43px;
                    width: 600px;
                    max-width: 100%;
                    color: #6d70a6;
                    @media #{$md-layout} {
                        margin: 0 auto 26px;
                    }
                    @media #{$sm-layout} {
                        margin: 0 auto 26px;
                        font-size: 27px;
                    }
                }
            }
        }
    }
    .maintanence-subscribe{
        .form-item{
            display: block;
            input{
                color: #ababab;
                border-color: #f8f8f8;
                background-color: #f8f8f8;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0em;
                width: 100%;
                outline: none;
                border: 1px solid #eee;
                border-radius: 5px;
                padding: 3px 20px;
                height: 56px;
                text-align: center;
            }
        }
    }
}










