/*========================
    Counterup Area 
==========================*/

.counterup_style--1 {
    text-align: center;
    margin-top: 30px;
    .title {
        font-size: 24px;
        line-height: 1.34;
        color: #6d70a6;
        padding: 0 40px;
        margin-bottom: 30px;
        @media #{$md-layout} {
            font-size: 20px;
            padding: 0;
            margin-bottom: 27px;
        }
        @media #{$sm-layout} {
            font-size: 20px;
            padding: 0;
            margin-bottom: 10px;
        }
    }
    .counter {
        color: #ee7455;
        font-size: 64px;
        font-weight: 300;
        line-height: 1;
        margin-bottom: 19px;
        @media #{$sm-layout} {
            font-size: 46px;
            margin-bottom: 5px;
        }
    }
    p {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: rgba(109,112,166,0.5);
    }
}


// Counter Up Style Two
.counterup_style--2{
    display: flex;
    @media #{$sm-layout} {
        display: block;
    }
    .icon{
        width: 160px;
        height: 160px;
        background-color: #9C7AF2;
        background-image: linear-gradient(-224deg,#9C7AF2 0,#5E61E7 100%);
        border-radius: 100%;
        position: relative;
        @media #{$md-layout} {
            width: 130px;
            height: 130px;
        }
        @media #{$sm-layout} {
            width: 130px;
            height: 130px;
            margin: 0 auto;
        }
        .inner{
            background-image: linear-gradient(218deg, #ffffff 0, #ffffff 50%, #ffffff 100%);
            border-radius: 100%;
            left: 3px;
            right: 3px;
            top: 3px;
            bottom: 3px;
            position: absolute;
            .countinner{
                background-color: #9C7AF2;
                background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
                border-radius: 100%;
                left: 9px;
                right: 9px;
                top: 9px;
                bottom: 9px;
                position: absolute;
            }
        }
        .counter{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 34px;
            line-height: 1;
            color: #fff;
            z-index: 2;
        }
        .circle-design {
            box-shadow: 0 2px 17px rgba(89,69,230,0.27);
            position: absolute;
            border-radius: 50%;
            animation: jump infinite 2s;
            animation-direction: alternate;
            background-color: #9C7AF2;
            background-image: linear-gradient(-224deg,#9C7AF2 0,#5E61E7 100%);
            &.color--yellow {
                background-color: #f76b1c;
                background-image: linear-gradient(-224deg,#fad961 0,#f76b1c 100%);
            }
            &.one {
                width: 43px;
                height: 43px;
                left: -12px;
                bottom: 27px;
            }
            &.two {
                width: 17px;
                height: 17px;
                right: -8px;
                top: 50%;
                margin-top: -11px;
                animation-delay: 1s;
            }
        }

    }
    .content{
        flex-grow: 1;
        margin-left: 35px;
        text-align: left;
        @media #{$sm-layout} {
            margin-left: 0;
            margin-top: 30px;
            text-align: center;
        }
        .title{
            margin-bottom: 17px;
            font-size: 18px;
            color: #333;
        }
        p{
            line-height: 2.14;
            br {
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
    }
    &.color--yellow{
        .icon{
            background-image: linear-gradient(-224deg,#fad961 0,#f76b1c 100%);
            .inner{
                .countinner{
                    background-image: linear-gradient(-224deg,#fad961 0,#f76b1c 100%);
                }
            }
        }
    }
}


// Counter Up Style Three
.counterup_style--3{
    text-align: center;
    .counter {
        font-size: 56px;
        font-weight: 400;
        line-height: 1.29;
        @media #{$sm-layout} {
            font-size: 30px;
        }
    }
    .title {
        color: rgba(255,255,255,0.7);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}


.counterup_style--4 {
    text-align: left;
    position: relative;
    padding-left: 15px;
    .counter {
        color: #38cb89;
        font-size: 48px;
        line-height: 1;
        font-weight: 400;
        @media #{$sm-layout} {
            font-size: 30px;
        }
    }
    .title {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: inherit;
    }
    &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: -14px;
        transform: translate(0, -50%);
        height: 65px;
        width: 1px;
        background: #d8d8d8;
        @media #{$sm-layout} {
            display: none;
        }
    }
    &:first-child{
        &::before {
            display: none;
        }
    }
}

