/*=======================
    Contact area 
==========================*/

.subscribe-area {
    h2 {
        br {
            @media #{$sm-layout} {
                display: none;
            }
        }
        @media #{$sm-layout} {
            font-size: 27px !important;
        }
    }
}


.contact-form-one{
    .thumbnail{
        margin-right: -168px;
        margin-left: 80px;
        text-align: left;
        @media #{$lg-layout} {
            margin-right: 0;
            margin-left: 0;
        }
        @media #{$md-layout} {
            margin-right: 0;
            margin-left: 0;
        }
        @media #{$sm-layout} {
            margin-right: 0;
            margin-left: 0;
            text-align: center;
            margin-top: 30px;
        }
    }
}

.contact-form--1{
    label {
        display: block;
        margin-bottom: 0;
    }
    input{
        color: #ababab;
        border-color: #f8f8f8;
        background-color: #f8f8f8;
        font-size: 15px;
        font-weight: 400;
        width: 100%;
        outline: none;
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 3px 20px;
        height: 56px;
        margin-bottom: 20px;
    }
    textarea{
        width: 100%;
        outline: none;
        border: 1px solid #f8f8f8;
        border-radius: 5px;
        background: #f8f8f8;
        display: block;
        padding: 12px 20px 3px;
        max-width: 100%;
        color: #ababab;
        background-color: #f8f8f8;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 20px;
        height: 150px;
    }
}
.newsletter-form--1.color-body .form-item input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #7e7e7e;
}
.newsletter-form--1.color-body .form-item input::-moz-placeholder { /* Firefox 19+ */
  color: #7e7e7e;
}
.newsletter-form--1.color-body .form-item input:-ms-input-placeholder { /* IE 10+ */
  color: #7e7e7e;
}










