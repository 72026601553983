/*=========================
    Responsive App Area
==========================*/

.responsive-app-area {
    .responsive-app-wrapper {
        .content {
            width: 600px;
            max-width: 100%;
            @media #{$sm-layout} {
                max-width: 100%;
                padding: 0 15px;
            }
            @media #{$lg-layout} {
                width: auto;
                max-width: 100%;
                padding: 0 12px;
            }
            @media #{$md-layout} {
                width: auto;
                max-width: 100%;
                padding: 0 12px;
            }
            h3 {
                font-size: 48px;
                line-height: 1.34;
                font-weight: 400;
                margin-bottom: 20px;
                @media #{$lg-layout} {
                    font-size: 43px;
                }
                @media #{$md-layout} {
                    font-size: 34px;
                }
                @media #{$sm-layout} {
                    font-size: 30px;
                }
                
            }
            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 1.78;
                margin-bottom: 40px;
            }
            .ar-button {
                margin-bottom: 50px;
            }
            h6 {
                &.next-button {
                    color: #6d70a6;
                    font-size: 18px;
                    line-height: 1.23;
                    a {
                        color: #38cb89;
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
            }
            
        }
    }
}











