/*======================
    Team Area 
========================*/

.team{
    .thumbnail {
        position: relative;
        margin-bottom: 31px;
        img {
            width: 200px;
            border-radius: 50%;
        }
    }
    .title {
        font-size: 24px;
        color: #333;
        line-height: 1.5;
        margin-bottom: 12px;
    }
    p {
        color: #6d70a6;
        font-size: 14px;
    }
    ul {
        &.social-icon {
            font-size: 0;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-5px);
            @extend %transition;
        }
    }
    &:hover{
        ul {
            &.social-icon {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }
}

/*=====================
Team Style Two 
========================*/
.team_style--2 {
    padding: 15px;
    text-align: center;
    .inner {
        background: #fff;
        padding: 30px;
        text-align: center;
        border-radius: 5px;
        box-shadow: 0 4px 20px rgba(51, 51, 51, 0.1);
        @extend %transition;
    }
    .thumbnail {
        margin-bottom: 24px;
        img {
            border-radius: 50%;
        }
    }
    .title {
        font-size: 18px;
        margin-bottom: 10px;
    }
    p {
        color: #6d70a6;
        font-size: 14px;
    }
    ul {
        &.social-icon {
            margin-top: 18px;
        }
    }
    &:hover{
        .inner{
            transform: translateY(-5px);
        }
    }
}

.slick-slide .team_style--2 img {
    display: inline-block;
}














