/*======================
    Sass Page 
========================*/

/*=======================
    Sass Digital App 
=========================*/


.digital-app-button{
    margin: -10px;
    a{
        padding: 10px;
        display: inline-block;
        @extend %transition;
        &:hover{
            transform: translateY(-3px);
        }
    }
}
.marketing-wrapper {
    @media #{$lg-layout} {
        padding: 0 20px;
    }
    @media #{$md-layout} {
        padding: 0 20px;
    }
    @media #{$sm-layout} {
        padding: 0 20px;
    }
}
