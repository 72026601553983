/*=======================
    Gallery Area 
==========================*/


.gallery {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 3;
    overflow: hidden;
    .action {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        color: #fff;
        opacity: 0;
        visibility: hidden;
        @extend %transition;
        z-index: 2;
    }
    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        background-color: $theme-color;
        z-index: 1;
    }
    &:hover{
        .overlay{
            visibility: visible;
            opacity: .9;
        }
        .action{
            visibility: visible;
            opacity: 1;
        }
    }
}







