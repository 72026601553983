@import url(~bootstrap/dist/css/bootstrap.min.css);
@import url(../public/assets/css/plugins.css);
@import url(../public/assets/css/revolution.css);
/*********************************************************************************

	Template Name: Template Full Name Here
	Description: A perfect Template For Portfolio. It comes with nice and clean design.
	Version: 1.0

	Note: This is style css.

**********************************************************************************/
/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ Reset Styles
	|	|___ Typography
	|	|___ Animations
	|	|___ Utilities
	|
	|
	|___Elements Styles
	|	|___ Button
	|	|___ Section Title
	|
	|
	|___Blocks Styles
	|	|___ Header 
	|	|___ Navigation 
	|	|___ Banner
	|	|___ Footer
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/
/**************************************
    Default Styles
***************************************/
@import url("https://fonts.googleapis.com/css?family=Rubik:400,400i,500,500i,700&display=swap");
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
@import url(~lightgallery.js/dist/css/lightgallery.css);
@import url(~react-tabs/style/react-tabs.css);
@import url(~animate.css/animate.min.css);
.body-color {
  color: #7e7e7e; }

/*===============================
    Font Family 
=================================*/
/*===============================
    Color Variation 
=================================*/
/* container 450px*/
/* container 300px*/
@media only screen and (max-width: 575px) {
  .container {
    max-width: 450px; } }

@media only screen and (max-width: 479px) {
  .container {
    max-width: 320px; } }

/**
 * Reset Styels
 */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html,
button,
input,
select,
textarea {
  font-family: "Source Sans Pro", Helvetica, sans-serif; }

a {
  color: #ca3c08;
  text-decoration: none; }

a:visited {
  color: #ac0404; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  color: #ea9629;
  outline: 0; }

a:hover {
  text-decoration: underline; }

address {
  font-style: italic;
  margin: 0 0 24px; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

p {
  margin: 0 0 24px; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 14px;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

pre {
  background: #f5f5f5;
  color: #666;
  font-family: monospace;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

blockquote,
q {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

blockquote {
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  margin: 24px 40px; }

blockquote blockquote {
  margin-right: 0; }

blockquote cite,
blockquote small {
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase; }

blockquote em,
blockquote i {
  font-style: normal;
  font-weight: 300; }

blockquote strong,
blockquote b {
  font-weight: 400; }

small {
  font-size: smaller; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

dl {
  margin: 0 20px; }

dt {
  font-weight: bold; }

dd {
  margin: 0 0 20px; }

menu,
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px; }

ul {
  list-style-type: square; }

nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

li > ul,
li > ol {
  margin: 0; }

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline; }

button,
input {
  line-height: normal; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  width: 270px; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-bottom: 1px solid #ededed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
  line-height: 2;
  margin: 0 0 20px;
  width: 100%; }

caption,
th,
td {
  font-weight: normal;
  text-align: left; }

caption {
  font-size: 16px;
  margin: 20px 0; }

th {
  font-weight: bold;
  text-transform: uppercase; }

td {
  border-top: 1px solid #ededed;
  padding: 6px 10px 6px 0; }

del {
  color: #333; }

ins {
  background: #fff9c0;
  text-decoration: none; }

hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px; }

/**
 * Typography
 */
* {
  box-sizing: border-box; }

img {
  max-width: 100%; }

html {
  overflow: hidden;
  overflow-y: auto; }

body {
  overflow: hidden;
  font-size: 15px;
  line-height: 1.74;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik", sans-serif;
  color: #7e7e7e;
  font-weight: 400;
  letter-spacing: 0em; }

a {
  transition: all 0.4s ease-in-out 0s; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
  margin: 0;
  margin-bottom: 15px; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Rubik", sans-serif;
  line-height: 1.34;
  color: #2e3280;
  font-weight: 500; }

h1,
.h1 {
  font-size: 56px; }

h2,
.h2 {
  font-size: 48px; }

h3,
.h3 {
  font-size: 36px; }

h4,
.h4 {
  font-size: 24px; }

h5,
.h5 {
  font-size: 18px; }

h6,
.h6 {
  font-size: 14px; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h2 {
    font-size: 45px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 52px; }
  h2,
  .h2 {
    font-size: 46px; }
  h3,
  .h3 {
    font-size: 34px; }
  h4,
  .h4 {
    font-size: 22px; } }

@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 50px; }
  h2,
  .h2 {
    font-size: 36px; }
  h3,
  .h3 {
    font-size: 34px; }
  h4,
  .h4 {
    font-size: 20px; } }

@media only screen and (max-width: 575px) {
  h1,
  .h1 {
    font-size: 48px; } }

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none; }

a:visited {
  color: inherit; }

input,
button,
select,
textarea {
  background: transparent;
  border: 1px solid #d4d4d4;
  transition: all 0.4s ease-out 0s;
  color: #7e7e7e; }
  input:focus, input:active,
  button:focus,
  button:active,
  select:focus,
  select:active,
  textarea:focus,
  textarea:active {
    outline: none;
    border-color: #5E61E7; }

input,
select,
textarea {
  width: 100%;
  font-size: 14px; }

input,
select {
  height: 40px;
  padding: 0 15px; }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

/*=====================
Common Css 
=======================*/
.textBlack {
  color: #000000; }

.bodyColor {
  color: #7e7e7e !important; }

.color-1 {
  color: #5E61E7 !important; }

.color-2 {
  color: #f4769a !important; }

.color-3 {
  color: #6d70a6 !important; }

.color-4 {
  color: #38cb89 !important; }

.color-5 {
  color: rgba(255, 255, 255, 0.7); }

.bgcolor-4 {
  background: #2f21b3; }

ul.social-text-link, ul.social-icon, ul.icon-title, ul.title-style, .pricing-table .pricing-content ul.pricingfeature, .pricing-table--3 .pricing-content ul.pricingfeature, .pricing-box--5 .inner .content .feature-list .pricing-feature-list, ul.list, .attribute-list, .attribute-list--2, .list-style--1, .single-post-details .content-wrapper .blog-post-share .post-share-left .tagcloud, .breadcrumb-default .content .inner .breadcrumb-list, .seo-content .inner ul.list, .digital-store .content .inner .store-footer .pricelist, .pre-header-area .mainmenu ul.menu, .footer-style--3 .copyright-inner .content {
  padding: 0;
  margin: 0;
  list-style: none; }

.liststyle {
  padding: 0;
  margin: 0;
  list-style: none; }

.accodion-style--1 .accordion__button, .accodion-style--1 .accordion__button::after, .accodion-style--1 .accordion__panel, button.video-button, .button-group .button-image, .video-popup-button .video-popup, .icon-box--2, .box-icon--2, .box-icon--4, .box-icon--6, .box-icon--7, .box-icon--9, .box-icon--9 .content h5.heading, .box-icon--9 .content p, .secret-link, .box-icon--8 .content-header i, .service_style--2 .icon, .service_style--2 .content h5.heading, .service-style--4, .slick-custom-arrow, ul.social-share.flat-rounded li a, ul.social-text-link li a span, ul.social-icon li a i, ul.icon-title li a, ul.title-style li a span, .feature, .pricing-table, .pricing-table--3 .inner, .pricing-table--4 .inner, .pricing-table--4 .inner::before, .pricing-box--5 .inner, .gallery .action, .team ul.social-icon, .team_style--2 .inner, .tab-style--1 li, .tab-style--2 .tab2-content, .single-post-details .content-wrapper .blog-post-share .post-share-left .tagcloud li a, .blog-comment .single-comment .comment-content .reply-button button, .portfolio, .portfolio .content::before, .portfolio-with-caption, .digital-app-button a, .branding-service .slide-content .slide-info, .branding-service .thumbnail img, .dg-gallery-list .item-grid a .hover-action h3.title, .dg-gallery-list .item-grid a .hover-action h3.read-more-button, .digital-store, .digital-store .digital-inner, .demo-single img, .footer-style--5 .newsletter-inner input {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.fontWeight900 {
  font-weight: 900 !important; }

.fontWeight800 {
  font-weight: 800 !important; }

.fontWeight700 {
  font-weight: 700 !important; }

.fontWeight600 {
  font-weight: 600 !important; }

.fontWeight500 {
  font-weight: 500 !important; }

.fontWeight400 {
  font-weight: 400 !important; }

.fontWeight300 {
  font-weight: 300 !important; }

.textUpper {
  text-transform: uppercase; }

.textCap {
  text-transform: capitalize; }

p:last-child {
  margin-bottom: 0; }

.font-1 {
  font-family: "Rubik", sans-serif; }

.font-2 {
  font-family: "Playfair Display", serif; }

.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.link-hover {
  position: relative; }
  .link-hover::after {
    content: '';
    width: 0;
    height: 1px;
    bottom: 0;
    position: absolute;
    left: auto;
    right: 0;
    z-index: -1;
    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    background: currentColor; }
  .link-hover:hover::after {
    width: 100%;
    left: 0;
    right: auto; }

/* Slick Default Dot */
.slick-default-dot {
  position: relative; }
  .slick-default-dot ul.slick-dots {
    position: absolute;
    bottom: 70px;
    z-index: 1; }
    @media only screen and (max-width: 767px) {
      .slick-default-dot ul.slick-dots {
        bottom: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slick-default-dot ul.slick-dots {
        bottom: 30px; } }
    .slick-default-dot ul.slick-dots li {
      width: auto;
      height: auto; }
      .slick-default-dot ul.slick-dots li button {
        width: 10px;
        height: 10px;
        padding: 5px;
        border: 0;
        outline: none;
        background: #7e7e7e;
        border-radius: 100%; }
        .slick-default-dot ul.slick-dots li button::before {
          display: none; }
      .slick-default-dot ul.slick-dots li.slick-active button {
        background: #5E61E7; }
  .slick-default-dot .slick-prev,
  .slick-default-dot .slick-next {
    z-index: 2; }
    @media only screen and (max-width: 767px) {
      .slick-default-dot .slick-prev,
      .slick-default-dot .slick-next {
        display: none !important; } }
    .slick-default-dot .slick-prev::before,
    .slick-default-dot .slick-next::before {
      font-size: 30px;
      opacity: 1; }
  .slick-default-dot .slick-prev {
    left: 30px; }
  .slick-default-dot .slick-next {
    right: 30px; }

/* ------------------------
    Separator Bounce 
---------------------------*/
@-webkit-keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/*==============================
 *  Utilities
=================================*/
/*====================================
    Google Font  
===================================*/
/*----------------------------------------
01. font-family: 'Rubik', sans-serif;

-------------------------------------*/
.hidden {
  display: none; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.poss_relative {
  position: relative; }

.poss_absolute {
  position: absolute; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.wrapper {
  position: relative; }

/* Define Colors */
.theme-color {
  color: #5E61E7; }

/*===============================
    Background Color 
=================================*/
.bg_color--1 {
  background: #fff; }

.bg_color--2 {
  background: #5E61E7; }

.bg_color--3 {
  background: #f5f5f5; }

.bg_color--4 {
  background: #f6f5f9; }

.bg_color--5 {
  background: #f5f7fd; }

.bg_color--6 {
  background: #2d1b6e; }

.bg_color--7 {
  background: #33237a; }

.bg_color--8 {
  background: #161953; }

/*===========================
    Background Image 
=============================*/
.bg_image--1 {
  background-image: url(/assets/images/bg/bg-image-1.jpg); }

.bg_image--2 {
  background-image: url(/assets/images/bg/bg-image-2.jpg); }

.bg_image--3 {
  background-image: url(/assets/images/bg/bg-image-3.jpg); }

.bg_image--4 {
  background-image: url(/assets/images/bg/bg-image-4.jpg); }

.bg_image--5 {
  background-image: url(/assets/images/bg/bg-image-5.jpg); }

.bg_image--6 {
  background-image: url(/assets/images/bg/bg-image-6.jpg); }

.bg_image--7 {
  background-image: url(/assets/images/bg/bg-image-7.jpg); }

.bg_image--8 {
  background-image: url(/assets/images/bg/bg-image-8.jpg); }

.bg_image--9 {
  background-image: url(/assets/images/bg/bg-image-9.jpg); }

.bg_image--10 {
  background-image: url(/assets/images/bg/bg-image-10.jpg); }

.bg_image--11 {
  background-image: url(/assets/images/bg/bg-image-11.jpg); }

.bg_image--12 {
  background-image: url(/assets/images/bg/bg-image-12.jpg); }

.bg_image--13 {
  background-image: url(/assets/images/bg/bg-image-13.jpg); }

.bg_image--14 {
  background-image: url(/assets/images/bg/bg-image-14.jpg); }

.bg_image--15 {
  background-image: url(/assets/images/bg/bg-image-15.jpg); }

.bg_image--16 {
  background-image: url(/assets/images/bg/bg-image-16.jpg); }

.bg_image--17 {
  background-image: url(/assets/images/bg/bg-image-17.jpg); }

.bg_image--18 {
  background-image: url(/assets/images/bg/bg-image-18.jpg); }

.bg_image--19 {
  background-image: url(/assets/images/bg/bg-image-19.jpg); }

.bg_image--20 {
  background-image: url(/assets/images/bg/bg-image-20.jpg); }

.bg_image--21 {
  background-image: url(/assets/images/bg/bg-image-21.jpg); }

.bg_image--22 {
  background-image: url(/assets/images/bg/bg-image-22.jpg); }

.bg_image--23 {
  background-image: url(/assets/images/bg/bg-image-23.jpg); }

.bg_image--24 {
  background-image: url(/assets/images/bg/bg-image-24.jpg); }

.bg_image--25 {
  background-image: url(/assets/images/bg/bg-image-25.jpg); }

.bg_image--26 {
  background-image: url(/assets/images/bg/bg-image-26.jpg); }

.bg_image--27 {
  background-image: url(/assets/images/bg/bg-image-27.jpg); }

.bg_image--28 {
  background-image: url(/assets/images/bg/bg-image-28.jpg); }

.bg_image--29 {
  background-image: url(/assets/images/bg/bg-image-29.jpg); }

.bg_image--30 {
  background-image: url(/assets/images/bg/bg-image-30.jpg); }

.font--1 {
  font-size: 1px !important; }

.font--2 {
  font-size: 2px !important; }

.font--3 {
  font-size: 3px !important; }

.font--4 {
  font-size: 4px !important; }

.font--5 {
  font-size: 5px !important; }

.font--6 {
  font-size: 6px !important; }

.font--7 {
  font-size: 7px !important; }

.font--8 {
  font-size: 8px !important; }

.font--9 {
  font-size: 9px !important; }

.font--10 {
  font-size: 10px !important; }

.font--11 {
  font-size: 11px !important; }

.font--12 {
  font-size: 12px !important; }

.font--13 {
  font-size: 13px !important; }

.font--14 {
  font-size: 14px !important; }

.font--15 {
  font-size: 15px !important; }

.font--16 {
  font-size: 16px !important; }

.font--17 {
  font-size: 17px !important; }

.font--18 {
  font-size: 18px !important; }

.font--19 {
  font-size: 19px !important; }

.font--20 {
  font-size: 20px !important; }

.font--21 {
  font-size: 21px !important; }

.font--22 {
  font-size: 22px !important; }

.font--23 {
  font-size: 23px !important; }

.font--24 {
  font-size: 24px !important; }

.font--25 {
  font-size: 25px !important; }

.font--26 {
  font-size: 26px !important; }

.font--27 {
  font-size: 27px !important; }

.font--28 {
  font-size: 28px !important; }

.font--29 {
  font-size: 29px !important; }

.font--30 {
  font-size: 30px !important; }

.font--31 {
  font-size: 31px !important; }

.font--32 {
  font-size: 32px !important; }

.font--33 {
  font-size: 33px !important; }

.font--34 {
  font-size: 34px !important; }

.font--35 {
  font-size: 35px !important; }

.font--36 {
  font-size: 36px !important; }

.font--37 {
  font-size: 37px !important; }

.font--38 {
  font-size: 38px !important; }

.font--39 {
  font-size: 39px !important; }

.font--40 {
  font-size: 40px !important; }

.font--41 {
  font-size: 41px !important; }

.font--42 {
  font-size: 42px !important; }

.font--43 {
  font-size: 43px !important; }

.font--44 {
  font-size: 44px !important; }

.font--45 {
  font-size: 45px !important; }

.font--46 {
  font-size: 46px !important; }

.font--47 {
  font-size: 47px !important; }

.font--48 {
  font-size: 48px !important; }

.font--49 {
  font-size: 49px !important; }

.font--50 {
  font-size: 50px !important; }

.font--51 {
  font-size: 51px !important; }

.font--52 {
  font-size: 52px !important; }

.font--53 {
  font-size: 53px !important; }

.font--54 {
  font-size: 54px !important; }

.font--55 {
  font-size: 55px !important; }

.font--56 {
  font-size: 56px !important; }

.font--57 {
  font-size: 57px !important; }

.font--58 {
  font-size: 58px !important; }

.font--59 {
  font-size: 59px !important; }

.font--60 {
  font-size: 60px !important; }

.font--61 {
  font-size: 61px !important; }

.font--62 {
  font-size: 62px !important; }

.font--63 {
  font-size: 63px !important; }

.font--64 {
  font-size: 64px !important; }

.font--65 {
  font-size: 65px !important; }

.font--66 {
  font-size: 66px !important; }

.font--67 {
  font-size: 67px !important; }

.font--68 {
  font-size: 68px !important; }

.font--69 {
  font-size: 69px !important; }

.font--70 {
  font-size: 70px !important; }

.font--71 {
  font-size: 71px !important; }

.font--72 {
  font-size: 72px !important; }

.font--73 {
  font-size: 73px !important; }

.font--74 {
  font-size: 74px !important; }

.font--75 {
  font-size: 75px !important; }

.font--76 {
  font-size: 76px !important; }

.font--77 {
  font-size: 77px !important; }

.font--78 {
  font-size: 78px !important; }

.font--79 {
  font-size: 79px !important; }

.font--80 {
  font-size: 80px !important; }

/*=========================
    Text specialized 
==========================*/
.text-italic {
  font-style: italic; }

.text-normal {
  font-style: normal; }

.text-underline {
  font-style: underline; }

/* Height and width */
.fullscreen {
  min-height: 100vh;
  width: 100%; }

/*===================
Custom Row
======================*/
.row--0 {
  margin-left: -0px;
  margin-right: -0px; }
  .row--0 > [class*="col"] {
    padding-left: 0px;
    padding-right: 0px; }

.row--5 {
  margin-left: -5px;
  margin-right: -5px; }
  .row--5 > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px; }

.row--10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row--10 > [class*="col"] {
    padding-left: 10px;
    padding-right: 10px; }

.row--20 {
  margin-left: -20px;
  margin-right: -20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--20 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--35 {
  margin-left: -35px;
  margin-right: -35px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--35 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 35px;
    padding-right: 35px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--35 > [class*="col"],
      .row--35 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--35 > [class*="col"],
      .row--35 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--35 > [class*="col"],
      .row--35 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--35 > [class*="col"],
      .row--35 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

/*===========================
    Input Placeholder
=============================*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

/*=============================
	Overlay styles 
==============================*/
[data-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative; }

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2; }

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2; }

[data-overlay]:before {
  background-color: #5E61E7; }

[data-black-overlay]:before {
  background-color: #000000; }

[data-white-overlay]:before {
  background-color: #ffffff; }

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
  opacity: 0.1; }

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
  opacity: 0.2; }

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
  opacity: 0.3; }

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
  opacity: 0.4; }

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
  opacity: 0.5; }

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
  opacity: 0.6; }

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
  opacity: 0.7; }

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
  opacity: 0.8; }

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
  opacity: 0.9; }

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
  opacity: 1; }

/*------------------------------
    Scroll Up 
--------------------------------*/
#scrollUp {
  width: 40px;
  height: 40px;
  background-color: #5E61E7;
  border: 3px solid #eeeeee;
  color: #2e3280;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important; }
  @media only screen and (max-width: 767px) {
    #scrollUp {
      display: none !important; } }
  #scrollUp i {
    display: block;
    line-height: 34px;
    font-size: 22px; }
  #scrollUp:hover i {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite; }

.form-message {
  margin-bottom: 0;
  text-align: center; }
  .form-message.error {
    margin-top: 20px;
    color: #f80707; }
  .form-message.success {
    margin-top: 20px;
    color: #0d8d2d; }

/*=========================
    Section Separation 
==========================*/
.letterspacing--2 {
  letter-spacing: 2px; }

.pra {
  font-size: 18px;
  line-height: 2em; }
  @media only screen and (max-width: 767px) {
    .pra {
      font-size: 14px;
      line-height: 24px; } }

.mt_dec--30 {
  margin-top: -30px; }

.pl--0 {
  padding-left: 0; }

.pr--0 {
  padding-right: 0; }

.pt--0 {
  padding-top: 0; }

.pb--0 {
  padding-bottom: 0; }

.mr--0 {
  margin-right: 0; }

.ml--0 {
  margin-left: 0; }

.mt--0 {
  margin-top: 0; }

.mb--0 {
  margin-bottom: 0; }

.pt--260 {
  padding-top: 260px; }

.pt--250 {
  padding-top: 250px; }

.plr--270 {
  padding: 0 270px; }

.plr--300 {
  padding: 0 300px; }

.plr--340 {
  padding: 0 340px; }

.ptb--300 {
  padding: 300px 0; }

.ptb--340 {
  padding: 340px 0; }

.ptb--450 {
  padding: 450px 0; }

.plr_dec--15 {
  margin: 0 -15px; }

.pt--300 {
  padding-top: 300px; }

.pb--300 {
  padding-bottom: 300px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb--120,
  .ptb--110,
  .ptb--80,
  .ptb--150,
  .ptb--170,
  .ptb--120 {
    padding: 80px 0 !important; } }

@media only screen and (max-width: 767px) {
  .ptb--120,
  .ptb--110,
  .ptb--80,
  .ptb--150,
  .ptb--170,
  .ptb--120 {
    padding: 80px 0 !important; } }

@media only screen and (max-width: 767px) {
  .pr--130 {
    padding-right: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr--130 {
    padding-right: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr--130 {
    padding-right: 40px !important; } }

@media only screen and (max-width: 767px) {
  .pl--130 {
    padding-left: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl--130 {
    padding-left: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl--130 {
    padding-left: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt--120,
  .pt--130,
  .pt--100 {
    padding-top: 80px !important; } }

@media only screen and (max-width: 767px) {
  .pt--120,
  .pt--130,
  .pt--100 {
    padding-top: 60px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb--120,
  .pb--170,
  .pb--150 {
    padding-bottom: 80px !important; } }

@media only screen and (max-width: 767px) {
  .pb--120,
  .pb--170,
  .pb--150 {
    padding-bottom: 60px !important; } }

.ptb--5 {
  padding: 5px 0; }

.plr--5 {
  padding: 0 5px; }

.pt--5 {
  padding-top: 5px; }

.pb--5 {
  padding-bottom: 5px; }

.pl--5 {
  padding-left: 5px; }

.pr--5 {
  padding-right: 5px; }

.mt--5 {
  margin-top: 5px !important; }

.mb--5 {
  margin-bottom: 5px !important; }

.mr--5 {
  margin-right: 5px; }

.ml--5 {
  margin-left: 5px; }

.ptb--10 {
  padding: 10px 0; }

.plr--10 {
  padding: 0 10px; }

.pt--10 {
  padding-top: 10px; }

.pb--10 {
  padding-bottom: 10px; }

.pl--10 {
  padding-left: 10px; }

.pr--10 {
  padding-right: 10px; }

.mt--10 {
  margin-top: 10px !important; }

.mb--10 {
  margin-bottom: 10px !important; }

.mr--10 {
  margin-right: 10px; }

.ml--10 {
  margin-left: 10px; }

.ptb--15 {
  padding: 15px 0; }

.plr--15 {
  padding: 0 15px; }

.pt--15 {
  padding-top: 15px; }

.pb--15 {
  padding-bottom: 15px; }

.pl--15 {
  padding-left: 15px; }

.pr--15 {
  padding-right: 15px; }

.mt--15 {
  margin-top: 15px !important; }

.mb--15 {
  margin-bottom: 15px !important; }

.mr--15 {
  margin-right: 15px; }

.ml--15 {
  margin-left: 15px; }

.ptb--20 {
  padding: 20px 0; }

.plr--20 {
  padding: 0 20px; }

.pt--20 {
  padding-top: 20px; }

.pb--20 {
  padding-bottom: 20px; }

.pl--20 {
  padding-left: 20px; }

.pr--20 {
  padding-right: 20px; }

.mt--20 {
  margin-top: 20px !important; }

.mb--20 {
  margin-bottom: 20px !important; }

.mr--20 {
  margin-right: 20px; }

.ml--20 {
  margin-left: 20px; }

.ptb--25 {
  padding: 25px 0; }

.plr--25 {
  padding: 0 25px; }

.pt--25 {
  padding-top: 25px; }

.pb--25 {
  padding-bottom: 25px; }

.pl--25 {
  padding-left: 25px; }

.pr--25 {
  padding-right: 25px; }

.mt--25 {
  margin-top: 25px !important; }

.mb--25 {
  margin-bottom: 25px !important; }

.mr--25 {
  margin-right: 25px; }

.ml--25 {
  margin-left: 25px; }

.ptb--30 {
  padding: 30px 0; }

.plr--30 {
  padding: 0 30px; }

.pt--30 {
  padding-top: 30px; }

.pb--30 {
  padding-bottom: 30px; }

.pl--30 {
  padding-left: 30px; }

.pr--30 {
  padding-right: 30px; }

.mt--30 {
  margin-top: 30px !important; }

.mb--30 {
  margin-bottom: 30px !important; }

.mr--30 {
  margin-right: 30px; }

.ml--30 {
  margin-left: 30px; }

.ptb--35 {
  padding: 35px 0; }

.plr--35 {
  padding: 0 35px; }

.pt--35 {
  padding-top: 35px; }

.pb--35 {
  padding-bottom: 35px; }

.pl--35 {
  padding-left: 35px; }

.pr--35 {
  padding-right: 35px; }

.mt--35 {
  margin-top: 35px !important; }

.mb--35 {
  margin-bottom: 35px !important; }

.mr--35 {
  margin-right: 35px; }

.ml--35 {
  margin-left: 35px; }

.ptb--40 {
  padding: 40px 0; }

.plr--40 {
  padding: 0 40px; }

.pt--40 {
  padding-top: 40px; }

.pb--40 {
  padding-bottom: 40px; }

.pl--40 {
  padding-left: 40px; }

.pr--40 {
  padding-right: 40px; }

.mt--40 {
  margin-top: 40px !important; }

.mb--40 {
  margin-bottom: 40px !important; }

.mr--40 {
  margin-right: 40px; }

.ml--40 {
  margin-left: 40px; }

.ptb--45 {
  padding: 45px 0; }

.plr--45 {
  padding: 0 45px; }

.pt--45 {
  padding-top: 45px; }

.pb--45 {
  padding-bottom: 45px; }

.pl--45 {
  padding-left: 45px; }

.pr--45 {
  padding-right: 45px; }

.mt--45 {
  margin-top: 45px !important; }

.mb--45 {
  margin-bottom: 45px !important; }

.mr--45 {
  margin-right: 45px; }

.ml--45 {
  margin-left: 45px; }

.ptb--50 {
  padding: 50px 0; }

.plr--50 {
  padding: 0 50px; }

.pt--50 {
  padding-top: 50px; }

.pb--50 {
  padding-bottom: 50px; }

.pl--50 {
  padding-left: 50px; }

.pr--50 {
  padding-right: 50px; }

.mt--50 {
  margin-top: 50px !important; }

.mb--50 {
  margin-bottom: 50px !important; }

.mr--50 {
  margin-right: 50px; }

.ml--50 {
  margin-left: 50px; }

.ptb--55 {
  padding: 55px 0; }

.plr--55 {
  padding: 0 55px; }

.pt--55 {
  padding-top: 55px; }

.pb--55 {
  padding-bottom: 55px; }

.pl--55 {
  padding-left: 55px; }

.pr--55 {
  padding-right: 55px; }

.mt--55 {
  margin-top: 55px !important; }

.mb--55 {
  margin-bottom: 55px !important; }

.mr--55 {
  margin-right: 55px; }

.ml--55 {
  margin-left: 55px; }

.ptb--60 {
  padding: 60px 0; }

.plr--60 {
  padding: 0 60px; }

.pt--60 {
  padding-top: 60px; }

.pb--60 {
  padding-bottom: 60px; }

.pl--60 {
  padding-left: 60px; }

.pr--60 {
  padding-right: 60px; }

.mt--60 {
  margin-top: 60px !important; }

.mb--60 {
  margin-bottom: 60px !important; }

.mr--60 {
  margin-right: 60px; }

.ml--60 {
  margin-left: 60px; }

.ptb--65 {
  padding: 65px 0; }

.plr--65 {
  padding: 0 65px; }

.pt--65 {
  padding-top: 65px; }

.pb--65 {
  padding-bottom: 65px; }

.pl--65 {
  padding-left: 65px; }

.pr--65 {
  padding-right: 65px; }

.mt--65 {
  margin-top: 65px !important; }

.mb--65 {
  margin-bottom: 65px !important; }

.mr--65 {
  margin-right: 65px; }

.ml--65 {
  margin-left: 65px; }

.ptb--70 {
  padding: 70px 0; }

.plr--70 {
  padding: 0 70px; }

.pt--70 {
  padding-top: 70px; }

.pb--70 {
  padding-bottom: 70px; }

.pl--70 {
  padding-left: 70px; }

.pr--70 {
  padding-right: 70px; }

.mt--70 {
  margin-top: 70px !important; }

.mb--70 {
  margin-bottom: 70px !important; }

.mr--70 {
  margin-right: 70px; }

.ml--70 {
  margin-left: 70px; }

.ptb--75 {
  padding: 75px 0; }

.plr--75 {
  padding: 0 75px; }

.pt--75 {
  padding-top: 75px; }

.pb--75 {
  padding-bottom: 75px; }

.pl--75 {
  padding-left: 75px; }

.pr--75 {
  padding-right: 75px; }

.mt--75 {
  margin-top: 75px !important; }

.mb--75 {
  margin-bottom: 75px !important; }

.mr--75 {
  margin-right: 75px; }

.ml--75 {
  margin-left: 75px; }

.ptb--80 {
  padding: 80px 0; }

.plr--80 {
  padding: 0 80px; }

.pt--80 {
  padding-top: 80px; }

.pb--80 {
  padding-bottom: 80px; }

.pl--80 {
  padding-left: 80px; }

.pr--80 {
  padding-right: 80px; }

.mt--80 {
  margin-top: 80px !important; }

.mb--80 {
  margin-bottom: 80px !important; }

.mr--80 {
  margin-right: 80px; }

.ml--80 {
  margin-left: 80px; }

.ptb--85 {
  padding: 85px 0; }

.plr--85 {
  padding: 0 85px; }

.pt--85 {
  padding-top: 85px; }

.pb--85 {
  padding-bottom: 85px; }

.pl--85 {
  padding-left: 85px; }

.pr--85 {
  padding-right: 85px; }

.mt--85 {
  margin-top: 85px !important; }

.mb--85 {
  margin-bottom: 85px !important; }

.mr--85 {
  margin-right: 85px; }

.ml--85 {
  margin-left: 85px; }

.ptb--90 {
  padding: 90px 0; }

.plr--90 {
  padding: 0 90px; }

.pt--90 {
  padding-top: 90px; }

.pb--90 {
  padding-bottom: 90px; }

.pl--90 {
  padding-left: 90px; }

.pr--90 {
  padding-right: 90px; }

.mt--90 {
  margin-top: 90px !important; }

.mb--90 {
  margin-bottom: 90px !important; }

.mr--90 {
  margin-right: 90px; }

.ml--90 {
  margin-left: 90px; }

.ptb--95 {
  padding: 95px 0; }

.plr--95 {
  padding: 0 95px; }

.pt--95 {
  padding-top: 95px; }

.pb--95 {
  padding-bottom: 95px; }

.pl--95 {
  padding-left: 95px; }

.pr--95 {
  padding-right: 95px; }

.mt--95 {
  margin-top: 95px !important; }

.mb--95 {
  margin-bottom: 95px !important; }

.mr--95 {
  margin-right: 95px; }

.ml--95 {
  margin-left: 95px; }

.ptb--100 {
  padding: 100px 0; }

.plr--100 {
  padding: 0 100px; }

.pt--100 {
  padding-top: 100px; }

.pb--100 {
  padding-bottom: 100px; }

.pl--100 {
  padding-left: 100px; }

.pr--100 {
  padding-right: 100px; }

.mt--100 {
  margin-top: 100px !important; }

.mb--100 {
  margin-bottom: 100px !important; }

.mr--100 {
  margin-right: 100px; }

.ml--100 {
  margin-left: 100px; }

.ptb--105 {
  padding: 105px 0; }

.plr--105 {
  padding: 0 105px; }

.pt--105 {
  padding-top: 105px; }

.pb--105 {
  padding-bottom: 105px; }

.pl--105 {
  padding-left: 105px; }

.pr--105 {
  padding-right: 105px; }

.mt--105 {
  margin-top: 105px !important; }

.mb--105 {
  margin-bottom: 105px !important; }

.mr--105 {
  margin-right: 105px; }

.ml--105 {
  margin-left: 105px; }

.ptb--110 {
  padding: 110px 0; }

.plr--110 {
  padding: 0 110px; }

.pt--110 {
  padding-top: 110px; }

.pb--110 {
  padding-bottom: 110px; }

.pl--110 {
  padding-left: 110px; }

.pr--110 {
  padding-right: 110px; }

.mt--110 {
  margin-top: 110px !important; }

.mb--110 {
  margin-bottom: 110px !important; }

.mr--110 {
  margin-right: 110px; }

.ml--110 {
  margin-left: 110px; }

.ptb--115 {
  padding: 115px 0; }

.plr--115 {
  padding: 0 115px; }

.pt--115 {
  padding-top: 115px; }

.pb--115 {
  padding-bottom: 115px; }

.pl--115 {
  padding-left: 115px; }

.pr--115 {
  padding-right: 115px; }

.mt--115 {
  margin-top: 115px !important; }

.mb--115 {
  margin-bottom: 115px !important; }

.mr--115 {
  margin-right: 115px; }

.ml--115 {
  margin-left: 115px; }

.ptb--120 {
  padding: 120px 0; }

.plr--120 {
  padding: 0 120px; }

.pt--120 {
  padding-top: 120px; }

.pb--120 {
  padding-bottom: 120px; }

.pl--120 {
  padding-left: 120px; }

.pr--120 {
  padding-right: 120px; }

.mt--120 {
  margin-top: 120px !important; }

.mb--120 {
  margin-bottom: 120px !important; }

.mr--120 {
  margin-right: 120px; }

.ml--120 {
  margin-left: 120px; }

.ptb--125 {
  padding: 125px 0; }

.plr--125 {
  padding: 0 125px; }

.pt--125 {
  padding-top: 125px; }

.pb--125 {
  padding-bottom: 125px; }

.pl--125 {
  padding-left: 125px; }

.pr--125 {
  padding-right: 125px; }

.mt--125 {
  margin-top: 125px !important; }

.mb--125 {
  margin-bottom: 125px !important; }

.mr--125 {
  margin-right: 125px; }

.ml--125 {
  margin-left: 125px; }

.ptb--130 {
  padding: 130px 0; }

.plr--130 {
  padding: 0 130px; }

.pt--130 {
  padding-top: 130px; }

.pb--130 {
  padding-bottom: 130px; }

.pl--130 {
  padding-left: 130px; }

.pr--130 {
  padding-right: 130px; }

.mt--130 {
  margin-top: 130px !important; }

.mb--130 {
  margin-bottom: 130px !important; }

.mr--130 {
  margin-right: 130px; }

.ml--130 {
  margin-left: 130px; }

.ptb--135 {
  padding: 135px 0; }

.plr--135 {
  padding: 0 135px; }

.pt--135 {
  padding-top: 135px; }

.pb--135 {
  padding-bottom: 135px; }

.pl--135 {
  padding-left: 135px; }

.pr--135 {
  padding-right: 135px; }

.mt--135 {
  margin-top: 135px !important; }

.mb--135 {
  margin-bottom: 135px !important; }

.mr--135 {
  margin-right: 135px; }

.ml--135 {
  margin-left: 135px; }

.ptb--140 {
  padding: 140px 0; }

.plr--140 {
  padding: 0 140px; }

.pt--140 {
  padding-top: 140px; }

.pb--140 {
  padding-bottom: 140px; }

.pl--140 {
  padding-left: 140px; }

.pr--140 {
  padding-right: 140px; }

.mt--140 {
  margin-top: 140px !important; }

.mb--140 {
  margin-bottom: 140px !important; }

.mr--140 {
  margin-right: 140px; }

.ml--140 {
  margin-left: 140px; }

.ptb--145 {
  padding: 145px 0; }

.plr--145 {
  padding: 0 145px; }

.pt--145 {
  padding-top: 145px; }

.pb--145 {
  padding-bottom: 145px; }

.pl--145 {
  padding-left: 145px; }

.pr--145 {
  padding-right: 145px; }

.mt--145 {
  margin-top: 145px !important; }

.mb--145 {
  margin-bottom: 145px !important; }

.mr--145 {
  margin-right: 145px; }

.ml--145 {
  margin-left: 145px; }

.ptb--150 {
  padding: 150px 0; }

.plr--150 {
  padding: 0 150px; }

.pt--150 {
  padding-top: 150px; }

.pb--150 {
  padding-bottom: 150px; }

.pl--150 {
  padding-left: 150px; }

.pr--150 {
  padding-right: 150px; }

.mt--150 {
  margin-top: 150px !important; }

.mb--150 {
  margin-bottom: 150px !important; }

.mr--150 {
  margin-right: 150px; }

.ml--150 {
  margin-left: 150px; }

.ptb--155 {
  padding: 155px 0; }

.plr--155 {
  padding: 0 155px; }

.pt--155 {
  padding-top: 155px; }

.pb--155 {
  padding-bottom: 155px; }

.pl--155 {
  padding-left: 155px; }

.pr--155 {
  padding-right: 155px; }

.mt--155 {
  margin-top: 155px !important; }

.mb--155 {
  margin-bottom: 155px !important; }

.mr--155 {
  margin-right: 155px; }

.ml--155 {
  margin-left: 155px; }

.ptb--160 {
  padding: 160px 0; }

.plr--160 {
  padding: 0 160px; }

.pt--160 {
  padding-top: 160px; }

.pb--160 {
  padding-bottom: 160px; }

.pl--160 {
  padding-left: 160px; }

.pr--160 {
  padding-right: 160px; }

.mt--160 {
  margin-top: 160px !important; }

.mb--160 {
  margin-bottom: 160px !important; }

.mr--160 {
  margin-right: 160px; }

.ml--160 {
  margin-left: 160px; }

.ptb--165 {
  padding: 165px 0; }

.plr--165 {
  padding: 0 165px; }

.pt--165 {
  padding-top: 165px; }

.pb--165 {
  padding-bottom: 165px; }

.pl--165 {
  padding-left: 165px; }

.pr--165 {
  padding-right: 165px; }

.mt--165 {
  margin-top: 165px !important; }

.mb--165 {
  margin-bottom: 165px !important; }

.mr--165 {
  margin-right: 165px; }

.ml--165 {
  margin-left: 165px; }

.ptb--170 {
  padding: 170px 0; }

.plr--170 {
  padding: 0 170px; }

.pt--170 {
  padding-top: 170px; }

.pb--170 {
  padding-bottom: 170px; }

.pl--170 {
  padding-left: 170px; }

.pr--170 {
  padding-right: 170px; }

.mt--170 {
  margin-top: 170px !important; }

.mb--170 {
  margin-bottom: 170px !important; }

.mr--170 {
  margin-right: 170px; }

.ml--170 {
  margin-left: 170px; }

.ptb--175 {
  padding: 175px 0; }

.plr--175 {
  padding: 0 175px; }

.pt--175 {
  padding-top: 175px; }

.pb--175 {
  padding-bottom: 175px; }

.pl--175 {
  padding-left: 175px; }

.pr--175 {
  padding-right: 175px; }

.mt--175 {
  margin-top: 175px !important; }

.mb--175 {
  margin-bottom: 175px !important; }

.mr--175 {
  margin-right: 175px; }

.ml--175 {
  margin-left: 175px; }

.ptb--180 {
  padding: 180px 0; }

.plr--180 {
  padding: 0 180px; }

.pt--180 {
  padding-top: 180px; }

.pb--180 {
  padding-bottom: 180px; }

.pl--180 {
  padding-left: 180px; }

.pr--180 {
  padding-right: 180px; }

.mt--180 {
  margin-top: 180px !important; }

.mb--180 {
  margin-bottom: 180px !important; }

.mr--180 {
  margin-right: 180px; }

.ml--180 {
  margin-left: 180px; }

.ptb--185 {
  padding: 185px 0; }

.plr--185 {
  padding: 0 185px; }

.pt--185 {
  padding-top: 185px; }

.pb--185 {
  padding-bottom: 185px; }

.pl--185 {
  padding-left: 185px; }

.pr--185 {
  padding-right: 185px; }

.mt--185 {
  margin-top: 185px !important; }

.mb--185 {
  margin-bottom: 185px !important; }

.mr--185 {
  margin-right: 185px; }

.ml--185 {
  margin-left: 185px; }

.ptb--190 {
  padding: 190px 0; }

.plr--190 {
  padding: 0 190px; }

.pt--190 {
  padding-top: 190px; }

.pb--190 {
  padding-bottom: 190px; }

.pl--190 {
  padding-left: 190px; }

.pr--190 {
  padding-right: 190px; }

.mt--190 {
  margin-top: 190px !important; }

.mb--190 {
  margin-bottom: 190px !important; }

.mr--190 {
  margin-right: 190px; }

.ml--190 {
  margin-left: 190px; }

.ptb--195 {
  padding: 195px 0; }

.plr--195 {
  padding: 0 195px; }

.pt--195 {
  padding-top: 195px; }

.pb--195 {
  padding-bottom: 195px; }

.pl--195 {
  padding-left: 195px; }

.pr--195 {
  padding-right: 195px; }

.mt--195 {
  margin-top: 195px !important; }

.mb--195 {
  margin-bottom: 195px !important; }

.mr--195 {
  margin-right: 195px; }

.ml--195 {
  margin-left: 195px; }

.ptb--200 {
  padding: 200px 0; }

.plr--200 {
  padding: 0 200px; }

.pt--200 {
  padding-top: 200px; }

.pb--200 {
  padding-bottom: 200px; }

.pl--200 {
  padding-left: 200px; }

.pr--200 {
  padding-right: 200px; }

.mt--200 {
  margin-top: 200px !important; }

.mb--200 {
  margin-bottom: 200px !important; }

.mr--200 {
  margin-right: 200px; }

.ml--200 {
  margin-left: 200px; }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .ptb_lp--5 {
    padding: 5px 0; }
  .plr_lp--5 {
    padding: 0 5px; }
  .pt_lp--5 {
    padding-top: 5px; }
  .pb_lp--5 {
    padding-bottom: 5px; }
  .pl_lp--5 {
    padding-left: 5px; }
  .pr_lp--5 {
    padding-right: 5px; }
  .mt_lp--5 {
    margin-top: 5px; }
  .mb_lp--5 {
    margin-bottom: 5px; }
  .ptb_lp--10 {
    padding: 10px 0; }
  .plr_lp--10 {
    padding: 0 10px; }
  .pt_lp--10 {
    padding-top: 10px; }
  .pb_lp--10 {
    padding-bottom: 10px; }
  .pl_lp--10 {
    padding-left: 10px; }
  .pr_lp--10 {
    padding-right: 10px; }
  .mt_lp--10 {
    margin-top: 10px; }
  .mb_lp--10 {
    margin-bottom: 10px; }
  .ptb_lp--15 {
    padding: 15px 0; }
  .plr_lp--15 {
    padding: 0 15px; }
  .pt_lp--15 {
    padding-top: 15px; }
  .pb_lp--15 {
    padding-bottom: 15px; }
  .pl_lp--15 {
    padding-left: 15px; }
  .pr_lp--15 {
    padding-right: 15px; }
  .mt_lp--15 {
    margin-top: 15px; }
  .mb_lp--15 {
    margin-bottom: 15px; }
  .ptb_lp--20 {
    padding: 20px 0; }
  .plr_lp--20 {
    padding: 0 20px; }
  .pt_lp--20 {
    padding-top: 20px; }
  .pb_lp--20 {
    padding-bottom: 20px; }
  .pl_lp--20 {
    padding-left: 20px; }
  .pr_lp--20 {
    padding-right: 20px; }
  .mt_lp--20 {
    margin-top: 20px; }
  .mb_lp--20 {
    margin-bottom: 20px; }
  .ptb_lp--25 {
    padding: 25px 0; }
  .plr_lp--25 {
    padding: 0 25px; }
  .pt_lp--25 {
    padding-top: 25px; }
  .pb_lp--25 {
    padding-bottom: 25px; }
  .pl_lp--25 {
    padding-left: 25px; }
  .pr_lp--25 {
    padding-right: 25px; }
  .mt_lp--25 {
    margin-top: 25px; }
  .mb_lp--25 {
    margin-bottom: 25px; }
  .ptb_lp--30 {
    padding: 30px 0; }
  .plr_lp--30 {
    padding: 0 30px; }
  .pt_lp--30 {
    padding-top: 30px; }
  .pb_lp--30 {
    padding-bottom: 30px; }
  .pl_lp--30 {
    padding-left: 30px; }
  .pr_lp--30 {
    padding-right: 30px; }
  .mt_lp--30 {
    margin-top: 30px; }
  .mb_lp--30 {
    margin-bottom: 30px; }
  .ptb_lp--35 {
    padding: 35px 0; }
  .plr_lp--35 {
    padding: 0 35px; }
  .pt_lp--35 {
    padding-top: 35px; }
  .pb_lp--35 {
    padding-bottom: 35px; }
  .pl_lp--35 {
    padding-left: 35px; }
  .pr_lp--35 {
    padding-right: 35px; }
  .mt_lp--35 {
    margin-top: 35px; }
  .mb_lp--35 {
    margin-bottom: 35px; }
  .ptb_lp--40 {
    padding: 40px 0; }
  .plr_lp--40 {
    padding: 0 40px; }
  .pt_lp--40 {
    padding-top: 40px; }
  .pb_lp--40 {
    padding-bottom: 40px; }
  .pl_lp--40 {
    padding-left: 40px; }
  .pr_lp--40 {
    padding-right: 40px; }
  .mt_lp--40 {
    margin-top: 40px; }
  .mb_lp--40 {
    margin-bottom: 40px; }
  .ptb_lp--45 {
    padding: 45px 0; }
  .plr_lp--45 {
    padding: 0 45px; }
  .pt_lp--45 {
    padding-top: 45px; }
  .pb_lp--45 {
    padding-bottom: 45px; }
  .pl_lp--45 {
    padding-left: 45px; }
  .pr_lp--45 {
    padding-right: 45px; }
  .mt_lp--45 {
    margin-top: 45px; }
  .mb_lp--45 {
    margin-bottom: 45px; }
  .ptb_lp--50 {
    padding: 50px 0; }
  .plr_lp--50 {
    padding: 0 50px; }
  .pt_lp--50 {
    padding-top: 50px; }
  .pb_lp--50 {
    padding-bottom: 50px; }
  .pl_lp--50 {
    padding-left: 50px; }
  .pr_lp--50 {
    padding-right: 50px; }
  .mt_lp--50 {
    margin-top: 50px; }
  .mb_lp--50 {
    margin-bottom: 50px; }
  .ptb_lp--55 {
    padding: 55px 0; }
  .plr_lp--55 {
    padding: 0 55px; }
  .pt_lp--55 {
    padding-top: 55px; }
  .pb_lp--55 {
    padding-bottom: 55px; }
  .pl_lp--55 {
    padding-left: 55px; }
  .pr_lp--55 {
    padding-right: 55px; }
  .mt_lp--55 {
    margin-top: 55px; }
  .mb_lp--55 {
    margin-bottom: 55px; }
  .ptb_lp--60 {
    padding: 60px 0; }
  .plr_lp--60 {
    padding: 0 60px; }
  .pt_lp--60 {
    padding-top: 60px; }
  .pb_lp--60 {
    padding-bottom: 60px; }
  .pl_lp--60 {
    padding-left: 60px; }
  .pr_lp--60 {
    padding-right: 60px; }
  .mt_lp--60 {
    margin-top: 60px; }
  .mb_lp--60 {
    margin-bottom: 60px; }
  .ptb_lp--65 {
    padding: 65px 0; }
  .plr_lp--65 {
    padding: 0 65px; }
  .pt_lp--65 {
    padding-top: 65px; }
  .pb_lp--65 {
    padding-bottom: 65px; }
  .pl_lp--65 {
    padding-left: 65px; }
  .pr_lp--65 {
    padding-right: 65px; }
  .mt_lp--65 {
    margin-top: 65px; }
  .mb_lp--65 {
    margin-bottom: 65px; }
  .ptb_lp--70 {
    padding: 70px 0; }
  .plr_lp--70 {
    padding: 0 70px; }
  .pt_lp--70 {
    padding-top: 70px; }
  .pb_lp--70 {
    padding-bottom: 70px; }
  .pl_lp--70 {
    padding-left: 70px; }
  .pr_lp--70 {
    padding-right: 70px; }
  .mt_lp--70 {
    margin-top: 70px; }
  .mb_lp--70 {
    margin-bottom: 70px; }
  .ptb_lp--75 {
    padding: 75px 0; }
  .plr_lp--75 {
    padding: 0 75px; }
  .pt_lp--75 {
    padding-top: 75px; }
  .pb_lp--75 {
    padding-bottom: 75px; }
  .pl_lp--75 {
    padding-left: 75px; }
  .pr_lp--75 {
    padding-right: 75px; }
  .mt_lp--75 {
    margin-top: 75px; }
  .mb_lp--75 {
    margin-bottom: 75px; }
  .ptb_lp--80 {
    padding: 80px 0; }
  .plr_lp--80 {
    padding: 0 80px; }
  .pt_lp--80 {
    padding-top: 80px; }
  .pb_lp--80 {
    padding-bottom: 80px; }
  .pl_lp--80 {
    padding-left: 80px; }
  .pr_lp--80 {
    padding-right: 80px; }
  .mt_lp--80 {
    margin-top: 80px; }
  .mb_lp--80 {
    margin-bottom: 80px; }
  .ptb_lp--85 {
    padding: 85px 0; }
  .plr_lp--85 {
    padding: 0 85px; }
  .pt_lp--85 {
    padding-top: 85px; }
  .pb_lp--85 {
    padding-bottom: 85px; }
  .pl_lp--85 {
    padding-left: 85px; }
  .pr_lp--85 {
    padding-right: 85px; }
  .mt_lp--85 {
    margin-top: 85px; }
  .mb_lp--85 {
    margin-bottom: 85px; }
  .ptb_lp--90 {
    padding: 90px 0; }
  .plr_lp--90 {
    padding: 0 90px; }
  .pt_lp--90 {
    padding-top: 90px; }
  .pb_lp--90 {
    padding-bottom: 90px; }
  .pl_lp--90 {
    padding-left: 90px; }
  .pr_lp--90 {
    padding-right: 90px; }
  .mt_lp--90 {
    margin-top: 90px; }
  .mb_lp--90 {
    margin-bottom: 90px; }
  .ptb_lp--95 {
    padding: 95px 0; }
  .plr_lp--95 {
    padding: 0 95px; }
  .pt_lp--95 {
    padding-top: 95px; }
  .pb_lp--95 {
    padding-bottom: 95px; }
  .pl_lp--95 {
    padding-left: 95px; }
  .pr_lp--95 {
    padding-right: 95px; }
  .mt_lp--95 {
    margin-top: 95px; }
  .mb_lp--95 {
    margin-bottom: 95px; }
  .ptb_lp--100 {
    padding: 100px 0; }
  .plr_lp--100 {
    padding: 0 100px; }
  .pt_lp--100 {
    padding-top: 100px; }
  .pb_lp--100 {
    padding-bottom: 100px; }
  .pl_lp--100 {
    padding-left: 100px; }
  .pr_lp--100 {
    padding-right: 100px; }
  .mt_lp--100 {
    margin-top: 100px; }
  .mb_lp--100 {
    margin-bottom: 100px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt_lg--0 {
    margin-top: 0 !important; }
  .ptb_lg--5 {
    padding: 5px 0 !important; }
  .plr_lg--5 {
    padding: 0 5px !important; }
  .pt_lg--5 {
    padding-top: 5px !important; }
  .pb_lg--5 {
    padding-bottom: 5px !important; }
  .pl_lg--5 {
    padding-left: 5px !important; }
  .pr_lg--5 {
    padding-right: 5px !important; }
  .mt_lg--5 {
    margin-top: 5px !important; }
  .mb_lg--5 {
    margin-bottom: 5px !important; }
  .ptb_lg--10 {
    padding: 10px 0 !important; }
  .plr_lg--10 {
    padding: 0 10px !important; }
  .pt_lg--10 {
    padding-top: 10px !important; }
  .pb_lg--10 {
    padding-bottom: 10px !important; }
  .pl_lg--10 {
    padding-left: 10px !important; }
  .pr_lg--10 {
    padding-right: 10px !important; }
  .mt_lg--10 {
    margin-top: 10px !important; }
  .mb_lg--10 {
    margin-bottom: 10px !important; }
  .ptb_lg--15 {
    padding: 15px 0 !important; }
  .plr_lg--15 {
    padding: 0 15px !important; }
  .pt_lg--15 {
    padding-top: 15px !important; }
  .pb_lg--15 {
    padding-bottom: 15px !important; }
  .pl_lg--15 {
    padding-left: 15px !important; }
  .pr_lg--15 {
    padding-right: 15px !important; }
  .mt_lg--15 {
    margin-top: 15px !important; }
  .mb_lg--15 {
    margin-bottom: 15px !important; }
  .ptb_lg--20 {
    padding: 20px 0 !important; }
  .plr_lg--20 {
    padding: 0 20px !important; }
  .pt_lg--20 {
    padding-top: 20px !important; }
  .pb_lg--20 {
    padding-bottom: 20px !important; }
  .pl_lg--20 {
    padding-left: 20px !important; }
  .pr_lg--20 {
    padding-right: 20px !important; }
  .mt_lg--20 {
    margin-top: 20px !important; }
  .mb_lg--20 {
    margin-bottom: 20px !important; }
  .ptb_lg--25 {
    padding: 25px 0 !important; }
  .plr_lg--25 {
    padding: 0 25px !important; }
  .pt_lg--25 {
    padding-top: 25px !important; }
  .pb_lg--25 {
    padding-bottom: 25px !important; }
  .pl_lg--25 {
    padding-left: 25px !important; }
  .pr_lg--25 {
    padding-right: 25px !important; }
  .mt_lg--25 {
    margin-top: 25px !important; }
  .mb_lg--25 {
    margin-bottom: 25px !important; }
  .ptb_lg--30 {
    padding: 30px 0 !important; }
  .plr_lg--30 {
    padding: 0 30px !important; }
  .pt_lg--30 {
    padding-top: 30px !important; }
  .pb_lg--30 {
    padding-bottom: 30px !important; }
  .pl_lg--30 {
    padding-left: 30px !important; }
  .pr_lg--30 {
    padding-right: 30px !important; }
  .mt_lg--30 {
    margin-top: 30px !important; }
  .mb_lg--30 {
    margin-bottom: 30px !important; }
  .ptb_lg--35 {
    padding: 35px 0 !important; }
  .plr_lg--35 {
    padding: 0 35px !important; }
  .pt_lg--35 {
    padding-top: 35px !important; }
  .pb_lg--35 {
    padding-bottom: 35px !important; }
  .pl_lg--35 {
    padding-left: 35px !important; }
  .pr_lg--35 {
    padding-right: 35px !important; }
  .mt_lg--35 {
    margin-top: 35px !important; }
  .mb_lg--35 {
    margin-bottom: 35px !important; }
  .ptb_lg--40 {
    padding: 40px 0 !important; }
  .plr_lg--40 {
    padding: 0 40px !important; }
  .pt_lg--40 {
    padding-top: 40px !important; }
  .pb_lg--40 {
    padding-bottom: 40px !important; }
  .pl_lg--40 {
    padding-left: 40px !important; }
  .pr_lg--40 {
    padding-right: 40px !important; }
  .mt_lg--40 {
    margin-top: 40px !important; }
  .mb_lg--40 {
    margin-bottom: 40px !important; }
  .ptb_lg--45 {
    padding: 45px 0 !important; }
  .plr_lg--45 {
    padding: 0 45px !important; }
  .pt_lg--45 {
    padding-top: 45px !important; }
  .pb_lg--45 {
    padding-bottom: 45px !important; }
  .pl_lg--45 {
    padding-left: 45px !important; }
  .pr_lg--45 {
    padding-right: 45px !important; }
  .mt_lg--45 {
    margin-top: 45px !important; }
  .mb_lg--45 {
    margin-bottom: 45px !important; }
  .ptb_lg--50 {
    padding: 50px 0 !important; }
  .plr_lg--50 {
    padding: 0 50px !important; }
  .pt_lg--50 {
    padding-top: 50px !important; }
  .pb_lg--50 {
    padding-bottom: 50px !important; }
  .pl_lg--50 {
    padding-left: 50px !important; }
  .pr_lg--50 {
    padding-right: 50px !important; }
  .mt_lg--50 {
    margin-top: 50px !important; }
  .mb_lg--50 {
    margin-bottom: 50px !important; }
  .ptb_lg--55 {
    padding: 55px 0 !important; }
  .plr_lg--55 {
    padding: 0 55px !important; }
  .pt_lg--55 {
    padding-top: 55px !important; }
  .pb_lg--55 {
    padding-bottom: 55px !important; }
  .pl_lg--55 {
    padding-left: 55px !important; }
  .pr_lg--55 {
    padding-right: 55px !important; }
  .mt_lg--55 {
    margin-top: 55px !important; }
  .mb_lg--55 {
    margin-bottom: 55px !important; }
  .ptb_lg--60 {
    padding: 60px 0 !important; }
  .plr_lg--60 {
    padding: 0 60px !important; }
  .pt_lg--60 {
    padding-top: 60px !important; }
  .pb_lg--60 {
    padding-bottom: 60px !important; }
  .pl_lg--60 {
    padding-left: 60px !important; }
  .pr_lg--60 {
    padding-right: 60px !important; }
  .mt_lg--60 {
    margin-top: 60px !important; }
  .mb_lg--60 {
    margin-bottom: 60px !important; }
  .ptb_lg--65 {
    padding: 65px 0 !important; }
  .plr_lg--65 {
    padding: 0 65px !important; }
  .pt_lg--65 {
    padding-top: 65px !important; }
  .pb_lg--65 {
    padding-bottom: 65px !important; }
  .pl_lg--65 {
    padding-left: 65px !important; }
  .pr_lg--65 {
    padding-right: 65px !important; }
  .mt_lg--65 {
    margin-top: 65px !important; }
  .mb_lg--65 {
    margin-bottom: 65px !important; }
  .ptb_lg--70 {
    padding: 70px 0 !important; }
  .plr_lg--70 {
    padding: 0 70px !important; }
  .pt_lg--70 {
    padding-top: 70px !important; }
  .pb_lg--70 {
    padding-bottom: 70px !important; }
  .pl_lg--70 {
    padding-left: 70px !important; }
  .pr_lg--70 {
    padding-right: 70px !important; }
  .mt_lg--70 {
    margin-top: 70px !important; }
  .mb_lg--70 {
    margin-bottom: 70px !important; }
  .ptb_lg--75 {
    padding: 75px 0 !important; }
  .plr_lg--75 {
    padding: 0 75px !important; }
  .pt_lg--75 {
    padding-top: 75px !important; }
  .pb_lg--75 {
    padding-bottom: 75px !important; }
  .pl_lg--75 {
    padding-left: 75px !important; }
  .pr_lg--75 {
    padding-right: 75px !important; }
  .mt_lg--75 {
    margin-top: 75px !important; }
  .mb_lg--75 {
    margin-bottom: 75px !important; }
  .ptb_lg--80 {
    padding: 80px 0 !important; }
  .plr_lg--80 {
    padding: 0 80px !important; }
  .pt_lg--80 {
    padding-top: 80px !important; }
  .pb_lg--80 {
    padding-bottom: 80px !important; }
  .pl_lg--80 {
    padding-left: 80px !important; }
  .pr_lg--80 {
    padding-right: 80px !important; }
  .mt_lg--80 {
    margin-top: 80px !important; }
  .mb_lg--80 {
    margin-bottom: 80px !important; }
  .ptb_lg--85 {
    padding: 85px 0 !important; }
  .plr_lg--85 {
    padding: 0 85px !important; }
  .pt_lg--85 {
    padding-top: 85px !important; }
  .pb_lg--85 {
    padding-bottom: 85px !important; }
  .pl_lg--85 {
    padding-left: 85px !important; }
  .pr_lg--85 {
    padding-right: 85px !important; }
  .mt_lg--85 {
    margin-top: 85px !important; }
  .mb_lg--85 {
    margin-bottom: 85px !important; }
  .ptb_lg--90 {
    padding: 90px 0 !important; }
  .plr_lg--90 {
    padding: 0 90px !important; }
  .pt_lg--90 {
    padding-top: 90px !important; }
  .pb_lg--90 {
    padding-bottom: 90px !important; }
  .pl_lg--90 {
    padding-left: 90px !important; }
  .pr_lg--90 {
    padding-right: 90px !important; }
  .mt_lg--90 {
    margin-top: 90px !important; }
  .mb_lg--90 {
    margin-bottom: 90px !important; }
  .ptb_lg--95 {
    padding: 95px 0 !important; }
  .plr_lg--95 {
    padding: 0 95px !important; }
  .pt_lg--95 {
    padding-top: 95px !important; }
  .pb_lg--95 {
    padding-bottom: 95px !important; }
  .pl_lg--95 {
    padding-left: 95px !important; }
  .pr_lg--95 {
    padding-right: 95px !important; }
  .mt_lg--95 {
    margin-top: 95px !important; }
  .mb_lg--95 {
    margin-bottom: 95px !important; }
  .ptb_lg--100 {
    padding: 100px 0 !important; }
  .plr_lg--100 {
    padding: 0 100px !important; }
  .pt_lg--100 {
    padding-top: 100px !important; }
  .pb_lg--100 {
    padding-bottom: 100px !important; }
  .pl_lg--100 {
    padding-left: 100px !important; }
  .pr_lg--100 {
    padding-right: 100px !important; }
  .mt_lg--100 {
    margin-top: 100px !important; }
  .mb_lg--100 {
    margin-bottom: 100px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb_md--0 {
    padding: 0 !important; }
  .pl_md--0 {
    padding-left: 0 !important; }
  .pr_md--0 {
    padding-right: 0 !important; }
  .pt_md--0 {
    padding-top: 0 !important; }
  .pb_md--0 {
    padding-bottom: 0 !important; }
  .mr_md--0 {
    margin-right: 0 !important; }
  .ml_md--0 {
    margin-left: 0 !important; }
  .mt_md--0 {
    margin-top: 0 !important; }
  .mb_md--0 {
    margin-bottom: 0 !important; }
  .ptb_md--250 {
    padding: 250px 0 !important; }
  .ptb_md--5 {
    padding: 5px 0 !important; }
  .plr_md--5 {
    padding: 0 5px !important; }
  .pt_md--5 {
    padding-top: 5px !important; }
  .pb_md--5 {
    padding-bottom: 5px !important; }
  .pl_md--5 {
    padding-left: 5px !important; }
  .pr_md--5 {
    padding-right: 5px !important; }
  .mt_md--5 {
    margin-top: 5px !important; }
  .mb_md--5 {
    margin-bottom: 5px !important; }
  .ptb_md--10 {
    padding: 10px 0 !important; }
  .plr_md--10 {
    padding: 0 10px !important; }
  .pt_md--10 {
    padding-top: 10px !important; }
  .pb_md--10 {
    padding-bottom: 10px !important; }
  .pl_md--10 {
    padding-left: 10px !important; }
  .pr_md--10 {
    padding-right: 10px !important; }
  .mt_md--10 {
    margin-top: 10px !important; }
  .mb_md--10 {
    margin-bottom: 10px !important; }
  .ptb_md--15 {
    padding: 15px 0 !important; }
  .plr_md--15 {
    padding: 0 15px !important; }
  .pt_md--15 {
    padding-top: 15px !important; }
  .pb_md--15 {
    padding-bottom: 15px !important; }
  .pl_md--15 {
    padding-left: 15px !important; }
  .pr_md--15 {
    padding-right: 15px !important; }
  .mt_md--15 {
    margin-top: 15px !important; }
  .mb_md--15 {
    margin-bottom: 15px !important; }
  .ptb_md--20 {
    padding: 20px 0 !important; }
  .plr_md--20 {
    padding: 0 20px !important; }
  .pt_md--20 {
    padding-top: 20px !important; }
  .pb_md--20 {
    padding-bottom: 20px !important; }
  .pl_md--20 {
    padding-left: 20px !important; }
  .pr_md--20 {
    padding-right: 20px !important; }
  .mt_md--20 {
    margin-top: 20px !important; }
  .mb_md--20 {
    margin-bottom: 20px !important; }
  .ptb_md--25 {
    padding: 25px 0 !important; }
  .plr_md--25 {
    padding: 0 25px !important; }
  .pt_md--25 {
    padding-top: 25px !important; }
  .pb_md--25 {
    padding-bottom: 25px !important; }
  .pl_md--25 {
    padding-left: 25px !important; }
  .pr_md--25 {
    padding-right: 25px !important; }
  .mt_md--25 {
    margin-top: 25px !important; }
  .mb_md--25 {
    margin-bottom: 25px !important; }
  .ptb_md--30 {
    padding: 30px 0 !important; }
  .plr_md--30 {
    padding: 0 30px !important; }
  .pt_md--30 {
    padding-top: 30px !important; }
  .pb_md--30 {
    padding-bottom: 30px !important; }
  .pl_md--30 {
    padding-left: 30px !important; }
  .pr_md--30 {
    padding-right: 30px !important; }
  .mt_md--30 {
    margin-top: 30px !important; }
  .mb_md--30 {
    margin-bottom: 30px !important; }
  .ptb_md--35 {
    padding: 35px 0 !important; }
  .plr_md--35 {
    padding: 0 35px !important; }
  .pt_md--35 {
    padding-top: 35px !important; }
  .pb_md--35 {
    padding-bottom: 35px !important; }
  .pl_md--35 {
    padding-left: 35px !important; }
  .pr_md--35 {
    padding-right: 35px !important; }
  .mt_md--35 {
    margin-top: 35px !important; }
  .mb_md--35 {
    margin-bottom: 35px !important; }
  .ptb_md--40 {
    padding: 40px 0 !important; }
  .plr_md--40 {
    padding: 0 40px !important; }
  .pt_md--40 {
    padding-top: 40px !important; }
  .pb_md--40 {
    padding-bottom: 40px !important; }
  .pl_md--40 {
    padding-left: 40px !important; }
  .pr_md--40 {
    padding-right: 40px !important; }
  .mt_md--40 {
    margin-top: 40px !important; }
  .mb_md--40 {
    margin-bottom: 40px !important; }
  .ptb_md--45 {
    padding: 45px 0 !important; }
  .plr_md--45 {
    padding: 0 45px !important; }
  .pt_md--45 {
    padding-top: 45px !important; }
  .pb_md--45 {
    padding-bottom: 45px !important; }
  .pl_md--45 {
    padding-left: 45px !important; }
  .pr_md--45 {
    padding-right: 45px !important; }
  .mt_md--45 {
    margin-top: 45px !important; }
  .mb_md--45 {
    margin-bottom: 45px !important; }
  .ptb_md--50 {
    padding: 50px 0 !important; }
  .plr_md--50 {
    padding: 0 50px !important; }
  .pt_md--50 {
    padding-top: 50px !important; }
  .pb_md--50 {
    padding-bottom: 50px !important; }
  .pl_md--50 {
    padding-left: 50px !important; }
  .pr_md--50 {
    padding-right: 50px !important; }
  .mt_md--50 {
    margin-top: 50px !important; }
  .mb_md--50 {
    margin-bottom: 50px !important; }
  .ptb_md--55 {
    padding: 55px 0 !important; }
  .plr_md--55 {
    padding: 0 55px !important; }
  .pt_md--55 {
    padding-top: 55px !important; }
  .pb_md--55 {
    padding-bottom: 55px !important; }
  .pl_md--55 {
    padding-left: 55px !important; }
  .pr_md--55 {
    padding-right: 55px !important; }
  .mt_md--55 {
    margin-top: 55px !important; }
  .mb_md--55 {
    margin-bottom: 55px !important; }
  .ptb_md--60 {
    padding: 60px 0 !important; }
  .plr_md--60 {
    padding: 0 60px !important; }
  .pt_md--60 {
    padding-top: 60px !important; }
  .pb_md--60 {
    padding-bottom: 60px !important; }
  .pl_md--60 {
    padding-left: 60px !important; }
  .pr_md--60 {
    padding-right: 60px !important; }
  .mt_md--60 {
    margin-top: 60px !important; }
  .mb_md--60 {
    margin-bottom: 60px !important; }
  .ptb_md--65 {
    padding: 65px 0 !important; }
  .plr_md--65 {
    padding: 0 65px !important; }
  .pt_md--65 {
    padding-top: 65px !important; }
  .pb_md--65 {
    padding-bottom: 65px !important; }
  .pl_md--65 {
    padding-left: 65px !important; }
  .pr_md--65 {
    padding-right: 65px !important; }
  .mt_md--65 {
    margin-top: 65px !important; }
  .mb_md--65 {
    margin-bottom: 65px !important; }
  .ptb_md--70 {
    padding: 70px 0 !important; }
  .plr_md--70 {
    padding: 0 70px !important; }
  .pt_md--70 {
    padding-top: 70px !important; }
  .pb_md--70 {
    padding-bottom: 70px !important; }
  .pl_md--70 {
    padding-left: 70px !important; }
  .pr_md--70 {
    padding-right: 70px !important; }
  .mt_md--70 {
    margin-top: 70px !important; }
  .mb_md--70 {
    margin-bottom: 70px !important; }
  .ptb_md--75 {
    padding: 75px 0 !important; }
  .plr_md--75 {
    padding: 0 75px !important; }
  .pt_md--75 {
    padding-top: 75px !important; }
  .pb_md--75 {
    padding-bottom: 75px !important; }
  .pl_md--75 {
    padding-left: 75px !important; }
  .pr_md--75 {
    padding-right: 75px !important; }
  .mt_md--75 {
    margin-top: 75px !important; }
  .mb_md--75 {
    margin-bottom: 75px !important; }
  .ptb_md--80 {
    padding: 80px 0 !important; }
  .plr_md--80 {
    padding: 0 80px !important; }
  .pt_md--80 {
    padding-top: 80px !important; }
  .pb_md--80 {
    padding-bottom: 80px !important; }
  .pl_md--80 {
    padding-left: 80px !important; }
  .pr_md--80 {
    padding-right: 80px !important; }
  .mt_md--80 {
    margin-top: 80px !important; }
  .mb_md--80 {
    margin-bottom: 80px !important; }
  .ptb_md--85 {
    padding: 85px 0 !important; }
  .plr_md--85 {
    padding: 0 85px !important; }
  .pt_md--85 {
    padding-top: 85px !important; }
  .pb_md--85 {
    padding-bottom: 85px !important; }
  .pl_md--85 {
    padding-left: 85px !important; }
  .pr_md--85 {
    padding-right: 85px !important; }
  .mt_md--85 {
    margin-top: 85px !important; }
  .mb_md--85 {
    margin-bottom: 85px !important; }
  .ptb_md--90 {
    padding: 90px 0 !important; }
  .plr_md--90 {
    padding: 0 90px !important; }
  .pt_md--90 {
    padding-top: 90px !important; }
  .pb_md--90 {
    padding-bottom: 90px !important; }
  .pl_md--90 {
    padding-left: 90px !important; }
  .pr_md--90 {
    padding-right: 90px !important; }
  .mt_md--90 {
    margin-top: 90px !important; }
  .mb_md--90 {
    margin-bottom: 90px !important; }
  .ptb_md--95 {
    padding: 95px 0 !important; }
  .plr_md--95 {
    padding: 0 95px !important; }
  .pt_md--95 {
    padding-top: 95px !important; }
  .pb_md--95 {
    padding-bottom: 95px !important; }
  .pl_md--95 {
    padding-left: 95px !important; }
  .pr_md--95 {
    padding-right: 95px !important; }
  .mt_md--95 {
    margin-top: 95px !important; }
  .mb_md--95 {
    margin-bottom: 95px !important; }
  .ptb_md--100 {
    padding: 100px 0 !important; }
  .plr_md--100 {
    padding: 0 100px !important; }
  .pt_md--100 {
    padding-top: 100px !important; }
  .pb_md--100 {
    padding-bottom: 100px !important; }
  .pl_md--100 {
    padding-left: 100px !important; }
  .pr_md--100 {
    padding-right: 100px !important; }
  .mt_md--100 {
    margin-top: 100px !important; }
  .mb_md--100 {
    margin-bottom: 100px !important; } }

@media only screen and (max-width: 767px) {
  .mt_dec--30 {
    margin-top: -30px; }
  .ptb_sm--250 {
    padding: 250px 0; }
  .ptb_sm--0 {
    padding: 0; }
  .pl_sm--0 {
    padding-left: 0; }
  .pr_sm--0 {
    padding-right: 0; }
  .pt_sm--0 {
    padding-top: 0; }
  .pb_sm--0 {
    padding-bottom: 0; }
  .mr_sm--0 {
    margin-right: 0; }
  .ml_sm--0 {
    margin-left: 0; }
  .mt_sm--0 {
    margin-top: 0; }
  .mb_sm--0 {
    margin-bottom: 0; }
  .ptb_sm--5 {
    padding: 5px 0 !important; }
  .plr_sm--5 {
    padding: 0 5px !important; }
  .pt_sm--5 {
    padding-top: 5px !important; }
  .pb_sm--5 {
    padding-bottom: 5px !important; }
  .pl_sm--5 {
    padding-left: 5px !important; }
  .pr_sm--5 {
    padding-right: 5px !important; }
  .mt_sm--5 {
    margin-top: 5px !important; }
  .mb_sm--5 {
    margin-bottom: 5px !important; }
  .ptb_sm--10 {
    padding: 10px 0 !important; }
  .plr_sm--10 {
    padding: 0 10px !important; }
  .pt_sm--10 {
    padding-top: 10px !important; }
  .pb_sm--10 {
    padding-bottom: 10px !important; }
  .pl_sm--10 {
    padding-left: 10px !important; }
  .pr_sm--10 {
    padding-right: 10px !important; }
  .mt_sm--10 {
    margin-top: 10px !important; }
  .mb_sm--10 {
    margin-bottom: 10px !important; }
  .ptb_sm--15 {
    padding: 15px 0 !important; }
  .plr_sm--15 {
    padding: 0 15px !important; }
  .pt_sm--15 {
    padding-top: 15px !important; }
  .pb_sm--15 {
    padding-bottom: 15px !important; }
  .pl_sm--15 {
    padding-left: 15px !important; }
  .pr_sm--15 {
    padding-right: 15px !important; }
  .mt_sm--15 {
    margin-top: 15px !important; }
  .mb_sm--15 {
    margin-bottom: 15px !important; }
  .ptb_sm--20 {
    padding: 20px 0 !important; }
  .plr_sm--20 {
    padding: 0 20px !important; }
  .pt_sm--20 {
    padding-top: 20px !important; }
  .pb_sm--20 {
    padding-bottom: 20px !important; }
  .pl_sm--20 {
    padding-left: 20px !important; }
  .pr_sm--20 {
    padding-right: 20px !important; }
  .mt_sm--20 {
    margin-top: 20px !important; }
  .mb_sm--20 {
    margin-bottom: 20px !important; }
  .ptb_sm--25 {
    padding: 25px 0 !important; }
  .plr_sm--25 {
    padding: 0 25px !important; }
  .pt_sm--25 {
    padding-top: 25px !important; }
  .pb_sm--25 {
    padding-bottom: 25px !important; }
  .pl_sm--25 {
    padding-left: 25px !important; }
  .pr_sm--25 {
    padding-right: 25px !important; }
  .mt_sm--25 {
    margin-top: 25px !important; }
  .mb_sm--25 {
    margin-bottom: 25px !important; }
  .ptb_sm--30 {
    padding: 30px 0 !important; }
  .plr_sm--30 {
    padding: 0 30px !important; }
  .pt_sm--30 {
    padding-top: 30px !important; }
  .pb_sm--30 {
    padding-bottom: 30px !important; }
  .pl_sm--30 {
    padding-left: 30px !important; }
  .pr_sm--30 {
    padding-right: 30px !important; }
  .mt_sm--30 {
    margin-top: 30px !important; }
  .mb_sm--30 {
    margin-bottom: 30px !important; }
  .ptb_sm--35 {
    padding: 35px 0 !important; }
  .plr_sm--35 {
    padding: 0 35px !important; }
  .pt_sm--35 {
    padding-top: 35px !important; }
  .pb_sm--35 {
    padding-bottom: 35px !important; }
  .pl_sm--35 {
    padding-left: 35px !important; }
  .pr_sm--35 {
    padding-right: 35px !important; }
  .mt_sm--35 {
    margin-top: 35px !important; }
  .mb_sm--35 {
    margin-bottom: 35px !important; }
  .ptb_sm--40 {
    padding: 40px 0 !important; }
  .plr_sm--40 {
    padding: 0 40px !important; }
  .pt_sm--40 {
    padding-top: 40px !important; }
  .pb_sm--40 {
    padding-bottom: 40px !important; }
  .pl_sm--40 {
    padding-left: 40px !important; }
  .pr_sm--40 {
    padding-right: 40px !important; }
  .mt_sm--40 {
    margin-top: 40px !important; }
  .mb_sm--40 {
    margin-bottom: 40px !important; }
  .ptb_sm--45 {
    padding: 45px 0 !important; }
  .plr_sm--45 {
    padding: 0 45px !important; }
  .pt_sm--45 {
    padding-top: 45px !important; }
  .pb_sm--45 {
    padding-bottom: 45px !important; }
  .pl_sm--45 {
    padding-left: 45px !important; }
  .pr_sm--45 {
    padding-right: 45px !important; }
  .mt_sm--45 {
    margin-top: 45px !important; }
  .mb_sm--45 {
    margin-bottom: 45px !important; }
  .ptb_sm--50 {
    padding: 50px 0 !important; }
  .plr_sm--50 {
    padding: 0 50px !important; }
  .pt_sm--50 {
    padding-top: 50px !important; }
  .pb_sm--50 {
    padding-bottom: 50px !important; }
  .pl_sm--50 {
    padding-left: 50px !important; }
  .pr_sm--50 {
    padding-right: 50px !important; }
  .mt_sm--50 {
    margin-top: 50px !important; }
  .mb_sm--50 {
    margin-bottom: 50px !important; }
  .ptb_sm--55 {
    padding: 55px 0 !important; }
  .plr_sm--55 {
    padding: 0 55px !important; }
  .pt_sm--55 {
    padding-top: 55px !important; }
  .pb_sm--55 {
    padding-bottom: 55px !important; }
  .pl_sm--55 {
    padding-left: 55px !important; }
  .pr_sm--55 {
    padding-right: 55px !important; }
  .mt_sm--55 {
    margin-top: 55px !important; }
  .mb_sm--55 {
    margin-bottom: 55px !important; }
  .ptb_sm--60 {
    padding: 60px 0 !important; }
  .plr_sm--60 {
    padding: 0 60px !important; }
  .pt_sm--60 {
    padding-top: 60px !important; }
  .pb_sm--60 {
    padding-bottom: 60px !important; }
  .pl_sm--60 {
    padding-left: 60px !important; }
  .pr_sm--60 {
    padding-right: 60px !important; }
  .mt_sm--60 {
    margin-top: 60px !important; }
  .mb_sm--60 {
    margin-bottom: 60px !important; }
  .ptb_sm--65 {
    padding: 65px 0 !important; }
  .plr_sm--65 {
    padding: 0 65px !important; }
  .pt_sm--65 {
    padding-top: 65px !important; }
  .pb_sm--65 {
    padding-bottom: 65px !important; }
  .pl_sm--65 {
    padding-left: 65px !important; }
  .pr_sm--65 {
    padding-right: 65px !important; }
  .mt_sm--65 {
    margin-top: 65px !important; }
  .mb_sm--65 {
    margin-bottom: 65px !important; }
  .ptb_sm--70 {
    padding: 70px 0 !important; }
  .plr_sm--70 {
    padding: 0 70px !important; }
  .pt_sm--70 {
    padding-top: 70px !important; }
  .pb_sm--70 {
    padding-bottom: 70px !important; }
  .pl_sm--70 {
    padding-left: 70px !important; }
  .pr_sm--70 {
    padding-right: 70px !important; }
  .mt_sm--70 {
    margin-top: 70px !important; }
  .mb_sm--70 {
    margin-bottom: 70px !important; }
  .ptb_sm--75 {
    padding: 75px 0 !important; }
  .plr_sm--75 {
    padding: 0 75px !important; }
  .pt_sm--75 {
    padding-top: 75px !important; }
  .pb_sm--75 {
    padding-bottom: 75px !important; }
  .pl_sm--75 {
    padding-left: 75px !important; }
  .pr_sm--75 {
    padding-right: 75px !important; }
  .mt_sm--75 {
    margin-top: 75px !important; }
  .mb_sm--75 {
    margin-bottom: 75px !important; }
  .ptb_sm--80 {
    padding: 80px 0 !important; }
  .plr_sm--80 {
    padding: 0 80px !important; }
  .pt_sm--80 {
    padding-top: 80px !important; }
  .pb_sm--80 {
    padding-bottom: 80px !important; }
  .pl_sm--80 {
    padding-left: 80px !important; }
  .pr_sm--80 {
    padding-right: 80px !important; }
  .mt_sm--80 {
    margin-top: 80px !important; }
  .mb_sm--80 {
    margin-bottom: 80px !important; }
  .ptb_sm--85 {
    padding: 85px 0 !important; }
  .plr_sm--85 {
    padding: 0 85px !important; }
  .pt_sm--85 {
    padding-top: 85px !important; }
  .pb_sm--85 {
    padding-bottom: 85px !important; }
  .pl_sm--85 {
    padding-left: 85px !important; }
  .pr_sm--85 {
    padding-right: 85px !important; }
  .mt_sm--85 {
    margin-top: 85px !important; }
  .mb_sm--85 {
    margin-bottom: 85px !important; }
  .ptb_sm--90 {
    padding: 90px 0 !important; }
  .plr_sm--90 {
    padding: 0 90px !important; }
  .pt_sm--90 {
    padding-top: 90px !important; }
  .pb_sm--90 {
    padding-bottom: 90px !important; }
  .pl_sm--90 {
    padding-left: 90px !important; }
  .pr_sm--90 {
    padding-right: 90px !important; }
  .mt_sm--90 {
    margin-top: 90px !important; }
  .mb_sm--90 {
    margin-bottom: 90px !important; }
  .ptb_sm--95 {
    padding: 95px 0 !important; }
  .plr_sm--95 {
    padding: 0 95px !important; }
  .pt_sm--95 {
    padding-top: 95px !important; }
  .pb_sm--95 {
    padding-bottom: 95px !important; }
  .pl_sm--95 {
    padding-left: 95px !important; }
  .pr_sm--95 {
    padding-right: 95px !important; }
  .mt_sm--95 {
    margin-top: 95px !important; }
  .mb_sm--95 {
    margin-bottom: 95px !important; }
  .ptb_sm--100 {
    padding: 100px 0 !important; }
  .plr_sm--100 {
    padding: 0 100px !important; }
  .pt_sm--100 {
    padding-top: 100px !important; }
  .pb_sm--100 {
    padding-bottom: 100px !important; }
  .pl_sm--100 {
    padding-left: 100px !important; }
  .pr_sm--100 {
    padding-right: 100px !important; }
  .mt_sm--100 {
    margin-top: 100px !important; }
  .mb_sm--100 {
    margin-bottom: 100px !important; }
  .pl_sm--0 {
    padding-left: 0 !important; }
  .pr_sm--0 {
    padding-right: 0 !important; }
  .pt_sm--0 {
    padding-top: 0 !important; }
  .pb_sm--0 {
    padding-bottom: 0 !important; }
  .mr_sm--0 {
    margin-right: 0 !important; }
  .ml_sm--0 {
    margin-left: 0 !important; }
  .mt_sm--0 {
    margin-top: 0 !important; }
  .mb_sm--0 {
    margin-bottom: 0 !important; } }

@media only screen and (max-width: 575px) {
  .ptb_mobile--5 {
    padding: 5px 0; }
  .plr_mobile--5 {
    padding: 0 5px; }
  .pt_mobile--5 {
    padding-top: 5px; }
  .pb_mobile--5 {
    padding-bottom: 5px; }
  .pl_mobile--5 {
    padding-left: 5px; }
  .pr_mobile--5 {
    padding-right: 5px; }
  .mt_mobile--5 {
    margin-top: 5px; }
  .mb_mobile--5 {
    margin-bottom: 5px; }
  .ptb_mobile--10 {
    padding: 10px 0; }
  .plr_mobile--10 {
    padding: 0 10px; }
  .pt_mobile--10 {
    padding-top: 10px; }
  .pb_mobile--10 {
    padding-bottom: 10px; }
  .pl_mobile--10 {
    padding-left: 10px; }
  .pr_mobile--10 {
    padding-right: 10px; }
  .mt_mobile--10 {
    margin-top: 10px; }
  .mb_mobile--10 {
    margin-bottom: 10px; }
  .ptb_mobile--15 {
    padding: 15px 0; }
  .plr_mobile--15 {
    padding: 0 15px; }
  .pt_mobile--15 {
    padding-top: 15px; }
  .pb_mobile--15 {
    padding-bottom: 15px; }
  .pl_mobile--15 {
    padding-left: 15px; }
  .pr_mobile--15 {
    padding-right: 15px; }
  .mt_mobile--15 {
    margin-top: 15px; }
  .mb_mobile--15 {
    margin-bottom: 15px; }
  .ptb_mobile--20 {
    padding: 20px 0; }
  .plr_mobile--20 {
    padding: 0 20px; }
  .pt_mobile--20 {
    padding-top: 20px; }
  .pb_mobile--20 {
    padding-bottom: 20px; }
  .pl_mobile--20 {
    padding-left: 20px; }
  .pr_mobile--20 {
    padding-right: 20px; }
  .mt_mobile--20 {
    margin-top: 20px; }
  .mb_mobile--20 {
    margin-bottom: 20px; }
  .ptb_mobile--25 {
    padding: 25px 0; }
  .plr_mobile--25 {
    padding: 0 25px; }
  .pt_mobile--25 {
    padding-top: 25px; }
  .pb_mobile--25 {
    padding-bottom: 25px; }
  .pl_mobile--25 {
    padding-left: 25px; }
  .pr_mobile--25 {
    padding-right: 25px; }
  .mt_mobile--25 {
    margin-top: 25px; }
  .mb_mobile--25 {
    margin-bottom: 25px; }
  .ptb_mobile--30 {
    padding: 30px 0; }
  .plr_mobile--30 {
    padding: 0 30px; }
  .pt_mobile--30 {
    padding-top: 30px; }
  .pb_mobile--30 {
    padding-bottom: 30px; }
  .pl_mobile--30 {
    padding-left: 30px; }
  .pr_mobile--30 {
    padding-right: 30px; }
  .mt_mobile--30 {
    margin-top: 30px; }
  .mb_mobile--30 {
    margin-bottom: 30px; }
  .ptb_mobile--35 {
    padding: 35px 0; }
  .plr_mobile--35 {
    padding: 0 35px; }
  .pt_mobile--35 {
    padding-top: 35px; }
  .pb_mobile--35 {
    padding-bottom: 35px; }
  .pl_mobile--35 {
    padding-left: 35px; }
  .pr_mobile--35 {
    padding-right: 35px; }
  .mt_mobile--35 {
    margin-top: 35px; }
  .mb_mobile--35 {
    margin-bottom: 35px; }
  .ptb_mobile--40 {
    padding: 40px 0; }
  .plr_mobile--40 {
    padding: 0 40px; }
  .pt_mobile--40 {
    padding-top: 40px; }
  .pb_mobile--40 {
    padding-bottom: 40px; }
  .pl_mobile--40 {
    padding-left: 40px; }
  .pr_mobile--40 {
    padding-right: 40px; }
  .mt_mobile--40 {
    margin-top: 40px; }
  .mb_mobile--40 {
    margin-bottom: 40px; }
  .ptb_mobile--45 {
    padding: 45px 0; }
  .plr_mobile--45 {
    padding: 0 45px; }
  .pt_mobile--45 {
    padding-top: 45px; }
  .pb_mobile--45 {
    padding-bottom: 45px; }
  .pl_mobile--45 {
    padding-left: 45px; }
  .pr_mobile--45 {
    padding-right: 45px; }
  .mt_mobile--45 {
    margin-top: 45px; }
  .mb_mobile--45 {
    margin-bottom: 45px; }
  .ptb_mobile--50 {
    padding: 50px 0; }
  .plr_mobile--50 {
    padding: 0 50px; }
  .pt_mobile--50 {
    padding-top: 50px; }
  .pb_mobile--50 {
    padding-bottom: 50px; }
  .pl_mobile--50 {
    padding-left: 50px; }
  .pr_mobile--50 {
    padding-right: 50px; }
  .mt_mobile--50 {
    margin-top: 50px; }
  .mb_mobile--50 {
    margin-bottom: 50px; }
  .ptb_mobile--55 {
    padding: 55px 0; }
  .plr_mobile--55 {
    padding: 0 55px; }
  .pt_mobile--55 {
    padding-top: 55px; }
  .pb_mobile--55 {
    padding-bottom: 55px; }
  .pl_mobile--55 {
    padding-left: 55px; }
  .pr_mobile--55 {
    padding-right: 55px; }
  .mt_mobile--55 {
    margin-top: 55px; }
  .mb_mobile--55 {
    margin-bottom: 55px; }
  .ptb_mobile--60 {
    padding: 60px 0; }
  .plr_mobile--60 {
    padding: 0 60px; }
  .pt_mobile--60 {
    padding-top: 60px; }
  .pb_mobile--60 {
    padding-bottom: 60px; }
  .pl_mobile--60 {
    padding-left: 60px; }
  .pr_mobile--60 {
    padding-right: 60px; }
  .mt_mobile--60 {
    margin-top: 60px; }
  .mb_mobile--60 {
    margin-bottom: 60px; }
  .ptb_mobile--65 {
    padding: 65px 0; }
  .plr_mobile--65 {
    padding: 0 65px; }
  .pt_mobile--65 {
    padding-top: 65px; }
  .pb_mobile--65 {
    padding-bottom: 65px; }
  .pl_mobile--65 {
    padding-left: 65px; }
  .pr_mobile--65 {
    padding-right: 65px; }
  .mt_mobile--65 {
    margin-top: 65px; }
  .mb_mobile--65 {
    margin-bottom: 65px; }
  .ptb_mobile--70 {
    padding: 70px 0; }
  .plr_mobile--70 {
    padding: 0 70px; }
  .pt_mobile--70 {
    padding-top: 70px; }
  .pb_mobile--70 {
    padding-bottom: 70px; }
  .pl_mobile--70 {
    padding-left: 70px; }
  .pr_mobile--70 {
    padding-right: 70px; }
  .mt_mobile--70 {
    margin-top: 70px; }
  .mb_mobile--70 {
    margin-bottom: 70px; }
  .ptb_mobile--75 {
    padding: 75px 0; }
  .plr_mobile--75 {
    padding: 0 75px; }
  .pt_mobile--75 {
    padding-top: 75px; }
  .pb_mobile--75 {
    padding-bottom: 75px; }
  .pl_mobile--75 {
    padding-left: 75px; }
  .pr_mobile--75 {
    padding-right: 75px; }
  .mt_mobile--75 {
    margin-top: 75px; }
  .mb_mobile--75 {
    margin-bottom: 75px; }
  .ptb_mobile--80 {
    padding: 80px 0; }
  .plr_mobile--80 {
    padding: 0 80px; }
  .pt_mobile--80 {
    padding-top: 80px; }
  .pb_mobile--80 {
    padding-bottom: 80px; }
  .pl_mobile--80 {
    padding-left: 80px; }
  .pr_mobile--80 {
    padding-right: 80px; }
  .mt_mobile--80 {
    margin-top: 80px; }
  .mb_mobile--80 {
    margin-bottom: 80px; }
  .ptb_mobile--85 {
    padding: 85px 0; }
  .plr_mobile--85 {
    padding: 0 85px; }
  .pt_mobile--85 {
    padding-top: 85px; }
  .pb_mobile--85 {
    padding-bottom: 85px; }
  .pl_mobile--85 {
    padding-left: 85px; }
  .pr_mobile--85 {
    padding-right: 85px; }
  .mt_mobile--85 {
    margin-top: 85px; }
  .mb_mobile--85 {
    margin-bottom: 85px; }
  .ptb_mobile--90 {
    padding: 90px 0; }
  .plr_mobile--90 {
    padding: 0 90px; }
  .pt_mobile--90 {
    padding-top: 90px; }
  .pb_mobile--90 {
    padding-bottom: 90px; }
  .pl_mobile--90 {
    padding-left: 90px; }
  .pr_mobile--90 {
    padding-right: 90px; }
  .mt_mobile--90 {
    margin-top: 90px; }
  .mb_mobile--90 {
    margin-bottom: 90px; }
  .ptb_mobile--95 {
    padding: 95px 0; }
  .plr_mobile--95 {
    padding: 0 95px; }
  .pt_mobile--95 {
    padding-top: 95px; }
  .pb_mobile--95 {
    padding-bottom: 95px; }
  .pl_mobile--95 {
    padding-left: 95px; }
  .pr_mobile--95 {
    padding-right: 95px; }
  .mt_mobile--95 {
    margin-top: 95px; }
  .mb_mobile--95 {
    margin-bottom: 95px; }
  .ptb_mobile--100 {
    padding: 100px 0; }
  .plr_mobile--100 {
    padding: 0 100px; }
  .pt_mobile--100 {
    padding-top: 100px; }
  .pb_mobile--100 {
    padding-bottom: 100px; }
  .pl_mobile--100 {
    padding-left: 100px; }
  .pr_mobile--100 {
    padding-right: 100px; }
  .mt_mobile--100 {
    margin-top: 100px; }
  .mb_mobile--100 {
    margin-bottom: 100px; } }

.slick-gutter-5 {
  margin-left: -5px;
  margin-right: -5px; }
  .slick-gutter-5 .slick-slide {
    padding-left: 5px;
    padding-right: 5px; }

.slick-gutter-10 {
  margin-left: -10px;
  margin-right: -10px; }
  .slick-gutter-10 .slick-slide {
    padding-left: 10px;
    padding-right: 10px; }

.slick-gutter-15 {
  margin-left: -15px;
  margin-right: -15px; }
  .slick-gutter-15 .slick-slide {
    padding-left: 15px;
    padding-right: 15px; }

.slick-gutter-20 {
  margin-left: -20px;
  margin-right: -20px; }
  .slick-gutter-20 .slick-slide {
    padding-left: 20px;
    padding-right: 20px; }

.slick-gutter-25 {
  margin-left: -25px;
  margin-right: -25px; }
  .slick-gutter-25 .slick-slide {
    padding-left: 25px;
    padding-right: 25px; }

.slick-gutter-30 {
  margin-left: -30px;
  margin-right: -30px; }
  .slick-gutter-30 .slick-slide {
    padding-left: 30px;
    padding-right: 30px; }

.slick-gutter-35 {
  margin-left: -35px;
  margin-right: -35px; }
  .slick-gutter-35 .slick-slide {
    padding-left: 35px;
    padding-right: 35px; }

.slick-gutter-40 {
  margin-left: -40px;
  margin-right: -40px; }
  .slick-gutter-40 .slick-slide {
    padding-left: 40px;
    padding-right: 40px; }

.slick-gutter-45 {
  margin-left: -45px;
  margin-right: -45px; }
  .slick-gutter-45 .slick-slide {
    padding-left: 45px;
    padding-right: 45px; }

.slick-gutter-50 {
  margin-left: -50px;
  margin-right: -50px; }
  .slick-gutter-50 .slick-slide {
    padding-left: 50px;
    padding-right: 50px; }

.slick-gutter-55 {
  margin-left: -55px;
  margin-right: -55px; }
  .slick-gutter-55 .slick-slide {
    padding-left: 55px;
    padding-right: 55px; }

.slick-gutter-60 {
  margin-left: -60px;
  margin-right: -60px; }
  .slick-gutter-60 .slick-slide {
    padding-left: 60px;
    padding-right: 60px; }

.slick-gutter-65 {
  margin-left: -65px;
  margin-right: -65px; }
  .slick-gutter-65 .slick-slide {
    padding-left: 65px;
    padding-right: 65px; }

.slick-gutter-70 {
  margin-left: -70px;
  margin-right: -70px; }
  .slick-gutter-70 .slick-slide {
    padding-left: 70px;
    padding-right: 70px; }

.slick-gutter-75 {
  margin-left: -75px;
  margin-right: -75px; }
  .slick-gutter-75 .slick-slide {
    padding-left: 75px;
    padding-right: 75px; }

.slick-gutter-80 {
  margin-left: -80px;
  margin-right: -80px; }
  .slick-gutter-80 .slick-slide {
    padding-left: 80px;
    padding-right: 80px; }

.slick-gutter-85 {
  margin-left: -85px;
  margin-right: -85px; }
  .slick-gutter-85 .slick-slide {
    padding-left: 85px;
    padding-right: 85px; }

.slick-gutter-90 {
  margin-left: -90px;
  margin-right: -90px; }
  .slick-gutter-90 .slick-slide {
    padding-left: 90px;
    padding-right: 90px; }

.slick-gutter-95 {
  margin-left: -95px;
  margin-right: -95px; }
  .slick-gutter-95 .slick-slide {
    padding-left: 95px;
    padding-right: 95px; }

.slick-gutter-100 {
  margin-left: -100px;
  margin-right: -100px; }
  .slick-gutter-100 .slick-slide {
    padding-left: 100px;
    padding-right: 100px; }

/**************************************
    Elememts Styles
***************************************/
/*=======================
    Acoordion Style 
========================*/
.accodion-style--1 .accordion__button {
  position: relative;
  font-size: 18px;
  line-height: 1.23;
  background: #fff;
  padding: 19px 30px;
  user-select: none;
  display: block;
  color: #6d70a6;
  cursor: pointer;
  outline: none;
  position: relative;
  z-index: 2;
  padding-right: 46px; }
  .accodion-style--1 .accordion__button::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    z-index: -1;
    opacity: 0; }
  .accodion-style--1 .accordion__button::before {
    display: inline-block;
    content: '\f055';
    height: 10px;
    width: 10px;
    margin-right: 0;
    border-bottom: 0px;
    border-right: 0px;
    transform: rotate(0);
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: 28px; }
  .accodion-style--1 .accordion__button:hover {
    background: #fff; }
  .accodion-style--1 .accordion__button[aria-expanded="true"] {
    color: #fff; }
    .accodion-style--1 .accordion__button[aria-expanded="true"]::before {
      content: '\f056';
      transform: rotate(0); }
    .accodion-style--1 .accordion__button[aria-expanded="true"]::after {
      opacity: .7;
      color: #fff;
      background-color: #9C7AF2;
      background-image: linear-gradient(-129deg, #9C7AF2 0, #5E61E7 100%); }

.accodion-style--1 .accordion__panel {
  padding: 23px 30px 29px;
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12); }
  .accodion-style--1 .accordion__panel p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 1.74; }

.accodion-style--1 .accordion__item + .accordion__item {
  border-top: 0 none; }

.accodion-style--1 .accordion__item {
  margin-bottom: 20px;
  box-shadow: 0 0 40px 10px rgba(89, 69, 230, 0.05); }

/*========================
    Button Area 
==========================*/
a.ar-button,
button.ar-button {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0em;
  border-color: transparent;
  color: #fff;
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-size: 200% auto;
  background-position: left center;
  padding: 0 36px;
  height: 56px;
  line-height: 56px;
  border-width: 0;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-block; }
  a.ar-button svg,
  button.ar-button svg {
    font-size: 20px; }
  a.ar-button:hover,
  button.ar-button:hover {
    border-color: transparent;
    color: #fff;
    background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
    background-position: right center;
    color: #fff;
    transform: translateY(-3px); }
  a.ar-button.style-white,
  button.ar-button.style-white {
    color: #5E61E7;
    background-image: inherit;
    background: #fff; }
  a.ar-button.button-wih-border,
  button.ar-button.button-wih-border {
    background: transparent;
    border-width: 1px;
    line-height: 46px;
    color: #5945e6;
    border-color: #5945e6; }
    a.ar-button.button-wih-border.border--2,
    button.ar-button.button-wih-border.border--2 {
      border-width: 2px; }
    a.ar-button.button-wih-border:hover,
    button.ar-button.button-wih-border:hover {
      border-color: transparent;
      background-color: #5945e6;
      background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
      background-size: 200% auto;
      background-position: left center;
      color: #ffffff; }
  a.ar-button.xs-small,
  button.ar-button.xs-small {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 13px; }
  a.ar-button.sm-size,
  button.ar-button.sm-size {
    padding: 0 30px;
    height: 48px;
    line-height: 48px;
    font-size: 14px; }
  a.ar-button.large-size,
  button.ar-button.large-size {
    padding: 0 40px;
    height: 64px;
    line-height: 64px;
    font-size: 16px; }
  a.ar-button.custom-size,
  button.ar-button.custom-size {
    padding: 0 36px;
    height: 56px;
    line-height: 56px;
    border-width: 0;
    border-radius: 5px;
    min-width: 280px; }
  a.ar-button.full-width,
  button.ar-button.full-width {
    width: 100%; }
    @media only screen and (max-width: 767px) {
      a.ar-button.full-width,
      button.ar-button.full-width {
        width: auto; } }
  a.ar-button.solid-style,
  button.ar-button.solid-style {
    border-color: #5945e6;
    background: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    background-position: left center;
    background-origin: border-box; }
    a.ar-button.solid-style .button-text,
    button.ar-button.solid-style .button-text {
      color: #5E61E7; }
    a.ar-button.solid-style:hover,
    button.ar-button.solid-style:hover {
      background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
      border-color: transparent;
      background-size: 200% auto;
      background-position: left center; }
      a.ar-button.solid-style:hover .button-text,
      button.ar-button.solid-style:hover .button-text {
        color: #fff; }
  a.ar-button.rectangle,
  button.ar-button.rectangle {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
  a.ar-button.buttet,
  button.ar-button.buttet {
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px; }
  a.ar-button.primary,
  button.ar-button.primary {
    border-color: #5945e6;
    background: #5945e6; }
  a.ar-button.secondary,
  button.ar-button.secondary {
    border-color: #38cb89;
    background: #38cb89; }
  a.ar-button.custom,
  button.ar-button.custom {
    background: #222; }
  a.ar-button.white,
  button.ar-button.white {
    -moz-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
    color: #222;
    background: #fff; }
  a.ar-button.gradient,
  button.ar-button.gradient {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FF3F85), color-stop(100%, #FAA720));
    background: -moz-linear-gradient(150deg, #FF3F85 0, #FAA720 100%);
    background: -webkit-linear-gradient(150deg, #FF3F85 0, #FAA720 100%);
    background: -o-linear-gradient(150deg, #FF3F85 0, #FAA720 100%);
    background: -ms-linear-gradient(150deg, #FF3F85 0, #FAA720 100%);
    background: linear-gradient(150deg, #FF3F85 0, #FAA720 100%); }
  a.ar-button.with-icon i,
  button.ar-button.with-icon i {
    margin-left: 7px;
    font-size: 15px; }
  a.ar-button.with-left i,
  button.ar-button.with-left i {
    margin-right: 7px;
    font-size: 15px; }

a.download-btn,
button.download-btn {
  border-color: #38cb89;
  background: #38cb89;
  font-size: 15px;
  font-weight: 600;
  padding: 0 36px;
  height: 56px;
  line-height: 56px;
  border-radius: 5px;
  display: inline-block;
  color: #fff; }
  a.download-btn .icon,
  button.download-btn .icon {
    font-size: 15px;
    display: inline-block;
    margin-right: 7px; }
  a.download-btn span,
  button.download-btn span {
    font-size: 15px; }
  a.download-btn:hover,
  button.download-btn:hover {
    transform: translateY(-3px); }

.pricing-table button.ar-button {
  border-width: 1px;
  line-height: 46px;
  padding: 0 30px;
  height: 48px; }

button.video-button {
  border: 0 none;
  outline: none; }
  button.video-button svg {
    color: #38cb89;
    font-size: 18px; }
  button.video-button span {
    margin-top: 0;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px; }
  button.video-button:hover {
    color: #5E61E7; }

/*======================
    Button Group 
========================*/
.button-group {
  margin: -10px; }
  .button-group .button-image {
    border: 0 none;
    padding: 0;
    margin: 10px; }
    .button-group .button-image img {
      border-radius: 5px; }
    .button-group .button-image:hover {
      transform: translateY(-3px); }

/*=======================
    Video Popup 
=========================*/
.video-popup-button .video-popup {
  min-width: 260px;
  border-color: transparent;
  color: #fff;
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-size: 200% auto;
  background-position: left center;
  padding: 0 36px;
  height: 56px;
  line-height: 56px;
  border-width: 0;
  border-radius: 5px; }
  .video-popup-button .video-popup svg {
    font-size: 15px;
    margin-right: 10px; }
  .video-popup-button .video-popup:hover {
    background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
    background-position: right center;
    color: #fff; }

.video-popup-button.button-transparent .video-popup {
  min-width: 260px;
  border-color: transparent;
  color: #7e7e7e;
  background-image: inherit;
  padding: 0 6px;
  height: inherit;
  line-height: inherit;
  border-radius: 0;
  box-shadow: inherit; }
  .video-popup-button.button-transparent .video-popup svg {
    color: #FF4D24; }
  .video-popup-button.button-transparent .video-popup span {
    margin-top: 0;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px; }
  .video-popup-button.button-transparent .video-popup:hover {
    background-image: inherit;
    background-position: right center;
    color: #5E61E7;
    transform: inherit; }

/*====================
    Button Group 
======================*/
.button-group {
  margin: -10px; }
  .button-group .ar-button {
    margin: 10px; }

/*=========================
    Icon Box Area 
==========================*/
.single-icon-box:last-child .icon-box .line {
  display: none; }

.icon-box {
  text-align: center;
  position: relative; }
  .icon-box .line {
    position: absolute;
    top: 60px;
    left: 100%;
    transform: translate(-50%, -5px);
    height: 10px;
    width: 100%;
    line-height: 0; }
    .icon-box .line .dot {
      display: inline-block;
      vertical-align: middle;
      background: #e4e8f6;
      border-radius: 50%;
      margin: 0 8px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .icon-box .line .dot {
          display: none; } }
      @media only screen and (max-width: 767px) {
        .icon-box .line .dot {
          display: none; } }
      .icon-box .line .dot.dot-1 {
        width: 2px;
        height: 2px; }
      .icon-box .line .dot.dot-2 {
        width: 4px;
        height: 4px; }
      .icon-box .line .dot.dot-3 {
        width: 6px;
        height: 6px; }
      .icon-box .line .dot.dot-4 {
        width: 8px;
        height: 8px; }
      .icon-box .line .dot.dot-5 {
        width: 10px;
        height: 10px; }
  .icon-box .content .icon {
    position: relative;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 40px;
    font-size: 34px;
    background: #fff;
    box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
    margin: 0 auto 52px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #38cb89; }
    .icon-box .content .icon i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .icon-box .content h5 {
    font-size: 24px;
    margin-bottom: 14px;
    color: #6d70a6; }
    @media only screen and (max-width: 767px) {
      .icon-box .content h5 {
        font-size: 19px;
        margin-bottom: 16px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .icon-box .content h5 {
        font-size: 21px;
        margin-bottom: 12px; } }
  .icon-box .content p {
    padding: 0 15px; }
  .icon-box:hover .content .icon {
    color: #fff;
    background-color: #38cb89; }

/*=============================
    Icon Box Style Two 
==============================*/
.icon-box--2 {
  padding: 37px 30px;
  border-radius: 5px;
  background: #ffffff;
  margin-top: 30px; }
  @media only screen and (max-width: 767px) {
    .icon-box--2 {
      padding: 20px 20px;
      margin-top: 0; } }
  @media only screen and (max-width: 767px) {
    .icon-box--2 {
      text-align: center; } }
  .icon-box--2 .content {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .icon-box--2 .content {
        display: block; } }
    @media only screen and (max-width: 767px) {
      .icon-box--2 .content {
        text-align: center; } }
    .icon-box--2 .content .icon {
      position: relative;
      flex-shrink: 0;
      margin: 0 27px 0 0;
      border: 3px solid #f5f5f5;
      height: 114px;
      width: 114px;
      line-height: 114px;
      text-align: center;
      border-radius: 50%; }
      @media only screen and (max-width: 767px) {
        .icon-box--2 .content .icon {
          margin-bottom: 20px; } }
      @media only screen and (max-width: 767px) {
        .icon-box--2 .content .icon {
          margin: 0 auto;
          margin-bottom: 20px; } }
      .icon-box--2 .content .icon img {
        max-width: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .icon-box--2 .content .inner {
      flex-grow: 1;
      position: relative;
      z-index: 1; }
      .icon-box--2 .content .inner h5 {
        color: #6d70a6;
        font-size: 18px;
        margin-bottom: 14px; }
      .icon-box--2 .content .inner p {
        text-align: left; }
        @media only screen and (max-width: 767px) {
          .icon-box--2 .content .inner p {
            text-align: center; } }
  .icon-box--2:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 50px 5px rgba(89, 69, 230, 0.1); }

/*=============================
    Box Icon Style Two 
==============================*/
.box-icon-two-wrapper .container {
  max-width: 970px; }

.box-icon--2 {
  padding: 30px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer; }
  .box-icon--2 .content .icon-inner {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 26px;
    font-size: 30px;
    margin-bottom: 19px;
    color: #2bc1db;
    border-color: #2bc1db;
    background-color: #eaf9ff; }
    .box-icon--2 .content .icon-inner.window {
      color: #ffa301;
      border-color: #ffa301;
      background-color: #fff6dc; }
    .box-icon--2 .content .icon-inner.apple {
      color: #524ce8;
      border-color: #524ce8;
      background-color: #f5f4fe; }
    .box-icon--2 .content .icon-inner.java {
      color: #ff595d;
      border-color: #ff595d;
      background-color: #fff5f6; }
    .box-icon--2 .content .icon-inner.android {
      color: #8dc938;
      border-color: #8dc938;
      background-color: #f5faed; }
    .box-icon--2 .content .icon-inner.php {
      color: #3066f0;
      border-color: #3066f0;
      background-color: #eaf0fe; }
  .box-icon--2 .content .inner h5 {
    font-size: 15px;
    margin-bottom: 0;
    color: #696969;
    font-weight: 400; }
  .box-icon--2:hover {
    background: #fff;
    box-shadow: 0 10px 55px 5px rgba(51, 51, 51, 0.09);
    transform: translateY(-5px); }

/*=============================
    Box Icon Style Three
==============================*/
.box-icon--3 {
  padding: 35px 40px 49px;
  box-shadow: 0 4px 20px rgba(51, 51, 51, 0.05);
  background: #fff;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .box-icon--3::before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden; }
  .box-icon--3 .icon {
    position: relative;
    background: #f6f5f9;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin-bottom: 16px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .box-icon--3 .icon img {
      max-width: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .box-icon--3 .content h5.heading {
    color: #6d70a6;
    font-size: 18px;
    margin-bottom: 16px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .box-icon--3 .content p {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .box-icon--3:hover {
    color: #fff;
    transform: translateY(-5px); }
    .box-icon--3:hover .icon {
      background: rgba(246, 245, 249, 0.2); }
    .box-icon--3:hover .content h5.heading {
      color: #ffffff; }
    .box-icon--3:hover .content p {
      color: #ffffff; }
    .box-icon--3:hover::before {
      opacity: .7;
      visibility: visible;
      background-color: #9C7AF2;
      background-image: linear-gradient(-138deg, #9C7AF2 0, #5E61E7 100%); }

/*=============================
    Box Icon Style Four
==============================*/
.box-icon--4 {
  text-align: center;
  border-radius: 5px;
  padding: 36px 35px 41px; }
  @media only screen and (max-width: 767px) {
    .box-icon--4 {
      padding: 15px 10px; } }
  .box-icon--4 .icon {
    margin-bottom: 21px; }
  .box-icon--4 .content h5.heading {
    color: #6d70a6;
    font-size: 18px;
    margin-bottom: 14px;
    font-weight: 400; }
  .box-icon--4:hover {
    transform: translateY(-5px);
    box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
    border-color: #fff; }

/*=============================
    Box Icon Style Five
==============================*/
.box-icon--5 {
  padding: 37px 30px 37px 17px;
  box-shadow: 0 0 40px 5px rgba(51, 51, 51, 0.1);
  background: #fff;
  border-radius: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  height: 100%; }
  @media only screen and (max-width: 575px) {
    .box-icon--5 {
      display: block; } }
  .box-icon--5 .icon {
    position: relative;
    flex-shrink: 0;
    margin: 0 13px 0 0;
    min-width: 185px;
    text-align: center; }
    @media only screen and (max-width: 575px) {
      .box-icon--5 .icon {
        margin-bottom: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .box-icon--5 .icon {
        min-width: auto;
        width: 88px; } }
    @media only screen and (max-width: 767px) {
      .box-icon--5 .icon {
        min-width: auto;
        width: 88px; } }
    .box-icon--5 .icon img {
      height: 127px;
      width: 100%; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .box-icon--5 .icon img {
          height: 86px;
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .box-icon--5 .icon img {
          height: 86px;
          width: 100%; } }
  .box-icon--5 .content {
    flex-grow: 1; }
    .box-icon--5 .content h5.heading {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 12px; }

/*=============================
    Box Icon Style Six
==============================*/
.box-icon--6 {
  border-radius: 5px;
  padding: 48px 35px 51px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .box-icon--6 {
      padding: 10px; } }
  .box-icon--6 .icon {
    margin-bottom: 33px; }
    @media only screen and (max-width: 767px) {
      .box-icon--6 .icon {
        margin-bottom: 12px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .box-icon--6 .icon {
        margin-bottom: 12px; } }
  .box-icon--6 .content span {
    color: rgba(89, 69, 230, 0.5);
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 17px;
    display: inline-block; }
  .box-icon--6 .content h5.heading {
    font-size: 18px;
    margin-bottom: 21px; }
    @media only screen and (max-width: 767px) {
      .box-icon--6 .content h5.heading {
        margin-bottom: 12px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .box-icon--6 .content h5.heading {
        margin-bottom: 12px; } }
  .box-icon--6:hover {
    transform: translateY(-5px);
    box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
    border-color: #fff; }

/*=============================
    Box Icon Style Seven
==============================*/
.box-icon--7 {
  padding: 67px 40px 64px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 16px 40px #ededed;
  text-align: center; }
  .box-icon--7 .svg-inner {
    height: 90px;
    text-align: center;
    width: 70px;
    margin: 0 auto; }
    .box-icon--7 .svg-inner svg {
      width: 200px;
      stroke: #ff8b8b;
      stroke-width: 2;
      margin: 0 auto; }
  .box-icon--7 .content h5.heading {
    line-height: 2;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 17px; }
  .box-icon--7:hover {
    transform: translateY(-5px); }

/*=============================
    Box Icon Style Seven
==============================*/
.box-icon--9 {
  text-align: center;
  padding: 60px 25px;
  background: #fff;
  border-bottom: 3px solid #5945e6;
  position: relative;
  z-index: 1; }
  .box-icon--9 .svg-inner {
    height: 90px;
    text-align: center;
    width: 70px;
    margin: 0 auto; }
    .box-icon--9 .svg-inner svg path {
      width: 200px;
      stroke: #5945e6;
      stroke-width: 2;
      margin: 0 auto;
      transition: 0.8s;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 280;
      stroke-dashoffset: 0; }
  .box-icon--9 .content h5.heading {
    line-height: 1.25;
    font-size: 20px;
    margin-bottom: 30px; }
  .box-icon--9::before {
    color: #fff;
    background-color: #9C7AF2;
    background-image: linear-gradient(-129deg, #9C7AF2 0, #5E61E7 100%);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -3px;
    opacity: 0;
    visibility: hidden;
    z-index: -1; }
  .box-icon--9:hover {
    transform: translateY(-5px); }
    .box-icon--9:hover .svg-inner svg path {
      stroke: #ffffff;
      stroke-dasharray: 99;
      stroke-dashoffset: 0; }
    .box-icon--9:hover .content h5.heading {
      color: #ffffff; }
    .box-icon--9:hover .content p {
      color: #ffffff; }
    .box-icon--9:hover::before {
      opacity: 1;
      visibility: visible; }

@-webkit-keyframes draw {
  0% {
    stroke-dashoffset: 0; }
  30% {
    stroke-dashoffset: 10; }
  50% {
    stroke-dashoffset: 20; }
  70% {
    stroke-dashoffset: 60; }
  100% {
    stroke-dashoffset: 99; } }

.secret-link {
  padding: 41px 30px 45px 42px;
  border: 1px solid rgba(182, 183, 210, 0.5);
  box-shadow: 0 10px 40px 5px rgba(89, 69, 230, 0.05);
  background: #fff;
  border-radius: 5px; }
  @media only screen and (max-width: 767px) {
    .secret-link {
      padding: 20px 20px 20px 20px; } }
  .secret-link .secret-link-box {
    display: flex; }
    .secret-link .secret-link-box .image {
      position: relative;
      flex-shrink: 0;
      margin: 0 20px 0 0;
      min-width: 120px;
      align-self: center; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .secret-link .secret-link-box .image {
          margin: 0 10px 0 0;
          min-width: 93px; } }
      @media only screen and (max-width: 767px) {
        .secret-link .secret-link-box .image {
          margin: 0 10px 0 0;
          min-width: 93px; } }
      @media only screen and (max-width: 767px) {
        .secret-link .secret-link-box .image {
          min-width: auto;
          width: 53px; } }
      @media only screen and (max-width: 767px) {
        .secret-link .secret-link-box .image img {
          width: 100%; } }
    .secret-link .secret-link-box .content {
      flex-grow: 1; }
      .secret-link .secret-link-box .content h6 {
        color: #ababab;
        margin-bottom: 21px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 15px; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .secret-link .secret-link-box .content h6 {
            margin-bottom: 9px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .secret-link .secret-link-box .content h6 {
            margin-bottom: 9px; } }
        @media only screen and (max-width: 767px) {
          .secret-link .secret-link-box .content h6 {
            margin-bottom: 9px; } }
      .secret-link .secret-link-box .content h4 {
        font-size: 34px;
        line-height: 1.42;
        color: #333;
        margin-bottom: 13px; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .secret-link .secret-link-box .content h4 {
            font-size: 28px;
            margin-bottom: 10px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .secret-link .secret-link-box .content h4 {
            font-size: 28px;
            margin-bottom: 10px; } }
        @media only screen and (max-width: 767px) {
          .secret-link .secret-link-box .content h4 {
            font-size: 18px;
            margin-bottom: 10px; } }
  .secret-link:hover {
    transform: translateY(-5px);
    box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
    border-color: #fff; }

/*=============================
    Box Icon Style Seven
==============================*/
.box-icon--8 .content-header {
  display: flex;
  align-items: center;
  margin-bottom: 13px; }
  .box-icon--8 .content-header i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 8px;
    font-size: 30px;
    color: #fff;
    background-image: linear-gradient(-135deg, #f8c59b 0%, #e68c60 50%, #f8c59b 100%);
    background-position: left center;
    background-size: 200% auto;
    flex-shrink: 0;
    margin-right: 19px; }
  .box-icon--8 .content-header h5.heading {
    color: #2e3280;
    font-size: 24px;
    margin-bottom: 0; }

.box-icon--8:hover .content-header i {
  background-position: right center; }

/*============================
    Circle Box 
==============================*/
.circle-box {
  width: 160px;
  height: 160px;
  background-color: #9C7AF2;
  background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  z-index: 2; }
  .circle-box .inner {
    background-image: linear-gradient(218deg, #ffffff 0, #ffffff 50%, #ffffff 100%);
    border-radius: 100%;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    position: absolute; }
    .circle-box .inner .countinner {
      background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
      border-radius: 100%;
      left: 27px;
      right: 27px;
      top: 27px;
      bottom: 27px;
      position: absolute; }
  .circle-box .number-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 34px;
    line-height: 1;
    color: #fff;
    z-index: 2; }
  .circle-box .circle-design {
    box-shadow: 0 2px 17px rgba(89, 69, 230, 0.27);
    position: absolute;
    border-radius: 50%;
    animation: jump infinite 2s;
    animation-direction: alternate;
    background-color: #9C7AF2;
    background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%); }
    .circle-box .circle-design.one {
      width: 43px;
      height: 43px;
      left: -12px;
      bottom: 27px; }
    .circle-box .circle-design.two {
      width: 17px;
      height: 17px;
      right: -8px;
      top: 50%;
      margin-top: -11px;
      animation-delay: 1s; }

@keyframes jump {
  0% {
    transform: translateY(-10px) scale(0.9, 0.9); }
  100% {
    transform: translateY(10px) scale(1, 1); } }

/*===================
Section Title 
======================*/
.section-title {
  margin-top: -10px; }
  .section-title h3.heading {
    font-size: 48px;
    line-height: 1.34;
    font-weight: 400;
    margin-bottom: 4px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .section-title h3.heading {
        font-size: 40px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .section-title h3.heading {
        font-size: 34px; } }
    @media only screen and (max-width: 767px) {
      .section-title h3.heading {
        font-size: 26px; } }
  .section-title p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 1.74;
    color: #7e7e7e; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-title h2 {
      font-size: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title h2 {
      font-size: 34px; } }
  @media only screen and (max-width: 767px) {
    .section-title h2 {
      font-size: 25px; } }

.section-title--3 span {
  color: #ee7455;
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-bottom: 25px; }
  @media only screen and (max-width: 767px) {
    .section-title--3 span {
      margin-bottom: 9px; } }

.section-title--3 .title {
  font-size: 40px;
  max-width: 770px;
  line-height: 1.4;
  font-weight: 400;
  color: #333;
  margin: 0 auto; }
  @media only screen and (max-width: 767px) {
    .section-title--3 .title {
      font-size: 25px; } }

.section-title--4 span {
  color: #38cb89;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.34;
  display: block;
  margin-bottom: 24px; }

.section-title--4 h3.title {
  font-size: 34px;
  line-height: 1.42; }
  @media only screen and (max-width: 767px) {
    .section-title--4 h3.title {
      font-size: 26px;
      line-height: 1.42;
      margin-bottom: 10px !important; } }

.section-title--5 span {
  line-height: 1.74;
  color: #ee7455;
  font-size: 15px;
  margin-bottom: 20px;
  display: block;
  font-weight: 600; }

.section-title--5 h3.title {
  font-size: 34px;
  line-height: 1.42; }

/*========================
    Service Area 
==========================*/
.service {
  padding: 35px 40px 49px;
  box-shadow: 0 4px 20px rgba(51, 51, 51, 0.05);
  background: #fff;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  transition: 0.5s; }
  .service::before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden; }
  .service .icon {
    position: relative;
    background: #f6f5f9;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin-bottom: 16px;
    transition: 0.5s; }
    .service .icon img {
      max-width: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .service .content h5.heading {
    color: #6d70a6;
    font-size: 18px;
    margin-bottom: 16px;
    transition: 0.5s; }
  .service .content p {
    transition: 0.5s; }
  .service:hover {
    color: #fff;
    transform: translateY(-5px); }
    .service:hover .icon {
      background: rgba(246, 245, 249, 0.2); }
    .service:hover .content h5.heading {
      color: #ffffff; }
    .service:hover .content p {
      color: #ffffff; }
    .service:hover::before {
      opacity: .7;
      visibility: visible;
      background-color: #9C7AF2;
      background-image: linear-gradient(-138deg, #9C7AF2 0, #5E61E7 100%); }

/*=======================
Service Style Two 
=========================*/
.service_style--2 {
  text-align: center; }
  .service_style--2 .icon {
    margin-bottom: 20px; }
  .service_style--2 .content h5.heading {
    line-height: 1.67;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 0; }
  .service_style--2:hover .icon {
    transform: translateY(-5px); }
  .service_style--2:hover .content h5.heading {
    color: #5E61E7; }
  .service_style--2::after {
    position: absolute;
    content: '';
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    height: 100px;
    background: #d1d9df;
    width: 1px; }
    @media only screen and (max-width: 767px) {
      .service_style--2::after {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .service_style--2::after {
        display: none; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .service_style--2::after {
        display: none; } }

.single-service:last-child .service_style--2::after {
  display: none; }

/*=======================
Service Style Three 
=========================*/
.service-style--4 {
  text-align: center; }
  .service-style--4 .thumbnail {
    position: relative;
    border-radius: 5px;
    overflow: hidden; }
    .service-style--4 .thumbnail img {
      transition: transform 1s;
      width: 100%; }
    .service-style--4 .thumbnail::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .5;
      background-image: linear-gradient(-180deg, transparent 39%, #1c0f69 100%); }
    .service-style--4 .thumbnail .action-title {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      max-width: 250px;
      margin: 0 auto;
      z-index: 2; }
      .service-style--4 .thumbnail .action-title h4 {
        font-size: 24px;
        line-height: 1.5;
        color: #fff; }
  .service-style--4 .content {
    margin-top: 25px; }
  .service-style--4:hover {
    transform: translateY(-5px); }
    .service-style--4:hover .thumbnail img {
      transform: scale(1.1, 1.1); }

/*========================
    Counterup Area 
==========================*/
.counterup_style--1 {
  text-align: center;
  margin-top: 30px; }
  .counterup_style--1 .title {
    font-size: 24px;
    line-height: 1.34;
    color: #6d70a6;
    padding: 0 40px;
    margin-bottom: 30px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .counterup_style--1 .title {
        font-size: 20px;
        padding: 0;
        margin-bottom: 27px; } }
    @media only screen and (max-width: 767px) {
      .counterup_style--1 .title {
        font-size: 20px;
        padding: 0;
        margin-bottom: 10px; } }
  .counterup_style--1 .counter {
    color: #ee7455;
    font-size: 64px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 19px; }
    @media only screen and (max-width: 767px) {
      .counterup_style--1 .counter {
        font-size: 46px;
        margin-bottom: 5px; } }
  .counterup_style--1 p {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgba(109, 112, 166, 0.5); }

.counterup_style--2 {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .counterup_style--2 {
      display: block; } }
  .counterup_style--2 .icon {
    width: 160px;
    height: 160px;
    background-color: #9C7AF2;
    background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
    border-radius: 100%;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .counterup_style--2 .icon {
        width: 130px;
        height: 130px; } }
    @media only screen and (max-width: 767px) {
      .counterup_style--2 .icon {
        width: 130px;
        height: 130px;
        margin: 0 auto; } }
    .counterup_style--2 .icon .inner {
      background-image: linear-gradient(218deg, #ffffff 0, #ffffff 50%, #ffffff 100%);
      border-radius: 100%;
      left: 3px;
      right: 3px;
      top: 3px;
      bottom: 3px;
      position: absolute; }
      .counterup_style--2 .icon .inner .countinner {
        background-color: #9C7AF2;
        background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
        border-radius: 100%;
        left: 9px;
        right: 9px;
        top: 9px;
        bottom: 9px;
        position: absolute; }
    .counterup_style--2 .icon .counter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 34px;
      line-height: 1;
      color: #fff;
      z-index: 2; }
    .counterup_style--2 .icon .circle-design {
      box-shadow: 0 2px 17px rgba(89, 69, 230, 0.27);
      position: absolute;
      border-radius: 50%;
      animation: jump infinite 2s;
      animation-direction: alternate;
      background-color: #9C7AF2;
      background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%); }
      .counterup_style--2 .icon .circle-design.color--yellow {
        background-color: #f76b1c;
        background-image: linear-gradient(-224deg, #fad961 0, #f76b1c 100%); }
      .counterup_style--2 .icon .circle-design.one {
        width: 43px;
        height: 43px;
        left: -12px;
        bottom: 27px; }
      .counterup_style--2 .icon .circle-design.two {
        width: 17px;
        height: 17px;
        right: -8px;
        top: 50%;
        margin-top: -11px;
        animation-delay: 1s; }
  .counterup_style--2 .content {
    flex-grow: 1;
    margin-left: 35px;
    text-align: left; }
    @media only screen and (max-width: 767px) {
      .counterup_style--2 .content {
        margin-left: 0;
        margin-top: 30px;
        text-align: center; } }
    .counterup_style--2 .content .title {
      margin-bottom: 17px;
      font-size: 18px;
      color: #333; }
    .counterup_style--2 .content p {
      line-height: 2.14; }
      @media only screen and (max-width: 767px) {
        .counterup_style--2 .content p br {
          display: none; } }
  .counterup_style--2.color--yellow .icon {
    background-image: linear-gradient(-224deg, #fad961 0, #f76b1c 100%); }
    .counterup_style--2.color--yellow .icon .inner .countinner {
      background-image: linear-gradient(-224deg, #fad961 0, #f76b1c 100%); }

.counterup_style--3 {
  text-align: center; }
  .counterup_style--3 .counter {
    font-size: 56px;
    font-weight: 400;
    line-height: 1.29; }
    @media only screen and (max-width: 767px) {
      .counterup_style--3 .counter {
        font-size: 30px; } }
  .counterup_style--3 .title {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px; }

.counterup_style--4 {
  text-align: left;
  position: relative;
  padding-left: 15px; }
  .counterup_style--4 .counter {
    color: #38cb89;
    font-size: 48px;
    line-height: 1;
    font-weight: 400; }
    @media only screen and (max-width: 767px) {
      .counterup_style--4 .counter {
        font-size: 30px; } }
  .counterup_style--4 .title {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: inherit; }
  .counterup_style--4::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -14px;
    transform: translate(0, -50%);
    height: 65px;
    width: 1px;
    background: #d8d8d8; }
    @media only screen and (max-width: 767px) {
      .counterup_style--4::before {
        display: none; } }
  .counterup_style--4:first-child::before {
    display: none; }

/*===============================
    Testimonial Area 
=================================*/
.testimonial-active-wrapper {
  margin: 0 -15px; }

.testimonial_style--1 {
  padding: 15px;
  text-align: center; }
  .testimonial_style--1 .inner {
    background: #fff;
    padding: 51px 34px 40px;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    @media only screen and (max-width: 767px) {
      .testimonial_style--1 .inner {
        padding: 20px 15px; } }
  .testimonial_style--1 .thumbnail {
    margin-bottom: 22px; }
    .testimonial_style--1 .thumbnail img {
      border-radius: 50%;
      display: inline-block; }
  .testimonial_style--1 .details {
    color: #6d70a6;
    font-size: 18px;
    line-height: 1.78;
    margin-bottom: 25px; }
  .testimonial_style--1 .title {
    font-size: 18px;
    line-height: 1.23;
    color: #333;
    margin-bottom: 13px; }
  .testimonial_style--1 .subtitle {
    font-size: 14px;
    font-style: italic;
    text-transform: uppercase;
    letter-spacing: 1px; }
    .testimonial_style--1 .subtitle::before {
      content: ' / '; }
  .testimonial_style--1:hover .inner {
    transform: translateY(-5px);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.07); }

/*=======================
All Landing Testimonial 
===========================*/
.testimonial-activation-wrapper .slick-slide img {
  display: inline-block; }

.testimonial-style--2 {
  display: flex !important;
  width: 100%;
  max-width: 830px;
  margin: 0 auto; }
  @media only screen and (max-width: 575px) {
    .testimonial-style--2 {
      display: block !important;
      padding: 0 30px; } }
  .testimonial-style--2 .thumbnail {
    flex-shrink: 0;
    margin-right: 90px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .testimonial-style--2 .thumbnail {
        margin-right: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .testimonial-style--2 .thumbnail {
        margin-right: 20px; } }
    @media only screen and (max-width: 767px) {
      .testimonial-style--2 .thumbnail {
        margin-right: 20px; } }
    .testimonial-style--2 .thumbnail img {
      border-radius: 50%; }
  .testimonial-style--2 .content {
    flex-grow: 1; }
    .testimonial-style--2 .content p {
      font-size: 24px;
      line-height: 1.67;
      color: #fff; }
    .testimonial-style--2 .content h6 {
      font-size: 24px;
      line-height: 1.5;
      color: #fff;
      margin-bottom: 9px;
      padding-top: 45px; }
      @media only screen and (max-width: 767px) {
        .testimonial-style--2 .content h6 {
          padding-top: 8px; } }
    .testimonial-style--2 .content span {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px; }
      .testimonial-style--2 .content span::before {
        content: ' / '; }

/*======================
    Slick Dots 
=======================*/
.slick-default-dots {
  position: relative; }
  .slick-default-dots .slick-dots {
    bottom: -70px; }
    @media only screen and (max-width: 767px) {
      .slick-default-dots .slick-dots {
        bottom: -30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slick-default-dots .slick-dots {
        bottom: -40px; } }
    .slick-default-dots .slick-dots li button {
      box-sizing: border-box;
      margin: 0 5px;
      width: 20px;
      height: 20px;
      border: 0;
      border-radius: 0;
      background: transparent;
      vertical-align: middle;
      opacity: 1;
      position: relative;
      outline: none; }
      .slick-default-dots .slick-dots li button::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        width: 8px;
        height: 8px;
        background: #d8d8d8;
        opacity: .4;
        z-index: 1;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .slick-default-dots .slick-dots li button::before {
        display: none; }
    .slick-default-dots .slick-dots li.slick-active button::after, .slick-default-dots .slick-dots li:hover button::after {
      background-color: #5945e6;
      width: 12px;
      height: 12px;
      opacity: 1; }
  .slick-default-dots.dots-position-right .slick-dots {
    bottom: auto;
    right: 50px;
    top: 50%;
    left: auto;
    text-align: right;
    z-index: 2;
    width: auto; }
    .slick-default-dots.dots-position-right .slick-dots li {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      margin: 5px 0;
      padding: 0;
      cursor: pointer; }
  .slick-default-dots.button--white .slick-dots li button::after {
    background: #ffffff; }
  .slick-default-dots.button--white .slick-dots li.slick-active button::after, .slick-default-dots.button--white .slick-dots li:hover button::after {
    background-color: #ffffff; }

.slick-slide img {
  display: inline-block; }

/*========================
    Slick Custom Arrow 
==========================*/
.slick-custom-arrow {
  position: relative; }

/*=========================
    Brand Area 
===========================*/
.brand {
  position: relative;
  text-align: center;
  display: inline-block; }
  .brand img {
    display: inline-block; }
  .brand .thumb2 {
    transition: 0.4s;
    text-align: center;
    display: inline-block; }
  .brand .thumb img {
    transition: 0.4s; }
  .brand .thumb {
    transition: all .3s linear;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50%) translateX(-50%);
    transition: 0.4s; }
    .brand .thumb img {
      transition: 0.4s; }
  .brand:hover .thumb2 {
    visibility: hidden;
    opacity: 0; }
  .brand:hover .thumb {
    opacity: 1;
    visibility: visible; }

.brand-activation .slick-track {
  display: flex;
  align-items: center; }

.brand-grid-list {
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 100px;
  display: grid; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brand-grid-list {
      justify-content: center; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-grid-list {
      justify-content: center; } }
  @media only screen and (max-width: 767px) {
    .brand-grid-list {
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 30px;
      justify-content: center; } }
  @media only screen and (max-width: 575px) {
    .brand-grid-list {
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 30px;
      justify-content: center; } }
  @media only screen and (max-width: 479px) {
    .brand-grid-list {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 30px; } }
  .brand-grid-list .brand {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }

.partner-style--1 {
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 100px;
  display: grid;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .partner-style--1 {
      grid-row-gap: 30px; } }
  .partner-style--1 .thumb {
    transition: 0.5s; }

/*======================
    Social Share 
=======================*/
ul.social-share {
  padding: 0;
  margin: 0;
  list-style: none;
  margin: -10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 767px) {
    ul.social-share {
      margin: -5px; } }
  ul.social-share li {
    margin: 10px; }
    @media only screen and (max-width: 767px) {
      ul.social-share li {
        margin: 5px; } }
    ul.social-share li a {
      font-size: 18px;
      display: block;
      text-align: center;
      height: 48px;
      width: 48px;
      background: transparent;
      color: #696969;
      border: 1px solid #ededed;
      border-radius: 50%;
      position: relative; }
      ul.social-share li a i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      ul.social-share li a:hover {
        color: #fff;
        border-color: transparent;
        background-color: #5E61E7; }
  ul.social-share.flat-rounded li a {
    font-size: 18px;
    display: block;
    text-align: center;
    height: 48px;
    width: 48px;
    background: #ededed;
    color: #696969;
    border-radius: 50%;
    position: relative; }
    ul.social-share.flat-rounded li a:hover {
      background-color: #5E61E7;
      color: #fff; }
  ul.social-share.text-white li a {
    color: #ffffff; }
    ul.social-share.text-white li a:hover {
      background-color: #ffffff;
      color: #5E61E7; }

ul.social-text-link {
  margin: 0 -14px;
  line-height: 1; }
  ul.social-text-link li {
    display: inline-block; }
    ul.social-text-link li a {
      padding: 10px 14px;
      display: inline-block;
      border-color: rgba(255, 255, 255, 0.2); }
      ul.social-text-link li a span {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        color: #cdbcf8;
        position: relative;
        padding: 5px 0;
        z-index: 1; }
        ul.social-text-link li a span::before {
          content: '';
          height: 2px;
          bottom: 0;
          position: absolute;
          left: 0;
          right: 0;
          z-index: -2;
          background-color: rgba(255, 255, 255, 0.2); }
        ul.social-text-link li a span::after {
          content: '';
          height: 2px;
          width: 0;
          bottom: 0;
          position: absolute;
          left: auto;
          right: 0;
          z-index: -1;
          transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          background-color: #38cb89; }
    ul.social-text-link li:hover a span {
      color: #fff; }
      ul.social-text-link li:hover a span::after {
        width: 100%;
        left: 0;
        right: auto; }

ul.social-icon {
  margin: 0 -8px; }
  ul.social-icon li {
    display: inline-block; }
    ul.social-icon li a {
      display: inline-block;
      padding: 5px 8px; }
      ul.social-icon li a i {
        color: #7e7e7e; }
      ul.social-icon li a:hover i {
        color: #5E61E7; }
  ul.social-icon.large-icon {
    margin: 0 -13px; }
    ul.social-icon.large-icon li a {
      padding: 5px 13px;
      font-size: 20px; }
  ul.social-icon.exlarge-icon {
    margin: 0 -15px; }
    @media only screen and (max-width: 767px) {
      ul.social-icon.exlarge-icon {
        margin: 0 -10px; } }
    ul.social-icon.exlarge-icon li a {
      padding: 5px 15px;
      font-size: 30px; }
      @media only screen and (max-width: 767px) {
        ul.social-icon.exlarge-icon li a {
          padding: 3px 10px;
          font-size: 20px; } }
  ul.social-icon.text-white li a i {
    color: #ffffff; }

ul.icon-title {
  margin: 0 -8px; }
  ul.icon-title li {
    display: inline-block; }
    ul.icon-title li a {
      display: inline-block;
      padding: 5px 8px;
      color: #7e7e7e;
      text-transform: capitalize; }
      ul.icon-title li a i {
        font-size: 16px;
        min-width: 16px;
        margin-right: 10px; }
      ul.icon-title li a:hover {
        color: #5E61E7; }

ul.title-style {
  margin: 0 -8px; }
  ul.title-style li {
    display: inline-block; }
    ul.title-style li a {
      padding: 10px 14px;
      position: relative;
      display: inline-block; }
      @media only screen and (max-width: 767px) {
        ul.title-style li a {
          padding: 5px 9px; } }
      ul.title-style li a span {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        color: #333;
        position: relative;
        padding: 5px 0;
        z-index: 1; }
        ul.title-style li a span::before {
          content: '';
          height: 2px;
          bottom: 0;
          position: absolute;
          left: 0;
          right: 0;
          z-index: -2;
          background: rgba(0, 0, 0, 0.2); }
        ul.title-style li a span::after {
          content: '';
          height: 2px;
          width: 0;
          bottom: 0;
          position: absolute;
          left: auto;
          right: 0;
          z-index: -1;
          transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          background-color: #38cb89; }
      ul.title-style li a:hover span {
        color: #5E61E7; }
        ul.title-style li a:hover span::after {
          width: 100%;
          left: 0;
          right: auto; }
  ul.title-style.customskin-color li a span {
    color: #cdbcf8; }
    ul.title-style.customskin-color li a span::before {
      background-color: rgba(255, 255, 255, 0.2); }
  ul.title-style.customskin-color li a:hover span {
    color: #fff; }

/*===========================
    Wavify Area 
============================*/
/*===========================
    Sass Wavify Area 
============================*/
.section-wrapper-wayvify {
  position: relative;
  z-index: 3;
  background: -webkit-linear-gradient(top, #FFF 0, #F6F5F9 74%, #F6F5F9 100%); }
  .section-wrapper-wayvify .service-wavify {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }
    .section-wrapper-wayvify .service-wavify svg {
      height: 79px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-bottom: -49px;
      z-index: -1; }
  .section-wrapper-wayvify.wayvify-style--2 {
    margin-bottom: 60px; }
    .section-wrapper-wayvify.wayvify-style--2 .service-wavify svg {
      margin-bottom: -78px; }

.wayvify-style--3 {
  position: relative;
  z-index: 3;
  top: 33px; }
  @media only screen and (max-width: 767px) {
    .wayvify-style--3 {
      display: none; } }
  .wayvify-style--3 .service-wavify {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 103%;
    transform: rotate(-180deg); }
    .wayvify-style--3 .service-wavify svg {
      height: 79px;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      margin-top: -79px; }

.brand-pricing-table-area.wayvify-style--3 {
  top: 0; }

/*=========================
    Feature Area 
===========================*/
.business-analitics-area .feature-wrapper {
  width: 600px;
  padding: 0 30px;
  max-width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .business-analitics-area .feature-wrapper {
      margin: 0 auto; } }
  @media only screen and (max-width: 767px) {
    .business-analitics-area .feature-wrapper {
      width: auto;
      max-width: 100%;
      margin: 0;
      padding: 0 15px;
      margin: 0 auto; } }

.business-analitics-area .thumbnail {
  text-align: right; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .business-analitics-area .thumbnail {
      text-align: center; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .business-analitics-area .thumbnail {
      text-align: center; } }
  @media only screen and (max-width: 767px) {
    .business-analitics-area .thumbnail {
      text-align: center; } }

.feature {
  display: flex;
  padding: 41px 22px 45px;
  box-shadow: 0 10px 40px 5px rgba(89, 69, 230, 0.05);
  background: #fff;
  border-radius: 5px;
  align-items: center; }
  @media only screen and (max-width: 575px) {
    .feature {
      display: block; } }
  .feature .icon {
    position: relative;
    flex-shrink: 0;
    margin: -19px 20px 0 0; }
    @media only screen and (max-width: 575px) {
      .feature .icon {
        margin-bottom: 20px; } }
  .feature .content {
    flex-grow: 1; }
    .feature .content h5.heading {
      font-size: 18px;
      color: #333;
      margin-bottom: 14px; }
  .feature:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 50px 5px rgba(89, 69, 230, 0.1); }

.single-feature:nth-child(2) .feature {
  margin-left: 70px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-feature:nth-child(2) .feature {
      margin-left: 0; } }
  @media only screen and (max-width: 767px) {
    .single-feature:nth-child(2) .feature {
      margin-left: 0; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-feature:nth-child(2) .feature {
      margin-left: 0; } }

/*========================
    Pricing Table 
==========================*/
.pricing-table {
  position: relative;
  height: 100%;
  background-color: #fff;
  padding: 34px 20px 40px;
  border: 1px solid #dce6ed;
  border-radius: 5px;
  text-align: center;
  overflow: hidden; }
  .pricing-table .feature-inner-mark {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 0;
    height: 0;
    border-top: 88px solid #ee7455;
    border-bottom: 88px solid transparent;
    border-left: 88px solid transparent; }
    .pricing-table .feature-inner-mark span {
      position: absolute;
      top: -72px;
      right: 6px;
      text-align: center;
      font-size: 11px;
      font-weight: 700;
      line-height: 1.19;
      display: block;
      color: #fff;
      transform: rotate(45deg); }
  .pricing-table .pricing-header {
    margin-bottom: 25px; }
    .pricing-table .pricing-header .image {
      margin-bottom: 17px; }
    .pricing-table .pricing-header h5.title {
      font-size: 24px;
      line-height: 1.5;
      color: #333;
      margin-bottom: 19px; }
    .pricing-table .pricing-header .pricing-wrap .price-wrap-inner {
      display: flex;
      align-items: flex-start;
      justify-content: center; }
      .pricing-table .pricing-header .pricing-wrap .price-wrap-inner h6.currency {
        font-size: 15px;
        margin-bottom: 0;
        color: #ababab; }
      .pricing-table .pricing-header .pricing-wrap .price-wrap-inner h6.price {
        font-size: 56px;
        line-height: .8;
        margin: 0 4px;
        font-weight: 300;
        color: #333; }
      .pricing-table .pricing-header .pricing-wrap .price-wrap-inner h6.period {
        font-size: 15px;
        margin-bottom: 0;
        color: #ababab;
        align-self: flex-end; }
  .pricing-table .pricing-content ul.pricingfeature li {
    position: relative;
    display: block;
    padding: 5px 0; }
  .pricing-table .pricing-footer {
    padding-top: 32px; }
  .pricing-table:hover {
    box-shadow: 0 2px 30px rgba(89, 69, 230, 0.12);
    border: 1px solid transparent;
    transform: translateY(-5px); }

/*---------------------------
Pricing Table Style Two 
-----------------------------*/
.pricing-table--3 {
  z-index: 2;
  position: relative; }
  .pricing-table--3 .inner {
    position: relative;
    height: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding: 49px 20px 51px;
    border-radius: 5px;
    box-shadow: 0 0 30px rgba(51, 51, 51, 0.1);
    overflow: hidden; }
  .pricing-table--3 .pricing-header {
    z-index: 1;
    position: relative;
    margin-bottom: 25px;
    text-align: center; }
    .pricing-table--3 .pricing-header .image {
      margin-bottom: 17px; }
    .pricing-table--3 .pricing-header .title {
      font-size: 15px;
      line-height: 1.5;
      color: #ccc;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 21px; }
    .pricing-table--3 .pricing-header .price-wrapper-inner {
      display: flex;
      align-items: flex-start;
      justify-content: center; }
      .pricing-table--3 .pricing-header .price-wrapper-inner h6.currency {
        font-size: 48px;
        line-height: .8;
        margin: 0;
        font-weight: 500;
        color: #333; }
      .pricing-table--3 .pricing-header .price-wrapper-inner h6.price {
        font-size: 48px;
        line-height: .8;
        margin: 0;
        font-weight: 500;
        color: #333; }
  .pricing-table--3 .pricing-button {
    text-align: center;
    padding-top: 40px; }
    .pricing-table--3 .pricing-button .ar-button {
      display: inline-block;
      width: 180px;
      max-width: 100%;
      padding: 0 30px;
      height: 48px;
      line-height: 48px;
      font-size: 14px; }
  .pricing-table--3 .pricing-content {
    text-align: left;
    max-width: 270px;
    margin: 29px auto 0; }
    .pricing-table--3 .pricing-content ul.pricingfeature li {
      position: relative;
      display: block;
      padding: 5px 0; }
  .pricing-table--3.heighlight .inner {
    padding: 52px 20px 61px; }
    .pricing-table--3.heighlight .inner .feature-mark {
      position: absolute;
      top: 0;
      right: 0;
      height: 92px;
      width: 92px;
      background-image: linear-gradient(-188deg, #ffbe00 0%, #f76b1c 100%); }
      .pricing-table--3.heighlight .inner .feature-mark span {
        position: absolute;
        top: 20px;
        right: 7px;
        text-align: center;
        font-size: 11px;
        font-weight: 700;
        line-height: 1.19;
        display: block;
        color: #fff;
        transform: rotate(45deg);
        width: 50px; }
      .pricing-table--3.heighlight .inner .feature-mark::after {
        transform: rotate(-45deg);
        background: none repeat scroll 0 0 #fff;
        content: "";
        height: 159px;
        right: 23px;
        position: absolute;
        top: 10px;
        width: 100%; }
  .pricing-table--3:hover .inner {
    transform: translateY(-5px);
    box-shadow: 0 0px 41px rgba(51, 51, 51, 0.07); }

/*---------------------------
Pricing Table Style Four 
-----------------------------*/
.pricing-table--4 {
  z-index: 2;
  position: relative; }
  .pricing-table--4 .inner {
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding: 49px 20px 51px;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    z-index: 2; }
    .pricing-table--4 .inner::before {
      transition: opacity .3s, visibility .3s;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 101%;
      height: 101%;
      display: block;
      background-image: linear-gradient(-180deg, #681f9d 0%, #32178a 50%, #040747 100%);
      opacity: 0;
      visibility: hidden;
      z-index: -1; }
    .pricing-table--4 .inner .feature-mark {
      position: absolute;
      top: 0;
      right: 0;
      height: 90px;
      width: 140px; }
      .pricing-table--4 .inner .feature-mark::before {
        content: '';
        position: absolute;
        top: -30px;
        right: -52px;
        height: 90px;
        width: 140px;
        transform: rotate(45deg);
        background-image: linear-gradient(60deg, #ffbe00 0%, #f76b1c 100%); }
      .pricing-table--4 .inner .feature-mark span {
        position: absolute;
        top: 20px;
        right: 7px;
        text-align: center;
        font-size: 11px;
        font-weight: 700;
        line-height: 1.19;
        display: block;
        color: #fff;
        transform: rotate(45deg);
        width: 50px; }
  .pricing-table--4 .pricing-header .image {
    margin-bottom: 17px; }
  .pricing-table--4 .pricing-header .title {
    font-size: 24px;
    line-height: 1.5;
    color: #ffff;
    margin-bottom: 18px; }
  .pricing-table--4 .pricing-content p {
    text-align: center;
    color: rgba(255, 255, 255, 0.7); }
  .pricing-table--4 .pricing-button {
    text-align: center;
    padding-top: 40px; }
    .pricing-table--4 .pricing-button .ar-button {
      display: inline-block;
      width: 180px;
      max-width: 100%;
      padding: 0 30px;
      height: 48px;
      line-height: 48px;
      font-size: 14px; }
  .pricing-table--4.heighted .inner::before {
    opacity: 1;
    visibility: visible; }
  .pricing-table--4:hover .inner {
    transform: translateY(-5px); }
    .pricing-table--4:hover .inner::before {
      opacity: 1;
      visibility: visible; }

/*---------------------------
Pricing Table Style Five
-----------------------------*/
.pricing-box--5 {
  height: 100%; }
  .pricing-box--5 .inner {
    position: relative;
    height: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px 30px 101px;
    border-radius: 4px;
    box-shadow: 0 0 30px rgba(51, 51, 51, 0.1);
    overflow: hidden;
    text-align: center; }
    .pricing-box--5 .inner .thumbnail {
      margin-bottom: 28px; }
    .pricing-box--5 .inner .content h4.title {
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 2px;
      line-height: 1.56;
      margin-bottom: 14px; }
    .pricing-box--5 .inner .content .feature-list .pricing-feature-list {
      color: #6d70a6; }
      .pricing-box--5 .inner .content .feature-list .pricing-feature-list li {
        position: relative;
        display: block;
        padding: 7px 0; }
    .pricing-box--5 .inner .pricing-footer {
      position: absolute;
      bottom: 56px;
      left: 30px;
      right: 30px; }
      .pricing-box--5 .inner .pricing-footer button {
        background: transparent;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.23;
        position: relative;
        z-index: 1;
        padding-bottom: 2px;
        color: #38cb89;
        border: 0 none;
        padding-left: 0;
        padding-right: 0; }
        .pricing-box--5 .inner .pricing-footer button::after {
          content: '';
          width: 0;
          height: 1px;
          bottom: 0;
          position: absolute;
          left: auto;
          right: 0;
          z-index: -1;
          transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          background: currentColor; }
        .pricing-box--5 .inner .pricing-footer button:hover::after {
          width: 100%;
          left: 0;
          right: auto; }
  .pricing-box--5:hover .inner {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(51, 51, 51, 0.1); }

/*----------------------------
Newsletter Area 
----------------------------*/
.branding-newsletter {
  background: linear-gradient(-259deg, #5E60E7 0, #9C7AF2 65%); }

.branding-newsletter button.ar-button {
  background: #ffffff;
  color: #5E60E7; }

.newsletter-form--1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px 0; }
  @media only screen and (max-width: 767px) {
    .newsletter-form--1 {
      text-align: center; } }
  .newsletter-form--1 .form-item {
    margin: 10px; }
    @media only screen and (max-width: 767px) {
      .newsletter-form--1 .form-item {
        display: block;
        margin: 10px 0; } }
    .newsletter-form--1 .form-item input {
      border-color: transparent;
      background: rgba(0, 0, 0, 0.07);
      min-width: 275px;
      width: 100%;
      outline: none;
      border: 1px solid #eee;
      border-radius: 5px;
      padding: 3px 20px;
      height: 56px;
      border: 0 none;
      outline: none; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .newsletter-form--1 .form-item input {
          min-width: 200px; } }
    .newsletter-form--1 .form-item.button {
      background: #fff;
      min-width: 180px;
      color: #5945e6;
      padding: 0 41px;
      height: 56px;
      line-height: 54px;
      outline: none;
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
      font-weight: 600; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .newsletter-form--1 .form-item.button {
          margin: 0 auto; } }
      @media only screen and (max-width: 767px) {
        .newsletter-form--1 .form-item.button {
          margin: 0 auto; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .newsletter-form--1 .form-item.button.ar-button {
          margin: 0 auto; } }
      @media only screen and (max-width: 767px) {
        .newsletter-form--1 .form-item.button.ar-button {
          margin: 0 auto; } }

.newsletter-form--1 .form-item input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff; }

.newsletter-form--1 .form-item input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff; }

.newsletter-form--1 .form-item input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff; }

.branding-newsletter .newsletter-form--1 .form-item input {
  color: #ffffff; }

.newsletter-area .next-arrowland.title {
  margin-bottom: 0; }

/*==========================
    Appshowcase Newsletter 
============================*/
.appshowcase-newsletter .newsletter-form--1 .form-item input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7e7e7e; }

.appshowcase-newsletter .newsletter-form--1 .form-item input::-moz-placeholder {
  /* Firefox 19+ */
  color: #7e7e7e; }

.appshowcase-newsletter .newsletter-form--1 .form-item input:-ms-input-placeholder {
  /* IE 10+ */
  color: #7e7e7e; }

/*=======================
    Newsletter Area 
=========================*/
.next-arrowland.title {
  margin-top: 45px;
  font-size: 18px; }
  .next-arrowland.title a {
    color: #38cb89; }

/*===================== 
    List Style Area 
=======================*/
ul.list li {
  margin: 10px 0; }
  ul.list li svg {
    color: #38cb89; }
  ul.list li span {
    font-size: 14px;
    line-height: inherit;
    font-weight: 400;
    color: #696969;
    margin-left: 11px; }

/*=====================
    Dividers 
========================*/
.thine-line {
  border-bottom: 1px solid #ddd; }

.thine-line-gray {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.dash-line {
  border-bottom: 1px dashed #ddd; }

.thick-line {
  border-bottom: 2px solid #ddd; }

.thin-short-line {
  display: inline-block;
  height: 2px;
  width: 120px;
  background: #222; }

.thick-short-line {
  display: inline-block;
  height: 3px;
  width: 120px;
  background: #222; }

/*======================
Basic Modern Dots 
========================*/
.basic-modern-dots {
  display: inline-block;
  padding: 3px 10px;
  cursor: pointer; }
  .basic-modern-dots .dot {
    background-color: #5E61E7;
    border-radius: 50%;
    box-shadow: 0 0 12px #def0ff;
    -webkit-animation: separator-bounce 1.4s ease-in-out 0s infinite both;
    animation: separator-bounce 1.4s ease-in-out 0s infinite both;
    width: 14px;
    height: 14px; }
    .basic-modern-dots .dot.second-circle {
      margin-top: 18px;
      -webkit-animation-delay: -.16s;
      animation-delay: -.16s; }
    .basic-modern-dots .dot.third-circle {
      margin-top: 14px;
      -webkit-animation-delay: -.32s;
      animation-delay: -.32s; }
  .basic-modern-dots.white-dots .dot {
    background-color: #ffffff;
    box-shadow: 0 0 12px #ffffff; }

/*=======================
    Attribute Area 
=========================*/
.attribute-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 54px 30px;
  width: 100%; }
  .attribute-list li .title {
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0; }
  .attribute-list li .value {
    margin-top: 14px;
    display: block; }

/*==================================
    Attribute List Style Two 
====================================*/
.attribute-list--2 {
  width: 100%;
  border-collapse: collapse;
  padding: 40px 30px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
  border-radius: 10px; }
  .attribute-list--2 li {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .attribute-list--2 li div {
      padding: 12px 0; }
    .attribute-list--2 li .name .title {
      color: #333;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 0; }
    .attribute-list--2 li .value {
      text-align: right; }
    .attribute-list--2 li + li {
      border-top: 1px solid #eee; }

/*======================
    Call To Action 
========================*/
.call-to-action--1 {
  box-shadow: 0 15px 40px rgba(89, 69, 230, 0.05);
  background: url(/assets/images/mokup/aeroland-macbook-02.png) 55% 100% no-repeat, linear-gradient(-138deg, #5E60E7 0, #9C7AF2 50%, #5E60E7 100%);
  border-radius: 5px; }
  .call-to-action--1 .inner {
    padding-left: 72px; }
    @media only screen and (max-width: 767px) {
      .call-to-action--1 .inner {
        padding-left: 15px; } }
    .call-to-action--1 .inner .content h3 {
      line-height: 1.88;
      font-size: 34px;
      font-weight: 600;
      letter-spacing: 0em;
      margin-bottom: 4px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .call-to-action--1 .inner .content h3 {
          font-size: 26px;
          margin-bottom: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .call-to-action--1 .inner .content h3 {
          font-size: 26px;
          margin-bottom: 0; } }
      @media only screen and (max-width: 767px) {
        .call-to-action--1 .inner .content h3 {
          font-size: 26px;
          margin-bottom: 0; } }
    .call-to-action--1 .inner .content h5 {
      line-height: 1.78;
      color: #fff;
      font-size: 18px; }
  .call-to-action--1 .thumbnail {
    margin-top: -53px;
    margin-right: -90px;
    margin-bottom: -112px;
    text-align: right; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .call-to-action--1 .thumbnail {
        margin-top: 37px;
        margin-right: 0;
        margin-bottom: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .call-to-action--1 .thumbnail {
        margin-top: 37px;
        margin-right: 0;
        margin-bottom: 0; } }
    @media only screen and (max-width: 767px) {
      .call-to-action--1 .thumbnail {
        margin-top: 37px;
        margin-right: 0;
        margin-bottom: 0; } }
  .call-to-action--1.variation--2 .thumbnail {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: -75px;
    text-align: right; }

/*=========================
    Call To Action Two 
===========================*/
.call-to-action-bg {
  background-image: url(/assets/images/call-to-action/call-to-action-03-bg.png);
  background-repeat: no-repeat;
  background-position: top right 200px; }

.call-to-action-four-bg .wrapper {
  background-image: url(/assets/images/aeroland/startup-image-01.png);
  background-repeat: no-repeat;
  background-position: top right 170px; }

/*==========================
    Message Box Area 
=============================*/
.message-box {
  background: rgba(91, 99, 254, 0.1);
  position: relative;
  padding: 27px 30px 27px 50px;
  font-weight: 600;
  border-radius: 5px; }
  .message-box .icon {
    position: absolute;
    top: 30px;
    left: 20px;
    font-size: 20px;
    line-height: 1; }
    .message-box .icon i {
      color: #5b63fe; }
  .message-box p {
    color: #5b63fe; }
  .message-box.success {
    background: rgba(56, 203, 137, 0.1); }
    .message-box.success .icon i {
      color: #38cb89; }
    .message-box.success p {
      color: #38cb89; }
  .message-box.danger {
    background: rgba(254, 40, 84, 0.1); }
    .message-box.danger .icon i {
      color: #fe2854; }
    .message-box.danger p {
      color: #fe2854; }
  .message-box.warning {
    background: rgba(255, 187, 0, 0.1); }
    .message-box.warning .icon i {
      color: #fb0; }
    .message-box.warning p {
      color: #fb0; }

/*====================
Popup Video 
=====================*/
.popup-video-inner .video-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .popup-video-inner .video-popup.button-right {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 767px) {
      .popup-video-inner .video-popup.button-right {
        left: 50%; } }

.video-popup {
  width: 60px;
  height: 60px;
  background: transparent;
  border: 4px solid #fff;
  position: relative;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  border-radius: 50%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  outline: none; }
  .video-popup span.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
    margin-left: 1px; }
    .video-popup span.play-icon::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 17px solid #fff; }
  .video-popup.icon-large {
    width: 72px;
    height: 72px;
    background: transparent;
    border: 6px solid #fff; }
  .video-popup.button-solid {
    width: 120px;
    height: 120px;
    background: #fff;
    border: 0 none; }
    @media only screen and (max-width: 767px) {
      .video-popup.button-solid {
        width: 70px;
        height: 70px; } }
    .video-popup.button-solid span.play-icon::before {
      border-top-width: 12px;
      border-bottom-width: 12px;
      border-left-width: 19px;
      border-left-color: #ee7455; }
  .video-popup.button-solid-blue {
    width: 100px;
    height: 100px;
    background: #fff;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
    border: 0 none; }
    @media only screen and (max-width: 767px) {
      .video-popup.button-solid-blue {
        width: 70px;
        height: 70px; } }
    .video-popup.button-solid-blue span.play-icon::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 17px solid #5945e6; }
  .video-popup.button-solid-yellow {
    width: 78px;
    height: 78px;
    background-image: linear-gradient(-221deg, #e17643 0%, #f0a86b 100%);
    box-shadow: 0 2px 41px 0 rgba(91, 99, 254, 0.36);
    border: 0 none; }
    @media only screen and (max-width: 767px) {
      .video-popup.button-solid-yellow {
        width: 70px;
        height: 70px; } }
    .video-popup.button-solid-yellow span.play-icon::before {
      border-top-width: 9px;
      border-bottom-width: 9px;
      border-left-width: 14px; }
  .video-popup:hover {
    transform: translate(-50%, -50%) scale3d(1.15, 1.15, 1.15); }

.video-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%, -50%);
  pointer-events: none; }
  .video-marker .wave-pulse::before, .video-marker .wave-pulse::after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: 3px solid #ee7455;
    animation: zoomBig 3.25s linear infinite; }
  .video-marker .wave-pulse.wave-pulse-1 {
    animation-delay: .75s; }

@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px; }
  40% {
    opacity: .5;
    border-width: 2px; }
  65% {
    border-width: 1px; }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px; } }

/*=======================
    Gallery Area 
==========================*/
.gallery {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 3;
  overflow: hidden; }
  .gallery .action {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    z-index: 2; }
  .gallery .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: #5E61E7;
    z-index: 1; }
  .gallery:hover .overlay {
    visibility: visible;
    opacity: .9; }
  .gallery:hover .action {
    visibility: visible;
    opacity: 1; }

/*==========================
Progressbar Area 
============================*/
.progress-bar--1 .single-progress {
  position: relative;
  margin-top: 38px; }
  .progress-bar--1 .single-progress .title {
    font-size: 18px;
    color: #6d70a6;
    font-weight: 400;
    margin-bottom: 5px; }
  .progress-bar--1 .single-progress .progress {
    height: 4px;
    border-radius: 4px;
    box-shadow: none;
    background-color: #f5f7fd;
    overflow: visible; }
    .progress-bar--1 .single-progress .progress .progress-bar {
      background-color: #9C7AF2;
      background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
      position: relative;
      color: #5E61E7; }
      .progress-bar--1 .single-progress .progress .progress-bar::before, .progress-bar--1 .single-progress .progress .progress-bar::after {
        content: '';
        position: absolute;
        right: -4px;
        top: 50%;
        border-radius: 50%;
        transform: translate(50%, -50%); }
      .progress-bar--1 .single-progress .progress .progress-bar::before {
        height: 28px;
        width: 28px;
        border: 1px solid currentColor;
        opacity: .3; }
      .progress-bar--1 .single-progress .progress .progress-bar::after {
        height: 13px;
        width: 13px;
        border: 3px solid currentColor; }
  .progress-bar--1 .single-progress .label {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 600;
    color: #2e3280; }
    @media only screen and (max-width: 767px) {
      .progress-bar--1 .single-progress .label {
        top: -8px; } }
  .progress-bar--1 .single-progress.custom-color--1 .progress .progress-bar {
    color: #61fded;
    background-color: #0d8abc;
    background-image: linear-gradient(-224deg, #0d8abc 0px, #61fded 100%); }
  .progress-bar--1 .single-progress.custom-color--2 .progress .progress-bar {
    color: #eece90;
    background-color: #d45529;
    background-image: linear-gradient(-224deg, #d45529 0px, #eece90 100%); }
  .progress-bar--1 .single-progress.custom-color--3 .progress .progress-bar {
    color: #5c51ff;
    background-color: #f646a9;
    background-image: linear-gradient(-224deg, #f646a9 0px, #5c51ff 100%); }
  .progress-bar--1 .single-progress.custom-color--4 .progress .progress-bar {
    color: #e5529a;
    background-color: #e77654;
    background-image: linear-gradient(-224deg, #e77654 0px, #e5529a 100%); }

.progress-bar--1.style-dots .single-progress .progress {
  height: 32px;
  border: 2px solid #f2f2f2;
  padding: 4px;
  border-radius: 30px;
  box-shadow: none;
  background: none;
  overflow: visible; }
  .progress-bar--1.style-dots .single-progress .progress .progress-bar {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    background-color: transparent !important;
    background-size: 25px 25px;
    background-repeat: repeat-x;
    background-position: -3px center;
    background-image: radial-gradient(ellipse at center, #5945e6 50%, transparent 55%); }
    .progress-bar--1.style-dots .single-progress .progress .progress-bar::before, .progress-bar--1.style-dots .single-progress .progress .progress-bar::after {
      display: none; }

.progress-bar--1.style-solid .single-progress .progress {
  height: 32px;
  padding: 6px;
  border-radius: 30px;
  box-shadow: none;
  background-color: #f2f2f2; }
  .progress-bar--1.style-solid .single-progress .progress .progress-bar {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    border-radius: inherit;
    background-color: #9C7AF2;
    background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%); }
    .progress-bar--1.style-solid .single-progress .progress .progress-bar::before, .progress-bar--1.style-solid .single-progress .progress .progress-bar::after {
      display: none; }

.progress-bar--1.style-solid .single-progress.custom-color--1 .progress .progress-bar {
  color: #61fded;
  background-color: #0d8abc;
  background-image: linear-gradient(-224deg, #0d8abc 0px, #61fded 100%); }

.progress-bar--1.style-solid .single-progress.custom-color--2 .progress .progress-bar {
  color: #eece90;
  background-color: #d45529;
  background-image: linear-gradient(-224deg, #d45529 0px, #eece90 100%); }

.progress-bar--1.style-solid .single-progress.custom-color--3 .progress .progress-bar {
  color: #5c51ff;
  background-color: #f646a9;
  background-image: linear-gradient(-224deg, #f646a9 0px, #5c51ff 100%); }

.progress-bar--1.style-solid .single-progress.custom-color--4 .progress .progress-bar {
  color: #e5529a;
  background-color: #e77654;
  background-image: linear-gradient(-224deg, #e77654 0px, #e5529a 100%); }

/*======================
    Team Area 
========================*/
.team .thumbnail {
  position: relative;
  margin-bottom: 31px; }
  .team .thumbnail img {
    width: 200px;
    border-radius: 50%; }

.team .title {
  font-size: 24px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 12px; }

.team p {
  color: #6d70a6;
  font-size: 14px; }

.team ul.social-icon {
  font-size: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px); }

.team:hover ul.social-icon {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); }

/*=====================
Team Style Two 
========================*/
.team_style--2 {
  padding: 15px;
  text-align: center; }
  .team_style--2 .inner {
    background: #fff;
    padding: 30px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 4px 20px rgba(51, 51, 51, 0.1); }
  .team_style--2 .thumbnail {
    margin-bottom: 24px; }
    .team_style--2 .thumbnail img {
      border-radius: 50%; }
  .team_style--2 .title {
    font-size: 18px;
    margin-bottom: 10px; }
  .team_style--2 p {
    color: #6d70a6;
    font-size: 14px; }
  .team_style--2 ul.social-icon {
    margin-top: 18px; }
  .team_style--2:hover .inner {
    transform: translateY(-5px); }

.slick-slide .team_style--2 img {
  display: inline-block; }

/*=================
List Style 
==================*/
.list-style--1 li {
  color: #696969;
  font-weight: 400;
  font-size: 14px;
  line-height: inherit;
  margin-bottom: 10px; }
  .list-style--1 li i {
    color: #38cb89;
    padding-right: 10px; }

.list-style--1.text-white li {
  color: #ffffff; }

.list-style--2 li {
  font-size: 24px;
  color: #333;
  line-height: 1.2;
  font-weight: 400; }
  .list-style--2 li + li {
    margin-top: 24px; }

.list-style--2.text-white li {
  color: #ffffff; }

/*=======================
    Tab Area 
========================*/
.tab-style--1 {
  margin: 0;
  text-align: center;
  margin: -10px;
  margin-bottom: 50px;
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .tab-style--1 {
      margin: -5px;
      margin-bottom: 30px; } }
  .tab-style--1 li {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 1px solid #ededed;
    background: #fff;
    color: #696969;
    padding: 21px 20px 19px;
    min-width: 200px;
    border-radius: 5px;
    margin: 10px;
    outline: none; }
    @media only screen and (max-width: 767px) {
      .tab-style--1 li {
        min-width: 140px;
        margin: 5px; } }
    .tab-style--1 li:hover {
      color: #fff;
      background: #7c82fe;
      border-color: transparent; }
    .tab-style--1 li.react-tabs__tab--selected {
      color: #fff;
      background: #7c82fe;
      border-color: transparent; }

/*=======================
Tab Style Two 
=========================*/
.tab-style--2 .tab2-content {
  text-align: left;
  opacity: .5;
  cursor: pointer; }
  .tab-style--2 .tab2-content .title {
    display: flex;
    align-items: center; }
    .tab-style--2 .tab2-content .title i {
      display: inline-block;
      width: 70px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      font-size: 32px;
      border-radius: 15px;
      color: #fff;
      background-image: linear-gradient(-225deg, #f8c59b 0%, #e68c60 100%);
      box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
      margin-right: 30px; }
      @media only screen and (max-width: 767px) {
        .tab-style--2 .tab2-content .title i {
          width: 63px;
          height: 61px;
          line-height: 61px;
          font-size: 20px;
          margin-right: 17px; } }
    .tab-style--2 .tab2-content .title h4 {
      flex-grow: 1;
      max-width: 226px;
      font-size: 24px;
      font-weight: 600;
      color: #fff; }
      @media only screen and (max-width: 767px) {
        .tab-style--2 .tab2-content .title h4 {
          font-size: 18px; } }
  .tab-style--2 .tab2-content p {
    color: #fff;
    margin-top: 10px;
    margin-bottom: 0; }

.tab-style--2.text-gray .tab2-content .title h4 {
  color: #2e3280; }

.tab-style--2.text-gray .tab2-content p {
  color: #2e3280; }

.tab2-thumbnail-wrapper .thumbnail img {
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px; }

.tab2-thumbnail-wrapper.slick-dots-style .slick-dots {
  bottom: -55px; }
  @media only screen and (max-width: 767px) {
    .tab2-thumbnail-wrapper.slick-dots-style .slick-dots {
      bottom: -34px; } }
  .tab2-thumbnail-wrapper.slick-dots-style .slick-dots li {
    padding: 5px 4px;
    width: auto;
    height: auto; }
    .tab2-thumbnail-wrapper.slick-dots-style .slick-dots li button {
      position: relative;
      margin: 0;
      width: 16px;
      height: 16px;
      opacity: 1;
      background: none;
      border-radius: 100%; }
      .tab2-thumbnail-wrapper.slick-dots-style .slick-dots li button::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: #fff;
        opacity: 1; }
    .tab2-thumbnail-wrapper.slick-dots-style .slick-dots li.slick-active button::before {
      background: #fff;
      width: 16px;
      height: 16px; }

.text-gray .slick-dots-style .slick-dots li button::before {
  background: #5E61E7; }

.text-gray .slick-dots-style .slick-dots li.slick-active button::before {
  background: #5E61E7; }

.tab-style--2 .slick-track {
  height: auto !important; }

.tab-style--2 .slick-center .tab2-content {
  opacity: 1; }

.startups-tabstwo-area .tabs-three-area {
  padding-top: 0; }

/*=======================
    Contact area 
==========================*/
@media only screen and (max-width: 767px) {
  .subscribe-area h2 br {
    display: none; } }

@media only screen and (max-width: 767px) {
  .subscribe-area h2 {
    font-size: 27px !important; } }

.contact-form-one .thumbnail {
  margin-right: -168px;
  margin-left: 80px;
  text-align: left; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-form-one .thumbnail {
      margin-right: 0;
      margin-left: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-form-one .thumbnail {
      margin-right: 0;
      margin-left: 0; } }
  @media only screen and (max-width: 767px) {
    .contact-form-one .thumbnail {
      margin-right: 0;
      margin-left: 0;
      text-align: center;
      margin-top: 30px; } }

.contact-form--1 label {
  display: block;
  margin-bottom: 0; }

.contact-form--1 input {
  color: #ababab;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
  margin-bottom: 20px; }

.contact-form--1 textarea {
  width: 100%;
  outline: none;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  background: #f8f8f8;
  display: block;
  padding: 12px 20px 3px;
  max-width: 100%;
  color: #ababab;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
  height: 150px; }

.newsletter-form--1.color-body .form-item input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7e7e7e; }

.newsletter-form--1.color-body .form-item input::-moz-placeholder {
  /* Firefox 19+ */
  color: #7e7e7e; }

.newsletter-form--1.color-body .form-item input:-ms-input-placeholder {
  /* IE 10+ */
  color: #7e7e7e; }

/*======================
    Countdown Area 
========================*/
.time-counterwrapper {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  width: 770px;
  max-width: 100%;
  margin: 0 auto; }
  @media only screen and (max-width: 767px) {
    .time-counterwrapper {
      grid-template-columns: repeat(2, 1fr); } }
  .time-counterwrapper .time-inner {
    display: inline-block;
    font-size: 80px;
    font-weight: 400;
    line-height: 1;
    color: #f76b1c; }
    @media only screen and (max-width: 767px) {
      .time-counterwrapper .time-inner {
        font-size: 40px; } }
    .time-counterwrapper .time-inner .text {
      display: block;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
      font-size: 14px;
      margin-top: 10px;
      color: #333; }

/**************************************
    Marketing Styles
***************************************/
/*================================
    Marketing Initiatives Area
==================================*/
.marketing-inner {
  width: 600px;
  max-width: 100%;
  float: right; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .marketing-inner {
      width: 100%;
      margin-bottom: 30px;
      padding: 0 15px; } }
  @media only screen and (max-width: 767px) {
    .marketing-inner {
      width: 100%;
      margin-bottom: 30px;
      padding: 0 15px; } }

.marketing-initiatives-area {
  position: relative; }

/**************************************
    Blog Styles
***************************************/
/*=================
Blog Area 
===================*/
.blog {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 32px rgba(51, 51, 51, 0.07);
  margin-bottom: 30px; }
  .blog .thumbnail a img {
    max-width: 100%; }
  .blog .content {
    padding: 24px 30px 50px; }
    .blog .content span.category {
      margin-bottom: 6px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
      display: block;
      color: #b6b7d2; }
    .blog .content h4.title a {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 60px;
      color: #2e3280; }
    .blog .content .post-meta {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .blog .content .post-meta .meta-info {
        display: flex;
        align-items: center; }
        .blog .content .post-meta .meta-info img {
          width: 32px;
          vertical-align: middle;
          margin-right: 6px;
          border-radius: 50%; }
        .blog .content .post-meta .meta-info h6.author {
          margin-bottom: 0;
          color: #7e7e7e;
          font-weight: 400; }
      .blog .content .post-meta .date span {
        color: #7e7e7e; }

/*======================
    Blog Details 
========================*/
p:last-child {
  margin-bottom: 0; }

.single-post-details .thumbnail {
  margin-bottom: 48px; }
  @media only screen and (max-width: 767px) {
    .single-post-details .thumbnail {
      margin-bottom: 20px; } }

.single-post-details .content span.category {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 9px;
  display: block;
  color: #b6b7d2;
  text-align: center; }

.single-post-details .content h2 {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.42;
  margin: 0 0 16px;
  word-wrap: break-word;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .single-post-details .content h2 {
      font-size: 28px;
      margin: 0 0 10px; } }

.single-post-details .content .post-meta {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; }
  .single-post-details .content .post-meta .meta-info {
    display: flex;
    align-items: center;
    padding: 0 8px; }
    .single-post-details .content .post-meta .meta-info img {
      width: 32px;
      vertical-align: middle;
      margin-right: 6px;
      border-radius: 50%; }
    .single-post-details .content .post-meta .meta-info .author {
      margin-bottom: 0;
      color: #7e7e7e;
      font-weight: 400; }
  .single-post-details .content .post-meta .date {
    padding: 0 8px; }
    .single-post-details .content .post-meta .date span {
      color: #7e7e7e; }

.single-post-details .content-wrapper {
  max-width: 800px;
  margin: 0 auto; }
  .single-post-details .content-wrapper .blog-description p {
    margin-bottom: 1.5em;
    color: #7e7e7e; }
  .single-post-details .content-wrapper .blog-description blockquote {
    margin: 54px 65px 44px 70px;
    padding: 0 0 0 20px;
    font-size: 18px;
    font-style: italic;
    line-height: 1.78;
    border-left: 2px solid #eee;
    position: relative;
    color: #6d70a6;
    border-left-color: #38cb89; }
    @media only screen and (max-width: 767px) {
      .single-post-details .content-wrapper .blog-description blockquote {
        margin: 30px; } }
  .single-post-details .content-wrapper .blog-post-share {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e8f6;
    margin-bottom: 50px;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .single-post-details .content-wrapper .blog-post-share {
        display: block; } }
    .single-post-details .content-wrapper .blog-post-share .post-share-left .tagcloud {
      margin: -5px; }
      .single-post-details .content-wrapper .blog-post-share .post-share-left .tagcloud li {
        display: inline-block;
        padding: 5px; }
        .single-post-details .content-wrapper .blog-post-share .post-share-left .tagcloud li a {
          color: #ababab;
          display: inline-block; }
          .single-post-details .content-wrapper .blog-post-share .post-share-left .tagcloud li a:hover {
            color: #5E61E7; }
    .single-post-details .content-wrapper .blog-post-share .post-share-right {
      display: flex;
      align-items: center; }
      .single-post-details .content-wrapper .blog-post-share .post-share-right span {
        font-size: 14px;
        margin-right: 8px;
        color: #ababab; }

.single-post-details h4.post-title {
  margin-top: 66px;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 400;
  color: #2e3280; }

.single-post-details .related-post-wrapper .related-post {
  width: 400px;
  padding: 15px;
  height: 100%;
  background-color: #fff;
  border: 1px solid #e7e7e7; }
  @media only screen and (max-width: 767px) {
    .single-post-details .related-post-wrapper .related-post {
      width: auto; } }
  .single-post-details .related-post-wrapper .related-post .inner {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    padding: 85px 30px 43px; }
    @media only screen and (max-width: 767px) {
      .single-post-details .related-post-wrapper .related-post .inner {
        padding: 20px; } }
    .single-post-details .related-post-wrapper .related-post .inner span {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 14px;
      font-weight: 500;
      color: #b6b7d2; }
    .single-post-details .related-post-wrapper .related-post .inner h3 {
      margin: 6px 0 0;
      font-size: 18px;
      line-height: 1.5;
      word-wrap: break-word;
      color: #2e3280; }

.blog-comment .single-comment {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .blog-comment .single-comment {
      display: block; } }
  .blog-comment .single-comment.CommentToComment {
    margin: 40px 0 20px 100px;
    padding: 0; }
    @media only screen and (max-width: 767px) {
      .blog-comment .single-comment.CommentToComment {
        margin: 40px 0 20px 20px; } }
  .blog-comment .single-comment .comment-content {
    padding-left: 40px; }
    @media only screen and (max-width: 767px) {
      .blog-comment .single-comment .comment-content {
        padding-left: 0; } }
    .blog-comment .single-comment .comment-content .heading {
      display: flex;
      align-items: center;
      margin-bottom: 12px; }
      @media only screen and (max-width: 767px) {
        .blog-comment .single-comment .comment-content .heading {
          display: block; } }
      .blog-comment .single-comment .comment-content .heading h5 {
        font-size: 15px;
        color: #333;
        display: inline-block;
        margin-bottom: 0; }
      .blog-comment .single-comment .comment-content .heading span {
        position: relative;
        display: inline-block;
        padding-left: 15px;
        margin-left: 10px;
        font-size: 14px;
        font-style: italic;
        color: #ababab; }
        @media only screen and (max-width: 767px) {
          .blog-comment .single-comment .comment-content .heading span {
            padding-left: 0;
            margin-left: 0; } }
    .blog-comment .single-comment .comment-content .reply-button {
      margin-top: 16px; }
      .blog-comment .single-comment .comment-content .reply-button button {
        font-weight: 600;
        color: #6d70a6;
        padding: 0;
        margin: 0;
        border: 0 none; }
        .blog-comment .single-comment .comment-content .reply-button button:hover {
          color: #5E61E7; }
  .blog-comment .single-comment + .single-comment {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #ededed; }

.comment-form-area .input-box input {
  color: #ababab;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
  margin: 0 0 20px; }

.comment-form-area .input-box textarea {
  height: 200px;
  padding: 19px 20px 3px;
  width: 100%;
  outline: none;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  background: #f8f8f8;
  display: block;
  max-width: 100%; }

/**************************************
    Common Styles
***************************************/
/*=====================
    Breadcrumb Area 
======================*/
.breadcrumb-default {
  border-bottom-width: 0px;
  border-bottom-color: rgba(0, 0, 0, 0);
  padding-top: 196px;
  padding-bottom: 114px;
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff 0, #eceefa 100%); }
  @media only screen and (max-width: 767px) {
    .breadcrumb-default {
      padding-top: 134px;
      padding-bottom: 96px; } }
  .breadcrumb-default .content .inner {
    text-align: center; }
    .breadcrumb-default .content .inner h1.page-title {
      font-size: 48px;
      font-weight: 500;
      line-height: 1.17; }
      @media only screen and (max-width: 767px) {
        .breadcrumb-default .content .inner h1.page-title {
          font-size: 30px; } }
    .breadcrumb-default .content .inner .breadcrumb-list .breadcrumb-item {
      display: inline-block; }
      .breadcrumb-default .content .inner .breadcrumb-list .breadcrumb-item a {
        color: rgba(109, 112, 166, 0.5);
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 1.58;
        text-transform: uppercase; }
      .breadcrumb-default .content .inner .breadcrumb-list .breadcrumb-item.active {
        color: #5E61E7; }

/*====================
    Link Css 
=====================*/
.theme-gradient {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5E60E7), color-stop(100%, #9C7AF2));
  background: -moz-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%);
  background: -webkit-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%);
  background: -o-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%);
  background: -ms-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%);
  background: linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%); }

.theme-gradient--2 {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #8383ED), color-stop(65%, #B095F5));
  background: -moz-linear-gradient(-259deg, #8383ED 0, #B095F5 65%);
  background: -webkit-linear-gradient(-259deg, #8383ED 0, #B095F5 65%);
  background: -o-linear-gradient(-259deg, #8383ED 0, #B095F5 65%);
  background: -ms-linear-gradient(-259deg, #8383ED 0, #B095F5 65%);
  background: linear-gradient(-259deg, #8383ED 0, #B095F5 65%); }

.backto-top div {
  color: #fff;
  background-color: #9C7AF2;
  background-image: linear-gradient(-218deg, #9C7AF2 0, #5E61E7 50%, #9C7AF2 100%);
  background-size: 200% auto;
  background-position: left center;
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 9999;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 54px;
  cursor: pointer; }
  .backto-top div i {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #ffffff;
    transform: translate(-50%, -50%); }

a.link-hover {
  display: inline-block;
  position: relative;
  z-index: 1; }
  a.link-hover::after {
    content: '';
    width: 0;
    height: 1px;
    bottom: 0;
    position: absolute;
    left: auto;
    right: 0;
    z-index: -1;
    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    background: currentColor; }
  a.link-hover:hover::after {
    width: 100%;
    left: 0;
    right: auto; }

/*==========================
Maintenance Area 
============================*/
.maintenane-inner {
  width: 100%;
  height: 100vh;
  text-align: center;
  background: #ffffff;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .maintenane-inner {
      height: auto; } }
  @media only screen and (max-width: 767px) {
    .maintenane-inner {
      height: auto; } }
  .maintenane-inner .left-bg {
    background-image: url(/assets/images/bg/bg-image-12.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }
  .maintenane-inner .right-bg {
    padding: 50px 0; }
    .maintenane-inner .right-bg .inner {
      width: 660px;
      max-width: 100%;
      margin: 0 auto; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .maintenane-inner .right-bg .inner {
          padding: 0 30px; } }
      @media only screen and (max-width: 767px) {
        .maintenane-inner .right-bg .inner {
          padding: 0 30px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .maintenane-inner .right-bg .inner {
          padding: 0 30px; } }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .maintenane-inner .right-bg .inner {
          padding: 0 30px; } }
      .maintenane-inner .right-bg .inner .content .aeroland-logo {
        margin-bottom: 152px; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .maintenane-inner .right-bg .inner .content .aeroland-logo {
            margin-bottom: 50px; } }
        @media only screen and (max-width: 767px) {
          .maintenane-inner .right-bg .inner .content .aeroland-logo {
            margin-bottom: 50px; } }
      .maintenane-inner .right-bg .inner .content .title {
        font-size: 40px;
        line-height: 1.4;
        margin: 0 auto 43px;
        width: 600px;
        max-width: 100%;
        color: #6d70a6; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .maintenane-inner .right-bg .inner .content .title {
            margin: 0 auto 26px; } }
        @media only screen and (max-width: 767px) {
          .maintenane-inner .right-bg .inner .content .title {
            margin: 0 auto 26px;
            font-size: 27px; } }
  .maintenane-inner .maintanence-subscribe .form-item {
    display: block; }
    .maintenane-inner .maintanence-subscribe .form-item input {
      color: #ababab;
      border-color: #f8f8f8;
      background-color: #f8f8f8;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0em;
      width: 100%;
      outline: none;
      border: 1px solid #eee;
      border-radius: 5px;
      padding: 3px 20px;
      height: 56px;
      text-align: center; }

/*===================
    Not Found Page 
======================*/
.page-not-found {
  background-image: url(/assets/images/bg/bg-image-13.jpg);
  background-color: #681F9D;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .page-not-found .content {
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center; }
  .page-not-found .title {
    color: #fff;
    font-size: 56px;
    font-weight: 400;
    line-height: 1.28; }
    @media only screen and (max-width: 767px) {
      .page-not-found .title {
        font-size: 44px; } }

/*====================
    Demo Option 
=====================*/
.demo-option {
  position: fixed;
  top: 0;
  left: 100%;
  background: #fff;
  height: 100%;
  width: 350px;
  max-width: calc(100% - 45px);
  z-index: 100000;
  -webkit-transition: all .5s;
  transition: all .5s; }
  .demo-option .demo-optiontoolbar {
    position: absolute;
    top: 200px;
    right: 100%;
    display: block;
    width: 40px;
    line-height: 60px;
    border: 1px solid #eee;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    background: #fff;
    text-align: center;
    box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1); }
  .demo-option .demo-panel {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 55px; }
  .demo-option.active {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14); }

/*====================
    Portfolio Area 
======================*/
.portfolio {
  position: relative;
  z-index: 2;
  margin-bottom: 30px; }
  .portfolio .thumbnail img {
    border-radius: 5px;
    width: 100%; }
  .portfolio .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2; }
    .portfolio .content::before {
      background-color: #9C7AF2;
      background-image: linear-gradient(-129deg, #9C7AF2 0, #5E61E7 100%);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
      border-radius: inherit;
      z-index: -1;
      content: "";
      border-radius: 5px; }
    .portfolio .content .inner {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      transform: translateY(-50%);
      padding: 0 15px; }
      .portfolio .content .inner h4.title {
        opacity: 0;
        transition: .3s ease-out 0s;
        transform: translateY(10px);
        font-size: 24px;
        line-height: 1.5;
        margin: 0; }
        @media only screen and (max-width: 767px) {
          .portfolio .content .inner h4.title {
            font-size: 17px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .portfolio .content .inner h4.title {
            font-size: 17px; } }
        .portfolio .content .inner h4.title a {
          color: #fff; }
  .portfolio:hover {
    border-radius: 5px; }
    .portfolio:hover .content::before {
      visibility: visible;
      opacity: 1; }
    .portfolio:hover .content .inner h4.title {
      opacity: 1;
      transition: .5s ease-out .2s;
      transform: translateY(0); }

/*=========================
Portfolio With Caption 
===========================*/
.portfolio-with-caption {
  background: #ffffff;
  margin-bottom: 30px; }
  .portfolio-with-caption .thumbnail img {
    border-radius: 5px;
    width: 100%; }
  .portfolio-with-caption .content {
    padding: 32px 30px 39px; }
    @media only screen and (max-width: 767px) {
      .portfolio-with-caption .content {
        padding: 20px; } }
    .portfolio-with-caption .content .inner p.category {
      margin-bottom: 13px;
      color: #b6b7d2;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px; }
    .portfolio-with-caption .content .inner h4.title {
      margin-bottom: 0; }
      .portfolio-with-caption .content .inner h4.title a {
        font-size: 24px;
        margin: 0;
        color: #2e3280; }
        @media only screen and (max-width: 767px) {
          .portfolio-with-caption .content .inner h4.title a {
            font-size: 20px; } }
  .portfolio-with-caption:hover {
    transform: translateY(-5px); }

.portfolio-metro-grid .row {
  grid-template-columns: repeat(3, 1fr);
  display: grid; }
  .portfolio-metro-grid .row .portfolio-metor-grid .portfolio {
    margin-bottom: 0; }
  .portfolio-metro-grid .row .portfolio-metor-grid.grid-height-2 {
    grid-column: span 2; }
  .portfolio-metro-grid .row .portfolio-metor-grid.grid-height-2 {
    grid-row: span 2; }

/*========================
Portfolio Details Area 
=========================*/
.portfolio-details-area .thumbnail img {
  max-width: 540px; }
  @media only screen and (max-width: 767px) {
    .portfolio-details-area .thumbnail img {
      max-width: initial; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-details-area .thumbnail img {
      max-width: initial; } }

.portfolio-details-area .content span.category {
  color: #b6b7d2;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 3px 0 12px;
  display: inline-block; }

.portfolio-details-area .content h2.title {
  font-size: 34px;
  line-height: 1.42;
  margin-bottom: 25px; }

.portfolio-details-area .content p.description {
  margin-bottom: 22px; }

.portfolio-details-area .content .portfolio-details {
  margin-top: 38px; }
  .portfolio-details-area .content .portfolio-details table tbody tr td {
    background: none;
    padding: 17px 10px;
    text-align: left; }
    .portfolio-details-area .content .portfolio-details table tbody tr td.label {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px;
      color: #333;
      margin-bottom: 12px;
      padding-left: 0; }
    .portfolio-details-area .content .portfolio-details table tbody tr td a {
      color: #7e7e7e; }
    .portfolio-details-area .content .portfolio-details table tbody tr td:last-child {
      padding-left: 0;
      text-align: right;
      padding-right: 0; }

/* Banner Area */
.slider-fixed-height {
  height: 950px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-fixed-height {
      height: 750px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-fixed-height {
      height: auto;
      padding: 150px 0; } }
  @media only screen and (max-width: 767px) {
    .slider-fixed-height {
      height: auto;
      padding: 100px 0; } }

.slide.slider--style--1 {
  margin-top: -80px;
  padding-left: 150px;
  padding-right: 150px;
  display: flex;
  align-items: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slide.slider--style--1 {
      margin-top: 0;
      padding-left: 100px;
      padding-right: 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slide.slider--style--1 {
      margin-top: 0;
      padding-left: 50px;
      padding-right: 50px; } }
  @media only screen and (max-width: 767px) {
    .slide.slider--style--1 {
      margin-top: 0;
      padding: 40px 30px; } }
  .slide.slider--style--1 .slide-text-centent h2.title {
    font-size: 72px;
    line-height: 1.17;
    color: #333;
    margin-bottom: 30px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slide.slider--style--1 .slide-text-centent h2.title {
        font-size: 60px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slide.slider--style--1 .slide-text-centent h2.title {
        font-size: 49px; } }
    @media only screen and (max-width: 767px) {
      .slide.slider--style--1 .slide-text-centent h2.title {
        font-size: 36px; } }
    @media only screen and (max-width: 575px) {
      .slide.slider--style--1 .slide-text-centent h2.title {
        font-size: 27px;
        line-height: 38px;
        margin-bottom: 20px; } }
  .slide.slider--style--1 .slide-text-centent p.description {
    margin-bottom: 36px;
    max-width: 490px;
    line-height: 1.78;
    color: #696969;
    font-size: 18px; }

.slider--sass .slider-fixed-height {
  height: 1200px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--sass .slider-fixed-height {
      height: auto; } }
  @media only screen and (max-width: 767px) {
    .slider--sass .slider-fixed-height {
      height: auto; } }

.slider--sass .content {
  padding-top: 180px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--sass .content {
      padding-top: 40px; } }
  @media only screen and (max-width: 767px) {
    .slider--sass .content {
      padding-top: 40px; } }
  .slider--sass .content h2 {
    color: #fff;
    line-height: 1.3;
    font-weight: 300; }
  .slider--sass .content p {
    color: #fff;
    font-weight: 400;
    padding-top: 0px;
    padding-bottom: 19px;
    font-size: 18px; }

.slider--sass .thumbnail {
  padding: 95px 15px 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--sass .thumbnail {
      padding: 30px 15px 0; } }
  @media only screen and (max-width: 767px) {
    .slider--sass .thumbnail {
      padding: 30px 15px 0; } }

.slick-active .slider--sass .content {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: .5s;
  animation-delay: .5s; }

.slick-active .slider--sass .thumbnail {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-delay: .5s;
  animation-delay: .5s; }

.slider--startups {
  padding-left: 150px;
  position: relative;
  z-index: 2;
  padding-top: 193px;
  padding-bottom: 97px; }
  @media only screen and (max-width: 767px) {
    .slider--startups {
      padding-left: 30px;
      padding-top: 100px;
      padding-bottom: 100px;
      padding-right: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--startups {
      padding-left: 30px;
      padding-top: 100px;
      padding-bottom: 76px;
      padding-right: 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider--startups {
      padding-left: 30px;
      padding-top: 100px;
      padding-bottom: 200px;
      padding-right: 30px; } }
  .slider--startups .slider-content {
    width: 530px;
    max-width: 100%; }
    .slider--startups .slider-content .inner {
      margin-top: 110px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slider--startups .slider-content .inner {
          margin-top: 40px; } }
      @media only screen and (max-width: 767px) {
        .slider--startups .slider-content .inner {
          margin-top: 40px; } }
      .slider--startups .slider-content .inner h1.title {
        font-size: 56px;
        line-height: 1.29;
        color: #fff;
        margin-bottom: 15px; }
        @media only screen and (max-width: 767px) {
          .slider--startups .slider-content .inner h1.title {
            font-size: 36px; } }
      .slider--startups .slider-content .inner p.description {
        font-size: 18px;
        line-height: 1.78;
        color: #fff;
        margin-bottom: 45px; }
  .slider--startups .thumbnail {
    padding-right: 50px; }
    @media only screen and (max-width: 767px) {
      .slider--startups .thumbnail {
        padding-right: 0;
        margin-top: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--startups .thumbnail {
        padding-right: 0;
        margin-top: 30px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider--startups .thumbnail {
        padding-right: 0;
        margin-top: 30px; } }
  .slider--startups .wavify-position {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
    .slider--startups .wavify-position .service-wavify svg {
      height: 150px; }
  .slider--startups .button-group .aro-btn-second {
    margin-left: 20px; }
    .slider--startups .button-group .aro-btn-second h6 {
      padding-right: 10px; }

/* Slider App Showcase */
.slider--appshowcase {
  position: relative; }
  .slider--appshowcase .svg-separator {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
    line-height: 0;
    bottom: 0; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--appshowcase .svg-separator {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .slider--appshowcase .svg-separator {
        display: none; } }
    .slider--appshowcase .svg-separator svg {
      height: 178px;
      fill: #fff;
      width: 100%; }
  .slider--appshowcase .slider-content .inner h2.title {
    font-size: 56px;
    line-height: 1.29;
    font-weight: 300;
    color: #fff;
    margin-bottom: 15px; }
    @media only screen and (max-width: 767px) {
      .slider--appshowcase .slider-content .inner h2.title {
        font-size: 34px; } }
  .slider--appshowcase .slider-content .inner p.description {
    font-size: 18px;
    line-height: 1.78;
    color: #fff; }
  .slider--appshowcase .slider-content .thumbnail {
    padding: 95px 15px 0; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--appshowcase .slider-content .thumbnail {
        padding: 30px 15px 0; } }
    @media only screen and (max-width: 767px) {
      .slider--appshowcase .slider-content .thumbnail {
        padding: 30px 15px 0; } }
  .slider--appshowcase.fullscreen {
    height: 1292px;
    padding-top: 90px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--appshowcase.fullscreen {
        min-height: auto;
        height: auto;
        padding: 50px 0;
        padding-top: 90px; } }
    @media only screen and (max-width: 767px) {
      .slider--appshowcase.fullscreen {
        min-height: auto;
        height: auto;
        padding: 50px 0;
        padding-top: 90px; } }

/* Slider Software */
.slider--software .slider-text {
  max-width: 500px;
  margin: 0 auto;
  padding-left: 54px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--software .slider-text {
      max-width: 100%;
      padding-top: 50px; } }
  @media only screen and (max-width: 767px) {
    .slider--software .slider-text {
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 50px; } }
  .slider--software .slider-text h2.title {
    font-weight: 500;
    font-size: 56px;
    line-height: 72px;
    margin-bottom: 26px; }
    @media only screen and (max-width: 767px) {
      .slider--software .slider-text h2.title {
        font-size: 31px;
        line-height: 48px;
        margin-bottom: 16px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider--software .slider-text h2.title {
        font-size: 44px;
        line-height: 53px;
        margin-bottom: 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--software .slider-text h2.title {
        font-size: 44px;
        line-height: 53px;
        margin-bottom: 10px; } }
  .slider--software .slider-text p.description {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 56px; }
    @media only screen and (max-width: 767px) {
      .slider--software .slider-text p.description {
        margin-bottom: 26px; } }

.slider--software .slider-wrapper {
  position: relative;
  z-index: 2;
  padding-top: 100px; }
  .slider--software .slider-wrapper .bg-shape-images {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }

.slider--software.fullscreen {
  height: 970px;
  margin-bottom: -80px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider--software.fullscreen {
      height: auto;
      margin-bottom: 40px;
      min-height: auto; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--software.fullscreen {
      height: auto;
      margin-bottom: 40px;
      min-height: auto; } }
  @media only screen and (max-width: 767px) {
    .slider--software.fullscreen {
      height: auto;
      margin-bottom: 40px;
      min-height: auto; } }

/* Slider Payment */
.slider--payment {
  min-height: 1108px;
  position: relative;
  z-index: 2; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider--payment {
      min-height: 900px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--payment {
      min-height: 927px; } }
  @media only screen and (max-width: 767px) {
    .slider--payment {
      min-height: auto;
      padding-bottom: 241px; } }
  .slider--payment .slider-content {
    padding-top: 312px;
    padding-left: 140px;
    max-width: 765px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider--payment .slider-content {
        padding-top: 150px;
        padding-left: 50px;
        padding-right: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--payment .slider-content {
        padding-top: 150px;
        padding-left: 50px;
        padding-right: 30px; } }
    @media only screen and (max-width: 767px) {
      .slider--payment .slider-content {
        padding-top: 150px;
        padding-left: 30px;
        padding-right: 30px; } }
    .slider--payment .slider-content h1.title {
      font-size: 56px;
      font-weight: 500;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .slider--payment .slider-content h1.title {
          font-size: 32px;
          margin-bottom: 20px;
          line-height: 39px; } }
    .slider--payment .slider-content p.description {
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 50px; }
  .slider--payment .slider-thumbnail {
    position: absolute;
    right: -28px;
    top: 138px;
    z-index: -1; }
    @media only screen and (max-width: 767px) {
      .slider--payment .slider-thumbnail {
        display: none; } }

@media only screen and (max-width: 767px) {
  .slider--applanding .fullscreen {
    min-height: auto;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 100px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider--applanding .fullscreen {
    min-height: auto;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 100px; } }

.slide-app-landing {
  padding: 0 150px !important; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slide-app-landing {
      padding: 0 100px !important; } }
  @media only screen and (max-width: 767px) {
    .slide-app-landing {
      padding: 0 30px !important; } }
  .slide-app-landing h1.title {
    font-size: 56px;
    line-height: 1.2;
    color: #fff;
    font-weight: 500; }
    @media only screen and (max-width: 767px) {
      .slide-app-landing h1.title {
        font-size: 38px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slide-app-landing h1.title {
        font-size: 38px; } }
  .slide-app-landing h6 {
    font-size: 18px;
    margin-top: 20px;
    color: #fff;
    padding-right: 40%;
    font-weight: 400;
    line-height: 1.74; }
    @media only screen and (max-width: 767px) {
      .slide-app-landing h6 {
        padding-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slide-app-landing h6 {
        padding-right: 0; } }
  .slide-app-landing button.ar-button {
    margin-top: 30px; }

.slider--ebook {
  height: 970px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider--ebook {
      height: auto;
      padding: 100px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--ebook {
      height: auto;
      padding: 100px 0;
      margin-top: 20px; } }
  @media only screen and (max-width: 767px) {
    .slider--ebook {
      height: auto;
      padding: 100px 0;
      margin-top: 20px; } }
  .slider--ebook .content {
    width: 490px;
    padding-left: 250px;
    box-sizing: content-box; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider--ebook .content {
        width: 500px;
        padding-left: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--ebook .content {
        width: 500px;
        padding-left: 50px; } }
    @media only screen and (max-width: 767px) {
      .slider--ebook .content {
        width: 500px;
        padding-left: 50px; } }
    @media only screen and (max-width: 575px) {
      .slider--ebook .content {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px; } }
    .slider--ebook .content .inner > span {
      color: #6d70a6;
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 40px;
      letter-spacing: 1.2px;
      text-transform: uppercase; }
    .slider--ebook .content .inner h2.title {
      font-weight: 500;
      color: #2e3280;
      line-height: 1.3;
      font-size: 56px;
      margin-bottom: 50px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .slider--ebook .content .inner h2.title {
          font-size: 41px;
          margin-bottom: 30px;
          margin-top: 15px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slider--ebook .content .inner h2.title {
          font-size: 42px;
          margin-bottom: 20px; } }
      @media only screen and (max-width: 767px) {
        .slider--ebook .content .inner h2.title {
          font-size: 42px;
          margin-bottom: 20px; } }
      @media only screen and (max-width: 575px) {
        .slider--ebook .content .inner h2.title {
          font-size: 33px; } }
  .slider--ebook .thumbnail {
    padding-right: 100px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider--ebook .thumbnail {
        padding-right: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--ebook .thumbnail {
        padding-right: 30px; } }
    @media only screen and (max-width: 767px) {
      .slider--ebook .thumbnail {
        padding-right: 30px; } }

/* Slider Clinic  */
.slider--clinic {
  position: relative; }
  .slider--clinic .clinic-hero-wrapper {
    overflow: hidden;
    padding: 205px 0 110px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(80%, #eceefa)); }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--clinic .clinic-hero-wrapper {
        padding: 100px 0 110px; } }
    @media only screen and (max-width: 767px) {
      .slider--clinic .clinic-hero-wrapper {
        padding: 100px 0 110px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider--clinic .clinic-hero-wrapper {
        padding: 100px 0 110px; } }
    .slider--clinic .clinic-hero-wrapper .content .inner h1 {
      line-height: 1.29;
      font-weight: 700;
      margin-bottom: 10px;
      font-size: 56px; }
      @media only screen and (max-width: 767px) {
        .slider--clinic .clinic-hero-wrapper .content .inner h1 {
          font-size: 38px; } }
    .slider--clinic .clinic-hero-wrapper .content .inner p {
      line-height: 1.78;
      color: #6d70a6;
      font-size: 18px; }
    .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper {
      display: flex;
      justify-content: center;
      background: #fff;
      box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
      padding: 12px 10px;
      border-radius: 8px;
      display: -webkit-box;
      -webkit-box-flex: 1;
      flex-grow: 1;
      flex-basis: 0;
      width: 970px;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 50px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper {
          width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper {
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper {
          width: 100%;
          display: block; } }
      .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper .input-box {
        padding: 10px;
        position: relative;
        -webkit-box-flex: 1;
        flex-grow: 1; }
        .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper .input-box input {
          background-color: #f8f8f8;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0;
          background: #f6f5f9;
          border-color: #f6f5f9;
          color: inherit;
          width: 100%;
          outline: none;
          border: 1px solid #eee;
          border-radius: 5px;
          padding: 3px 20px;
          padding-left: 45px;
          height: 56px;
          width: 370px; }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper .input-box input {
              width: 350px; } }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper .input-box input {
              width: 233px; } }
          @media only screen and (max-width: 767px) {
            .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper .input-box input {
              width: 100%; } }
        .slider--clinic .clinic-hero-wrapper .content .inner .form-wrapper .input-box i {
          left: 28px;
          right: auto;
          top: 32px;
          z-index: 2;
          font-size: 15px;
          position: absolute; }
    .slider--clinic .clinic-hero-wrapper .clinic-slide-bottom {
      margin-bottom: -115px;
      position: relative;
      padding-top: 90px; }
      @media only screen and (max-width: 767px) {
        .slider--clinic .clinic-hero-wrapper .clinic-slide-bottom {
          display: none; } }
      .slider--clinic .clinic-hero-wrapper .clinic-slide-bottom .svg-bottom-image {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
        line-height: 0;
        bottom: 0; }
        .slider--clinic .clinic-hero-wrapper .clinic-slide-bottom .svg-bottom-image svg {
          height: 220px;
          fill: #fff;
          z-index: 0;
          width: 100%;
          overflow: hidden; }
      .slider--clinic .clinic-hero-wrapper .clinic-slide-bottom .clinic-hero-image img {
        position: relative;
        z-index: 4;
        max-width: 100%;
        height: auto; }

.slider--digitalstore {
  padding: 200px 0;
  padding-bottom: 120px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider--digitalstore {
      padding: 100px 0;
      padding-top: 170px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--digitalstore {
      padding: 100px 0;
      padding-top: 150px; } }
  @media only screen and (max-width: 767px) {
    .slider--digitalstore {
      padding: 100px 0;
      padding-top: 100px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider--digitalstore .content {
      padding-top: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--digitalstore .content {
      padding-top: 0; } }
  @media only screen and (max-width: 767px) {
    .slider--digitalstore .content {
      padding-top: 0; } }
  .slider--digitalstore .content .inner h3 {
    line-height: 1.29;
    color: #fff;
    font-size: 50px;
    margin-bottom: 35px; }
    @media only screen and (max-width: 767px) {
      .slider--digitalstore .content .inner h3 {
        font-size: 31px;
        font-weight: 700;
        margin-bottom: 22px; }
        .slider--digitalstore .content .inner h3 br {
          display: none; } }
  .slider--digitalstore .content .inner .slide-image-wrapepr {
    justify-content: center; }
    .slider--digitalstore .content .inner .slide-image-wrapepr .single-box {
      padding: 10px; }
      .slider--digitalstore .content .inner .slide-image-wrapepr .single-box p {
        line-height: 1.78;
        color: #fff;
        font-size: 18px; }
  .slider--digitalstore .content .thumbnail {
    margin-top: 95px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider--digitalstore .content .thumbnail {
        margin-top: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider--digitalstore .content .thumbnail {
        margin-top: 20px; } }
    @media only screen and (max-width: 767px) {
      .slider--digitalstore .content .thumbnail {
        margin-top: 20px; } }

.slider--digitalagency {
  padding: 230px 0; }
  @media only screen and (max-width: 767px) {
    .slider--digitalagency {
      padding: 100px 0;
      margin-top: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider--digitalagency {
      padding: 100px 0;
      margin-top: 20px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider--digitalagency {
      padding: 150px 0;
      padding-top: 200px; } }
  .slider--digitalagency .wrapper .inner h2 {
    color: #fff;
    font-size: 27px;
    margin-bottom: 0; }
  .slider--digitalagency .wrapper .inner h1 {
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .slider--digitalagency .wrapper .inner h1 {
        font-size: 31px;
        font-weight: 700;
        margin-bottom: 22px; }
        .slider--digitalagency .wrapper .inner h1 br {
          display: none; } }

/**************************************
    Template Styles
***************************************/
/*=========================
    Responsive App Area
==========================*/
.responsive-app-area .responsive-app-wrapper .content {
  width: 600px;
  max-width: 100%; }
  @media only screen and (max-width: 767px) {
    .responsive-app-area .responsive-app-wrapper .content {
      max-width: 100%;
      padding: 0 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .responsive-app-area .responsive-app-wrapper .content {
      width: auto;
      max-width: 100%;
      padding: 0 12px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .responsive-app-area .responsive-app-wrapper .content {
      width: auto;
      max-width: 100%;
      padding: 0 12px; } }
  .responsive-app-area .responsive-app-wrapper .content h3 {
    font-size: 48px;
    line-height: 1.34;
    font-weight: 400;
    margin-bottom: 20px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .responsive-app-area .responsive-app-wrapper .content h3 {
        font-size: 43px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .responsive-app-area .responsive-app-wrapper .content h3 {
        font-size: 34px; } }
    @media only screen and (max-width: 767px) {
      .responsive-app-area .responsive-app-wrapper .content h3 {
        font-size: 30px; } }
  .responsive-app-area .responsive-app-wrapper .content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.78;
    margin-bottom: 40px; }
  .responsive-app-area .responsive-app-wrapper .content .ar-button {
    margin-bottom: 50px; }
  .responsive-app-area .responsive-app-wrapper .content h6.next-button {
    color: #6d70a6;
    font-size: 18px;
    line-height: 1.23; }
    .responsive-app-area .responsive-app-wrapper .content h6.next-button a {
      color: #38cb89;
      display: inline-block;
      margin-left: 10px; }

/*===========================
Header Area 
============================*/
.header.header--transparent {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%; }

.header-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 150px;
  padding-right: 150px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .header-wrapper {
      padding-left: 50px;
      padding-right: 50px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-wrapper {
      padding-left: 50px;
      padding-right: 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-wrapper {
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 30px; } }
  @media only screen and (max-width: 767px) {
    .header-wrapper {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 30px; } }
  .header-wrapper .branding {
    flex-shrink: 0;
    flex-basis: 20%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-wrapper .branding {
        flex-basis: 40%; } }
    @media only screen and (max-width: 767px) {
      .header-wrapper .branding {
        flex-basis: 50%; } }
    .header-wrapper .branding .logo a img {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 0px; }
      @media only screen and (max-width: 767px) {
        .header-wrapper .branding .logo a img {
          width: 130px; } }
  .header-wrapper .page-navigation {
    flex-grow: 1; }
  .header-wrapper .header-right {
    flex-shrink: 0;
    flex-basis: 20%;
    display: flex;
    margin: 0 -12px;
    align-items: center;
    justify-content: flex-end; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-wrapper .header-right {
        flex-basis: 60%; } }
    @media only screen and (max-width: 767px) {
      .header-wrapper .header-right {
        flex-basis: 50%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-wrapper button.ar-button {
      padding: 0 28px; } }

/*=====================
    Header Right 
=======================*/
.header-right .header-links {
  padding: 0 12px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .header-right .header-links {
      display: none !important; } }
  .header-right .header-links .links {
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #38cb89;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .header-right .header-links .links::after {
      content: '';
      width: 0;
      height: 1px;
      bottom: 0;
      position: absolute;
      left: auto;
      right: 0;
      z-index: -1;
      transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      background: currentColor;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .header-right .header-links .links:hover {
      color: #38cb89; }
      .header-right .header-links .links:hover::after {
        width: 100%;
        left: 0;
        right: auto; }

.header-right .header-button {
  padding: 0 12px; }

/* Mobile Menu */
.popup-mobile-menu-wrapper .popup-menu {
  visibility: visible;
  opacity: 1; }
  .popup-mobile-menu-wrapper .popup-menu .inner {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }

.popup-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 99999; }
  .popup-menu .inner {
    background: #000000;
    position: relative;
    width: 400px;
    max-width: 80%;
    height: 100%;
    text-align: left;
    -webkit-transition: all .3s;
    transition: all .3s;
    overflow-y: auto;
    padding-top: 85px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    cursor: default; }

/*---------------------
    Main Menu  
----------------------*/
.mainmenu li.has-dropdown ul.submenu {
  padding: 18px 0 22px;
  min-width: 240px !important;
  border: 0;
  border-radius: 5px;
  position: absolute;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border: none;
  border-bottom: 3px solid #5945e6;
  margin-top: -2px;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: -9;
  overflow: hidden;
  overflow-y: auto;
  max-height: 522px; }
  .mainmenu li.has-dropdown ul.submenu li {
    display: block; }
    .mainmenu li.has-dropdown ul.submenu li a {
      padding: 9px 30px;
      color: #ababab;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.47;
      display: block;
      text-transform: capitalize;
      transition: 0.3s; }
    .mainmenu li.has-dropdown ul.submenu li:hover a {
      color: #5E61E7; }

.mainmenu li.has-dropdown:hover ul.submenu {
  opacity: 1;
  visibility: visible;
  z-index: 999; }

.page-template-boxed-with-corner .header-wrapper {
  padding-left: 110px;
  padding-right: 110px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .page-template-boxed-with-corner .header-wrapper {
      padding-left: 30px;
      padding-right: 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .page-template-boxed-with-corner .header-wrapper {
      padding-left: 30px;
      padding-right: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .page-template-boxed-with-corner .header-wrapper {
      padding-left: 30px;
      padding-right: 30px; } }
  @media only screen and (max-width: 767px) {
    .page-template-boxed-with-corner .header-wrapper {
      padding-left: 30px;
      padding-right: 30px; } }

/*------------------------------------------
    MObile Menu Responsive 
-------------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-wrapper .page-navigation {
    position: absolute; }
    .header-wrapper .page-navigation .mainmenu-nav {
      width: 60vw;
      height: 100vh;
      position: fixed;
      top: 0;
      right: -61vw;
      z-index: 99;
      padding: 55px;
      background: #fff;
      -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
      display: block;
      overflow-y: auto; }
      .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu {
        text-align: left; }
        .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li {
          display: block; }
          .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li > a {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 0;
            padding-right: 0;
            display: block; }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li > a span::after {
              display: none; }
          .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown > a {
            position: relative; }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown > a::after {
              width: 0;
              height: 0;
              border-color: #1f1f25 transparent transparent;
              border-style: solid;
              border-width: 7px 7px 0;
              position: absolute;
              content: "";
              right: 0;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown > a.open::after {
              width: 0;
              height: 0;
              border-color: transparent transparent #5E61E7;
              border-style: solid;
              border-width: 0 7px 7px; }
          .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown ul.submenu {
            position: relative;
            height: 0;
            -webkit-transition: .3s;
            transition: .3s;
            padding: 0;
            opacity: 0;
            visibility: hidden;
            background: transparent;
            box-shadow: inherit;
            border-bottom: 0 none;
            left: 14px; }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown ul.submenu li a {
              padding-top: 8px;
              padding-bottom: 8px; }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown ul.submenu.active {
              height: 100%;
              padding: 12px 0;
              opacity: 1;
              visibility: visible; } }

@media only screen and (max-width: 767px) {
  .header-wrapper .page-navigation {
    position: absolute; }
    .header-wrapper .page-navigation .mainmenu-nav {
      width: 60vw;
      height: 100vh;
      position: fixed;
      top: 0;
      right: -61vw;
      z-index: 99;
      padding: 55px;
      background: #fff;
      -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
      display: block;
      overflow-y: auto; } }
    @media only screen and (max-width: 767px) and (max-width: 767px) {
      .header-wrapper .page-navigation .mainmenu-nav {
        width: 92vw;
        right: -92vw; } }

@media only screen and (max-width: 767px) {
      .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu {
        text-align: left; }
        .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li {
          display: block; }
          .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li > a {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 0;
            padding-right: 0;
            display: block; }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li > a span::after {
              display: none; }
          .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown > a {
            position: relative; }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown > a::after {
              width: 0;
              height: 0;
              border-color: #1f1f25 transparent transparent;
              border-style: solid;
              border-width: 7px 7px 0;
              position: absolute;
              content: "";
              right: 0;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown > a.open::after {
              width: 0;
              height: 0;
              border-color: transparent transparent #5E61E7;
              border-style: solid;
              border-width: 0 7px 7px; }
          .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown ul.submenu {
            position: relative;
            height: 0;
            -webkit-transition: .3s;
            transition: .3s;
            padding: 0;
            opacity: 0;
            visibility: hidden;
            background: transparent;
            box-shadow: inherit;
            border-bottom: 0 none;
            left: 14px; }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown ul.submenu li a {
              padding-top: 8px;
              padding-bottom: 8px; }
            .header-wrapper .page-navigation .mainmenu-nav ul.mainmenu li.has-dropdown ul.submenu.active {
              height: 100%;
              padding: 12px 0;
              opacity: 1;
              visibility: visible; } }

.header-wrapper .humberger-menu span {
  font-size: 28px;
  color: #000;
  cursor: pointer; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-wrapper .close-menu {
    position: fixed;
    top: 12px;
    opacity: 0;
    visibility: hidden;
    right: 29px;
    z-index: -10; }
    .header-wrapper .close-menu span {
      color: #000;
      font-size: 30px;
      cursor: pointer; } }

@media only screen and (max-width: 767px) {
  .header-wrapper .close-menu {
    position: fixed;
    top: 12px;
    opacity: 0;
    visibility: hidden;
    right: 29px;
    z-index: -10; }
    .header-wrapper .close-menu span {
      color: #000;
      font-size: 30px;
      cursor: pointer; } }

@media only screen and (max-width: 767px) {
  .header-wrapper .close-menu {
    position: fixed;
    top: 12px;
    opacity: 0;
    visibility: hidden;
    right: 29px;
    z-index: -10; }
    .header-wrapper .close-menu span {
      color: #000;
      font-size: 30px;
      cursor: pointer; } }

.header-wrapper.menu-open .page-navigation .mainmenu-nav {
  right: 0; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-wrapper.menu-open .close-menu {
    opacity: 1;
    visibility: visible;
    z-index: 9999; } }

@media only screen and (max-width: 767px) {
  .header-wrapper.menu-open .close-menu {
    opacity: 1;
    visibility: visible;
    z-index: 9999; } }

.color--light .header-wrapper .humberger-menu span {
  color: #ffffff; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .color--light .mainmenu-nav .mainmenu li a {
    color: #1f1f25; }
  .mainmenu-nav .mainmenu > li:hover > a {
    color: #5E61E7 !important; } }

@media only screen and (max-width: 767px) {
  .color--light .mainmenu-nav .mainmenu li a {
    color: #1f1f25; }
  .mainmenu-nav .mainmenu > li:hover > a {
    color: #5E61E7 !important; } }

/*======================
    Header Nav Bar 
========================*/
.mainmenu-nav .mainmenu {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }
  .mainmenu-nav .mainmenu li {
    display: inline-block;
    position: relative; }
    .mainmenu-nav .mainmenu li a {
      padding-top: 52px;
      padding-bottom: 52px;
      padding-left: 24px;
      padding-right: 24px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.75px;
      line-height: 1.18;
      text-transform: uppercase;
      display: inline-block; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .mainmenu-nav .mainmenu li a {
          padding-left: 18px;
          padding-right: 18px; } }
      .mainmenu-nav .mainmenu li a span {
        position: relative;
        overflow: hidden;
        z-index: 1; }
        .mainmenu-nav .mainmenu li a span::after {
          content: '';
          width: 0;
          height: 1px;
          bottom: 0;
          position: absolute;
          left: auto;
          right: 0;
          z-index: -1;
          transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          background: currentColor; }
    .mainmenu-nav .mainmenu li:hover a {
      color: #ffffff; }
      .mainmenu-nav .mainmenu li:hover a span::after {
        width: 100%;
        left: 0;
        right: auto; }

.header.color--black .mainmenu-nav .mainmenu > li > a {
  color: #ababab; }

.header.color--black .mainmenu-nav .mainmenu > li:hover > a {
  color: #5E61E7; }

/*========================
    Download App Area 
========================== */
.download-app-wrapper {
  -webkit-box-shadow: 0 15px 40px rgba(89, 69, 230, 0.05);
  box-shadow: 0 15px 40px rgba(89, 69, 230, 0.05);
  background: url(/assets/images/mokup/aeroland-macbook-02.png) 55% 100% no-repeat, linear-gradient(-138deg, #5E60E7 0, #9C7AF2 50%, #5E60E7 100%);
  border-radius: 5px; }
  .download-app-wrapper .doenload-appinner {
    padding-left: 78px; }
    @media only screen and (max-width: 767px) {
      .download-app-wrapper .doenload-appinner {
        padding-left: 30px; } }
    .download-app-wrapper .doenload-appinner .content h3 {
      line-height: 1.88;
      color: #fff;
      font-size: 34px;
      font-weight: 600;
      letter-spacing: 0em;
      margin-bottom: 4px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .download-app-wrapper .doenload-appinner .content h3 {
          line-height: 36px;
          font-size: 25px; } }
      @media only screen and (max-width: 767px) {
        .download-app-wrapper .doenload-appinner .content h3 {
          line-height: 36px;
          font-size: 25px; } }
    .download-app-wrapper .doenload-appinner .content h5 {
      line-height: 1.78;
      color: #fff;
      font-size: 18px; }
    .download-app-wrapper .doenload-appinner .content a.download-btn {
      margin-top: 20px; }
  .download-app-wrapper .thumbnail {
    margin-top: -50px;
    margin-bottom: -110px;
    margin-right: -77px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .download-app-wrapper .thumbnail {
        margin-right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .download-app-wrapper .thumbnail {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0; } }
    @media only screen and (max-width: 767px) {
      .download-app-wrapper .thumbnail {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0; } }

/*======================
    Sass Page 
========================*/
/*=======================
    Sass Digital App 
=========================*/
.digital-app-button {
  margin: -10px; }
  .digital-app-button a {
    padding: 10px;
    display: inline-block; }
    .digital-app-button a:hover {
      transform: translateY(-3px); }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .marketing-wrapper {
    padding: 0 20px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .marketing-wrapper {
    padding: 0 20px; } }

@media only screen and (max-width: 767px) {
  .marketing-wrapper {
    padding: 0 20px; } }

/*======================
    Seo Marketing 
========================*/
.seo-content {
  width: 600px;
  max-width: 100%;
  float: right; }
  .seo-content p {
    max-width: 370px;
    color: #696969;
    margin-top: 28px; }
  .seo-content .inner ul.list li {
    margin: 10px 0; }
    .seo-content .inner ul.list li svg {
      color: #38cb89; }
    .seo-content .inner ul.list li span {
      font-size: 14px;
      line-height: inherit;
      font-weight: 400;
      color: #696969;
      margin-left: 11px; }

.meeting-seo-wrapper {
  position: relative; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .meeting-seo-wrapper {
      padding: 120px 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .meeting-seo-wrapper {
      padding: 80px 50px; } }
  @media only screen and (max-width: 767px) {
    .meeting-seo-wrapper {
      padding: 60px 30px; } }
  .meeting-seo-wrapper .thumbnail {
    margin-top: -90px;
    margin-left: -116px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .meeting-seo-wrapper .thumbnail {
        margin-top: 0px;
        margin-left: -10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .meeting-seo-wrapper .thumbnail {
        margin-top: 0px;
        margin-left: -10px; } }
    @media only screen and (max-width: 767px) {
      .meeting-seo-wrapper .thumbnail {
        margin-top: 0px;
        margin-left: -10px; } }

/*============================
    Picture Branding Area 
=============================*/
.branding-service .slide-content .slide-info {
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 570px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  padding: 54px 50px 52px;
  transition-delay: .5s;
  transition-delay: .5s;
  opacity: 0;
  transform: translateX(100%); }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .branding-service .slide-content .slide-info {
      margin: 0 auto;
      margin-bottom: 50px; } }
  @media only screen and (max-width: 767px) {
    .branding-service .slide-content .slide-info {
      padding: 30px 30px 30px;
      margin: 0 auto;
      margin-bottom: 50px; } }
  .branding-service .slide-content .slide-info h5 {
    font-size: 34px;
    line-height: 1.65;
    font-weight: 400;
    color: #333;
    margin-bottom: 11px;
    padding-right: 13px; }
  .branding-service .slide-content .slide-info p {
    font-size: 18px;
    line-height: 1.78; }

.branding-service .thumbnail {
  margin-right: 20px;
  text-align: right;
  font-size: 0;
  overflow: hidden; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .branding-service .thumbnail {
      margin-right: 0; } }
  @media only screen and (max-width: 767px) {
    .branding-service .thumbnail {
      margin-right: 0; } }
  .branding-service .thumbnail img {
    transition-delay: .5s;
    opacity: 0;
    transform: translateX(-100%);
    display: inline-block; }

.slick-active.slick-current .slide-content .slide-info {
  opacity: 1;
  transform: translateY(0); }

.slick-active.slick-current .thumbnail img {
  opacity: 1;
  transform: translateY(0); }

/*==========================
    App Showcase Area 
============================*/
.curve-wrapper {
  position: relative; }
  .curve-wrapper .center_curve {
    transform: rotate(-180deg);
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
    line-height: 0; }
    .curve-wrapper .center_curve svg {
      height: 130px;
      fill: #f8f8f8;
      width: 100%; }
    .curve-wrapper .center_curve.bottom {
      bottom: 0; }
    .curve-wrapper .center_curve.top {
      transform: rotate(0);
      top: 0; }
      .curve-wrapper .center_curve.top svg {
        height: 120px;
        fill: #e3e9ff;
        width: 100%; }
  .curve-wrapper .space-height--1 {
    height: 122px; }
  .curve-wrapper .space-height--2 {
    height: 47px; }

.app-showcase-wrapper {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F8F8F8), color-stop(50%, #ECEEFA), color-stop(100%, #E3E9FF));
  background: -moz-linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%);
  background: -webkit-linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%);
  background: -o-linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%);
  background: -ms-linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%);
  background: linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%); }

.showcase.showcase--1 .thumbnail img {
  -moz-border-radius: 53px;
  -webkit-border-radius: 53px;
  border-radius: 53px;
  -moz-box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  -webkit-box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12); }

.showcase.showcase--3 .thumbnail img {
  margin-left: -113px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .showcase.showcase--3 .thumbnail img {
      margin-left: 0; } }
  @media only screen and (max-width: 767px) {
    .showcase.showcase--3 .thumbnail img {
      margin-left: 0; } }

.showcase.showcase--2 .list {
  margin-top: 32px; }

.showcase .showcase-inner span.subtitle {
  color: #ee7455;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.34;
  margin-bottom: 24px;
  display: block; }

.showcase .showcase-inner h3.title {
  font-size: 34px;
  line-height: 1.42;
  font-weight: 400;
  margin-bottom: 14px; }

.showcase .showcase-inner p {
  font-size: 18px;
  line-height: 1.78;
  margin-bottom: 42px; }

.app-download-inner .video-popup:hover {
  transform: inherit; }

/*=========================
    Showcase Download 
==========================*/
.appshowcase-download .thumbnail {
  margin-right: 60px;
  text-align: right; }

.appshowcase-download .app-download-inner {
  width: 600px;
  max-width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .appshowcase-download .app-download-inner {
      width: 100%;
      max-width: 100%;
      padding: 0 20px; } }
  @media only screen and (max-width: 767px) {
    .appshowcase-download .app-download-inner {
      width: 100%;
      max-width: 100%;
      padding: 0 20px;
      text-align: center; } }
  .appshowcase-download .app-download-inner p {
    font-size: 18px;
    line-height: 1.78;
    padding-right: 15%;
    margin-bottom: 40px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .appshowcase-download .app-download-inner p {
        padding-right: 0; } }
    @media only screen and (max-width: 767px) {
      .appshowcase-download .app-download-inner p {
        padding-right: 0; } }

/*=====================
    Software Area 
=======================*/
.software-service-wrapper .thumbnail {
  text-align: right; }

.software-service-wrapper .service-wrapper {
  width: 600px;
  max-width: 100%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .software-service-wrapper .service-wrapper {
      width: 100%;
      max-width: 100%;
      padding: 0 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .software-service-wrapper .service-wrapper {
      width: 100%;
      max-width: 100%;
      padding: 0 20px; } }
  @media only screen and (max-width: 767px) {
    .software-service-wrapper .service-wrapper {
      width: 100%;
      max-width: 100%;
      padding: 0 20px; } }

.software-pricing-table .thumbnail {
  text-align: right; }

.software-pricing-table .software-pricing-inner {
  width: 600px;
  max-width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .software-pricing-table .software-pricing-inner {
      max-width: 100%;
      padding: 0 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .software-pricing-table .software-pricing-inner {
      max-width: 100%;
      padding: 0 30px; } }
  @media only screen and (max-width: 767px) {
    .software-pricing-table .software-pricing-inner {
      max-width: 100%;
      padding: 0 30px; } }

.software-service-area .video-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100% !important; }
  .software-service-area .video-popup svg {
    color: #38cb89 !important; }

/*=====================
    Start Ups Area 
=======================*/
.startups-bg-images {
  background-image: url(/assets/images/bg/bg-image-6.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center; }

/*=====================
    Payment Area 
=======================*/
.payment-sestem-bg-image {
  background-image: url(/assets/images/bg/bg-image-8.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top 80px center;
  position: relative; }
  .payment-sestem-bg-image .for-bottom-shape {
    position: relative;
    padding-bottom: 200px; }
    .payment-sestem-bg-image .for-bottom-shape .vc_row-separator {
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 1;
      line-height: 0; }
      .payment-sestem-bg-image .for-bottom-shape .vc_row-separator.tilt_left.bottom {
        bottom: 0; }
      .payment-sestem-bg-image .for-bottom-shape .vc_row-separator svg {
        height: 350px;
        fill: transparent;
        width: 100%; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .payment-sestem-bg-image .for-bottom-shape .vc_row-separator svg {
            height: 252px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .payment-sestem-bg-image .for-bottom-shape .vc_row-separator svg {
            height: 252px; } }
        @media only screen and (max-width: 767px) {
          .payment-sestem-bg-image .for-bottom-shape .vc_row-separator svg {
            height: 250px; } }

.payment-heading {
  position: relative;
  font-size: 34px;
  line-height: 1.42;
  display: inline-block; }
  .payment-heading sup {
    position: absolute;
    top: 15px;
    transform: translateY(-50%);
    font-size: 138px;
    font-weight: 700;
    line-height: 1;
    color: #f3eff8;
    z-index: -1;
    left: -55px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .payment-heading sup {
        font-size: 105px;
        left: 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .payment-heading sup {
        left: 0;
        font-size: 105px; } }
    @media only screen and (max-width: 767px) {
      .payment-heading sup {
        font-size: 60px;
        left: 0; } }
  .payment-heading.sup-right sup {
    right: -25px;
    left: auto; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .payment-heading.sup-right sup {
        right: 0;
        font-size: 105px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .payment-heading.sup-right sup {
        right: 0;
        font-size: 105px; } }
    @media only screen and (max-width: 767px) {
      .payment-heading.sup-right sup {
        right: 0;
        font-size: 60px; } }

.payment-service-inner {
  position: relative;
  z-index: 2; }
  .payment-service-inner .vc_row-separator {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
    line-height: 0; }
    .payment-service-inner .vc_row-separator.tilt_left svg {
      fill: transparent;
      width: 100%;
      height: 520px; }
    .payment-service-inner .vc_row-separator.bottom {
      bottom: 0; }

.payment-icon-service-area .online-credit-area {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5E60E7), color-stop(100%, #9C7AF2));
  background: -moz-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%);
  background: -webkit-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%);
  background: -o-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%);
  background: -ms-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%);
  background: linear-gradient(-259deg, #5E60E7 0, #9C7AF2 100%);
  position: relative;
  z-index: 2;
  padding-top: 60px; }
  .payment-icon-service-area .online-credit-area .vc_row-separator {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
    line-height: 0; }
    .payment-icon-service-area .online-credit-area .vc_row-separator svg {
      height: 520px;
      fill: transparent;
      width: 100%; }
    .payment-icon-service-area .online-credit-area .vc_row-separator.top {
      border: 0;
      transform: scale(-1, -1); }

.payment-icon-service-area .section-separator .separator--1 {
  height: 48px; }

/*======================
    Effortless Area 
========================*/
.effortless-area {
  position: relative;
  z-index: 2; }
  .effortless-area .thumbnail {
    text-align: left; }
  .effortless-area .effortless-content .inner .space--1 {
    height: 200px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .effortless-area .effortless-content .inner .space--1 {
        height: 30px; } }
    @media only screen and (max-width: 767px) {
      .effortless-area .effortless-content .inner .space--1 {
        height: 30px; } }
  .effortless-area .effortless-content .inner h2 {
    max-width: 370px;
    position: relative;
    font-size: 34px;
    line-height: 1.42;
    display: inline-block;
    margin-bottom: 32px; }
    .effortless-area .effortless-content .inner h2 sup {
      right: -25px;
      position: absolute;
      top: 15px;
      transform: translateY(-50%);
      font-size: 138px;
      font-weight: 700;
      line-height: 1;
      color: #f3eff8;
      z-index: -1; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .effortless-area .effortless-content .inner h2 sup {
          right: 0;
          font-size: 60px; } }
      @media only screen and (max-width: 767px) {
        .effortless-area .effortless-content .inner h2 sup {
          right: 0;
          font-size: 60px; } }
  .effortless-area .effortless-content .inner p {
    font-size: 18px;
    line-height: 1.78;
    margin-bottom: 42px; }

/*========================
    Pricing Table Area 
=========================*/
.pricing-bg-svg {
  position: relative; }
  .pricing-bg-svg .vc_row-separator {
    transform: scale(-1, -1); }
    .pricing-bg-svg .vc_row-separator svg {
      height: 350px;
      fill: transparent;
      width: 100%; }
    .pricing-bg-svg .vc_row-separator.tilt_right {
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 1;
      line-height: 0; }
      .pricing-bg-svg .vc_row-separator.tilt_right.top {
        top: 0; }

.payment-pricig-table .pricing-bg-shape {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #8481EC), color-stop(100%, #A990F2));
  background: -moz-linear-gradient(-259deg, #8481EC 0, #A990F2 100%);
  background: -webkit-linear-gradient(-259deg, #8481EC 0, #A990F2 100%);
  background: -o-linear-gradient(-259deg, #8481EC 0, #A990F2 100%);
  background: -ms-linear-gradient(-259deg, #8481EC 0, #A990F2 100%);
  background: linear-gradient(-259deg, #8481EC 0, #A990F2 100%); }

/*======================
    App Landing 
=======================*/
.app-feature .icon {
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border-radius: 40px;
  font-size: 34px;
  color: #fff;
  background-image: linear-gradient(-221deg, #e17643 0%, #f0a86b 100%);
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  margin-bottom: 52px; }
  @media only screen and (max-width: 767px) {
    .app-feature .icon {
      width: 80px;
      height: 80px;
      line-height: 80px;
      margin-bottom: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-feature .icon {
      width: 80px;
      height: 80px;
      line-height: 80px;
      margin-bottom: 20px; } }

.app-feature .content h4.title {
  font-size: 24px;
  margin-bottom: 14px;
  color: #fff; }

.app-feature .content p {
  color: rgba(255, 255, 255, 0.7); }

/*=========================
All Feature Area 
===========================*/
.all-feature-area .thumbnail {
  text-align: right; }

.all-feature-area .content {
  padding-left: 100px;
  width: 700px;
  max-width: 100%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .all-feature-area .content {
      padding-left: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .all-feature-area .content {
      padding-left: 30px; } }
  @media only screen and (max-width: 767px) {
    .all-feature-area .content {
      padding-left: 30px; } }
  .all-feature-area .content h2 {
    margin-bottom: 19px; }
  .all-feature-area .content p {
    font-size: 18px;
    line-height: 1.78;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 39px; }
  .all-feature-area .content .ar-button {
    margin-bottom: 49px; }

/*=====================
    Screenshot Area 
=======================*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .screenshot-top {
    padding-left: 100px;
    padding-right: 100px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .screenshot-top {
    padding-left: 50px;
    padding-right: 50px; } }

@media only screen and (max-width: 767px) {
  .screenshot-top {
    padding-left: 30px;
    padding-right: 30px; } }

.screenshot-top .app-screens-inner {
  text-align: center; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .screenshot-top .app-screens-inner {
      text-align: left;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .screenshot-top .app-screens-inner {
      text-align: left;
      margin-bottom: 20px; } }
  .screenshot-top .app-screens-inner h2 {
    margin-bottom: 0;
    line-height: 1; }
  .screenshot-top .app-screens-inner .screen {
    font-size: 15px;
    line-height: 2;
    letter-spacing: 1em;
    font-weight: 500;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7); }

.screenshot-top .applist .list-style--1 li {
  color: #ffffff; }

.screenshot-slide-wrapper {
  margin: 0 -15px; }
  .screenshot-slide-wrapper .screenshot {
    padding: 0 15px; }

/*======================
    Digital Agency 
=======================*/
.working-process-area .work-proces-bg {
  background-image: url(/assets/images/bg/work.png);
  background-repeat: no-repeat;
  background-position: 42% center; }

.working-process-area .image-wrapper {
  text-align: right; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .working-process-area .image-wrapper {
      text-align: left; } }
  @media only screen and (max-width: 767px) {
    .working-process-area .image-wrapper {
      text-align: left; } }

.working-process-area .thumbnail-inner {
  display: inline-block; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .working-process-area .thumbnail-inner {
      display: block; } }
  @media only screen and (max-width: 767px) {
    .working-process-area .thumbnail-inner {
      display: block; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .working-process-area .thumbnail-inner .thumbnail {
      margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .working-process-area .thumbnail-inner .thumbnail {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .working-process-area .thumbnail-inner .thumbnail {
      display: block;
      text-align: left; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .working-process-area .thumbnail-inner .thumbnail {
      display: block;
      text-align: left; } }
  @media only screen and (max-width: 767px) {
    .working-process-area .thumbnail-inner .thumbnail {
      display: block;
      text-align: left; } }
  .working-process-area .thumbnail-inner .thumbnail img {
    border-radius: 5px;
    overflow: hidden;
    display: inline-block; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .working-process-area .thumbnail-inner .thumbnail img {
        width: 100%; } }
    @media only screen and (max-width: 767px) {
      .working-process-area .thumbnail-inner .thumbnail img {
        width: 100%; } }

.working-process-area .work-process-inner {
  padding-left: 115px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .working-process-area .work-process-inner {
      padding-left: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .working-process-area .work-process-inner {
      padding-left: 30px; } }
  @media only screen and (max-width: 767px) {
    .working-process-area .work-process-inner {
      padding-left: 30px; } }

.cooperation-bg-images {
  background-image: url(/assets/images/bg/digital-bg-1.png);
  background-repeat: no-repeat;
  background-position: left -200px bottom 150px; }

/*=======================
    Gallery Area 
========================*/
.bg-gallery-area {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F6F5F9), color-stop(71%, #FFF)); }

.dg-gallery-list {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  display: grid; }
  @media only screen and (max-width: 575px) {
    .dg-gallery-list {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 20px; } }
  @media only screen and (max-width: 767px) {
    .dg-gallery-list {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 20px; } }
  .dg-gallery-list .item-grid {
    position: relative; }
    .dg-gallery-list .item-grid a {
      display: block;
      height: 100%;
      width: 100%; }
      .dg-gallery-list .item-grid a .thumbnail {
        height: 100%; }
        .dg-gallery-list .item-grid a .thumbnail img {
          min-height: 100%;
          border-radius: 5px;
          overflow: hidden; }
          @media only screen and (max-width: 767px) {
            .dg-gallery-list .item-grid a .thumbnail img {
              width: 100%; } }
      .dg-gallery-list .item-grid a .hover-action {
        position: absolute;
        right: 30px;
        left: 30px;
        bottom: 21px; }
        .dg-gallery-list .item-grid a .hover-action h3.title {
          font-size: 18px;
          line-height: 1.78;
          color: #fff;
          margin: 0; }
        .dg-gallery-list .item-grid a .hover-action h3.read-more-button {
          font-size: 18px;
          line-height: 1.78;
          color: #fff;
          margin: 0;
          position: absolute;
          left: 0;
          bottom: 0;
          opacity: 0;
          visibility: hidden;
          transform: translateY(20px); }
          .dg-gallery-list .item-grid a .hover-action h3.read-more-button i,
          .dg-gallery-list .item-grid a .hover-action h3.read-more-button span {
            margin-left: 5px; }
    .dg-gallery-list .item-grid:hover a .hover-action h3.title {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-20px); }
    .dg-gallery-list .item-grid:hover a .hover-action h3.read-more-button {
      opacity: 1;
      visibility: visible;
      transform: none; }
    .dg-gallery-list .item-grid.grid-width-2 {
      grid-column: span 2; }
      @media only screen and (max-width: 767px) {
        .dg-gallery-list .item-grid.grid-width-2 {
          grid-column: span 1; } }

.digital-agency-contant {
  position: relative;
  border-radius: 5px;
  overflow: hidden; }
  .digital-agency-contant .thumbnail {
    position: relative; }
    .digital-agency-contant .thumbnail img {
      transition: transform 1s;
      width: 100%; }
    .digital-agency-contant .thumbnail::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .3;
      background-image: linear-gradient(-180deg, transparent 39%, #1c0f69 100%); }
  .digital-agency-contant .heading {
    position: absolute;
    bottom: 23px;
    left: 30px;
    right: 30px;
    margin: 0 auto; }
    .digital-agency-contant .heading h4.title {
      font-size: 18px;
      line-height: 1.78;
      color: #fff;
      margin: 0 auto; }
  .digital-agency-contant:hover .thumbnail img {
    transform: scale(1.1, 1.1); }

/*===================
Ebook Area 
======================*/
.page-template-boxed-with-corner {
  background: url(/assets/images/aeroland/body-special-bg-shape-01.png) right top no-repeat, url(/assets/images/aeroland/body-special-bg-shape-02.png) left top 570px no-repeat, url(/assets/images/aeroland/body-special-bg-shape-03.png) left bottom no-repeat, url(/assets/images/aeroland/body-special-bg-shape-04.png) right bottom no-repeat, #e4e8f6;
  overflow: hidden; }

.page-template-boxed-with-corner-background {
  max-width: 1820px;
  margin: 50px auto;
  box-shadow: none; }
  .page-template-boxed-with-corner-background .site {
    background: #fff;
    overflow: hidden; }

.chanters-bg {
  background-image: url(/assets/images/bg/ebook-image-02.png);
  background-repeat: no-repeat;
  background-position: top left 45%; }

.echanters-inner .thumbnail {
  margin-bottom: -105px !important; }
  .echanters-inner .thumbnail img {
    border: 5px solid #e8e8e8;
    border-radius: 28px;
    -moz-box-shadow: 40px 40px 100px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 40px 40px 100px rgba(0, 0, 0, 0.1);
    box-shadow: 40px 40px 100px rgba(0, 0, 0, 0.1); }

/*======================
    Testimonial Area 
========================*/
.e-testimonial-area .space-top {
  height: 227px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .e-testimonial-area .space-top {
      height: 146px; } }
  @media only screen and (max-width: 767px) {
    .e-testimonial-area .space-top {
      height: 146px; } }

/*=====================
    Clinic Area 
======================*/
/*=======================
    Clinic Portfolio 
========================*/
.clinic-bg-gradient {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F5F7FD), color-stop(100%, #fff));
  background: -moz-linear-gradient(-259deg, #F5F7FD 0, #fff 100%);
  background: -webkit-linear-gradient(-259deg, #F5F7FD 0, #fff 100%);
  background: -o-linear-gradient(-259deg, #F5F7FD 0, #fff 100%);
  background: -ms-linear-gradient(-259deg, #F5F7FD 0, #fff 100%);
  background: linear-gradient(-259deg, #F5F7FD 0, #fff 100%); }

.clinic-portfolio .thumbnail {
  margin-left: -115px;
  text-align: left; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .clinic-portfolio .thumbnail {
      margin-left: 0; } }
  @media only screen and (max-width: 767px) {
    .clinic-portfolio .thumbnail {
      margin-left: 0; } }

.clinic-portfolio .description p {
  font-size: 18px;
  line-height: 1.78;
  color: #696969; }

.clinic-portfolio.clinic-portfolio--right .thumbnail {
  margin-left: 0;
  margin-right: -115px;
  text-align: right; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .clinic-portfolio.clinic-portfolio--right .thumbnail {
      margin-right: 0; } }
  @media only screen and (max-width: 767px) {
    .clinic-portfolio.clinic-portfolio--right .thumbnail {
      margin-right: 0; } }

/*======================
    Digital Store 
========================*/
.digital-store {
  height: 100%; }
  .digital-store .digital-inner {
    margin-top: 30px;
    position: relative;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
    overflow: hidden; }
  .digital-store .thumbnail a img {
    width: 100%;
    border-radius: 8px 8px 0 0; }
  .digital-store .content {
    padding: 30px 40px 40px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .digital-store .content {
        padding: 30px 20px 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .digital-store .content {
        padding: 30px 20px 30px; } }
    @media only screen and (max-width: 767px) {
      .digital-store .content {
        padding: 15px 10px; } }
    .digital-store .content .inner h3.title {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 6px; }
      .digital-store .content .inner h3.title a {
        color: #2e3280; }
    .digital-store .content .inner span.category {
      color: #6d70a6;
      font-size: 14px;
      line-height: 1.29; }
    .digital-store .content .inner p {
      margin-top: 16px;
      color: #696969; }
    .digital-store .content .inner .store-footer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 26px; }
      @media only screen and (max-width: 767px) {
        .digital-store .content .inner .store-footer {
          display: block; } }
      .digital-store .content .inner .store-footer .pricelist {
        margin-left: 12px; }
        @media only screen and (max-width: 767px) {
          .digital-store .content .inner .store-footer .pricelist {
            margin-top: 20px; } }
        .digital-store .content .inner .store-footer .pricelist li {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          font-size: 18px;
          color: #ababab;
          padding: 0 5px;
          margin-right: 10px; }
          .digital-store .content .inner .store-footer .pricelist li.oldprice::after {
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background: #979797; }
          .digital-store .content .inner .store-footer .pricelist li.newprice {
            display: inline-block;
            vertical-align: middle;
            font-size: 24px;
            color: #38cb89;
            font-weight: 600; }
  .digital-store:hover .digital-inner {
    transform: translateY(-5px); }

.digital-store-area .space-separator {
  height: 271px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .digital-store-area .space-separator {
      height: 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .digital-store-area .space-separator {
      height: 100px; } }
  @media only screen and (max-width: 767px) {
    .digital-store-area .space-separator {
      height: 100px; } }

.digitalstore-bg {
  background-image: url(/assets/images/bg/bg-image-11.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom 180px center; }

/*=====================
Page Landing Area 
=======================*/
.landing-hero-area.hero-bg-area {
  background-image: url(/assets/images/preview/landing-hero-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat; }

.landing-hero-area .landing-hero-inner .spacing--1 {
  height: 182px; }

/* Header Area  */
.pre-header-area {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  top: 0;
  padding: 0 100px;
  padding-top: 30px;
  z-index: 22; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pre-header-area {
      padding: 0 30px;
      padding-top: 10px; } }
  @media only screen and (max-width: 767px) {
    .pre-header-area {
      padding: 0 30px;
      padding-top: 30px; } }
  @media only screen and (max-width: 767px) {
    .pre-header-area button.ar-button {
      padding: 0 20px; } }
  .pre-header-area .mainmenu ul.menu {
    display: flex;
    align-items: center;
    justify-content: center; }
    .pre-header-area .mainmenu ul.menu li {
      padding: 50px 26px; }
      .pre-header-area .mainmenu ul.menu li a {
        color: #fff;
        position: relative;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase; }
        .pre-header-area .mainmenu ul.menu li a::after {
          content: "";
          width: 0;
          height: 1px;
          bottom: -3px;
          position: absolute;
          left: auto;
          right: 0;
          z-index: -1;
          background-color: currentColor;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .pre-header-area .mainmenu ul.menu li:hover a::after {
        width: 100%; }

/*=====================
    Filter Button 
======================*/
.demo-tab-button {
  text-align: center; }
  .demo-tab-button li.react-tabs__tab {
    padding: 0;
    border: 0 none;
    position: relative;
    border: 0 none;
    outline: none;
    background: transparent;
    border-radius: inherit; }
    .demo-tab-button li.react-tabs__tab span.filter-text {
      font-weight: 600;
      line-height: 1.58;
      font-size: 16px;
      color: #333333;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: relative;
      display: inline-block;
      padding: 5px 26px; }
    .demo-tab-button li.react-tabs__tab span.filter-counter {
      position: absolute;
      top: 0;
      left: 50%;
      visibility: hidden;
      margin: 0 auto;
      min-width: 34px;
      height: 24px;
      line-height: 25px;
      border-radius: 3px;
      font-size: 12px;
      background-color: #5E61E7;
      color: #fff;
      text-align: center;
      opacity: 0;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-weight: 600; }
      .demo-tab-button li.react-tabs__tab span.filter-counter::before {
        position: absolute;
        right: 0;
        bottom: -4px;
        left: 0;
        display: block;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: 4px solid #5E61E7;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: "";
        border-top-color: #5E61E7;
        border-top: 4px solid #5E61E7;
        border-top-color: #5E61E7; }
    .demo-tab-button li.react-tabs__tab.react-tabs__tab--selected span.filter-text, .demo-tab-button li.react-tabs__tab:hover span.filter-text {
      color: #5E61E7; }
    .demo-tab-button li.react-tabs__tab.react-tabs__tab--selected span.filter-counter, .demo-tab-button li.react-tabs__tab:hover span.filter-counter {
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate(-50%, -100%);
      -ms-transform: translate(-50%, -100%);
      transform: translate(-50%, -100%); }

/*=================
Demo Single 
=================*/
.demo-single {
  margin-top: 50px; }
  @media only screen and (max-width: 767px) {
    .demo-single {
      margin-top: 20px; } }
  .demo-single img {
    position: relative;
    margin-bottom: 29px;
    box-shadow: 0 5px 43px rgba(51, 51, 51, 0.1);
    border-radius: 8px; }
  .demo-single .title {
    text-align: center;
    text-transform: capitalize; }
    .demo-single .title a {
      font-size: 20px;
      line-height: 1.39;
      margin-bottom: 0;
      color: #2e3280; }
  .demo-single:hover img {
    box-shadow: 0 41px 43px rgba(51, 51, 51, 0.1);
    transform: translateY(-20px); }

/*----------------------
    Footer Area  
------------------------*/
.pre-footer-area.footer-bg {
  background: url(/assets/images/preview/preview-footer.jpg);
  background-size: cover;
  background-repeat: no-repeat; }

.pre-footer-area .inner h6 {
  letter-spacing: 7px;
  font-weight: 700; }

/**************************************
    Footer Styles
***************************************/
/*=========================
    Footer Area
===========================*/
.footer-area .footer-top {
  border-top-width: 1px;
  border-top-color: #ededed;
  border-top-style: solid; }

.footer-widget .title {
  padding-bottom: 13px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.34;
  color: #333;
  border-bottom-color: rgba(0, 0, 0, 0);
  margin-bottom: 10px; }

.footer-widget .inner .addres {
  margin-top: 22px; }
  .footer-widget .inner .addres p {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400; }
    .footer-widget .inner .addres p a {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .footer-widget .inner .addres p a:hover {
        color: #5E61E7; }

.footer-widget .inner .author a {
  font-size: 15px;
  color: #38cb89; }
  .footer-widget .inner .author a:hover {
    color: #5E61E7; }

.footer-widget .inner ul.menu {
  padding: 0;
  margin: 0;
  list-style: none; }
  .footer-widget .inner ul.menu li {
    margin-bottom: 10px; }
    .footer-widget .inner ul.menu li a {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.74;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: #7e7e7e; }
      .footer-widget .inner ul.menu li a:hover {
        color: #5E61E7; }

.footer-widget .tweet .tweet-text {
  position: relative;
  font-size: 14px;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 19px 20px 25px;
  margin-bottom: 25px;
  word-wrap: break-word;
  background-color: rgba(89, 69, 230, 0.05); }
  .footer-widget .tweet .tweet-text::before, .footer-widget .tweet .tweet-text::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    transform: rotate(45deg); }
  .footer-widget .tweet .tweet-text::before {
    left: 30px;
    bottom: -12px;
    border-color: transparent #ededed transparent transparent;
    border-width: 12px; }
  .footer-widget .tweet .tweet-text::after {
    left: 33px;
    bottom: -10px;
    border-color: transparent #f7fbfe transparent transparent;
    border-width: 10px; }
  .footer-widget .tweet .tweet-text p {
    margin-bottom: 0; }
    .footer-widget .tweet .tweet-text p a {
      color: #38cb89;
      font-weight: 600; }

.footer-widget .tweet .tweet-info {
  position: relative;
  padding-left: 55px; }
  .footer-widget .tweet .tweet-info::before {
    content: '\f099';
    font-size: 18px;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 23px;
    display: block;
    font-family: 'Font Awesome 5 Brands';
    font-weight: 400;
    color: #5945e6; }
  .footer-widget .tweet .tweet-info .tweet-inner h6 {
    font-size: 14px;
    color: #333;
    margin-bottom: 1px;
    font-weight: 600; }
  .footer-widget .tweet .tweet-info .tweet-inner span {
    font-size: 14px;
    color: #ababab; }

/*======================
    Footer Style Two 
========================*/
.footer-style--2 {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5E60E7), color-stop(65%, #9C7AF2));
  background: -moz-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 65%);
  background: -webkit-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 65%);
  background: -o-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 65%);
  background: -ms-linear-gradient(-259deg, #5E60E7 0, #9C7AF2 65%);
  background: linear-gradient(-259deg, #5E60E7 0, #9C7AF2 65%); }
  .footer-style--2 .copyright-list-inner .copyright-list li {
    display: inline-block;
    padding: 3px 14px;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    position: relative; }
    .footer-style--2 .copyright-list-inner .copyright-list li::before {
      position: absolute;
      background: #ffffff;
      width: 1px;
      height: 16px;
      content: "";
      top: 11px;
      left: 0; }
    .footer-style--2 .copyright-list-inner .copyright-list li:first-child::before {
      display: none; }

/*=====================
Footer Style Three 
=======================*/
.footer-bg-images {
  background-image: url(/assets/images/bg/footer-01.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }

.footer-style--3 {
  position: relative;
  z-index: 2; }
  .footer-style--3 .footer-top .thumbnail {
    margin-bottom: 100px; }
    .footer-style--3 .footer-top .thumbnail img {
      margin-top: -215px;
      position: relative;
      z-index: 2; }
  .footer-style--3 .vc_row-separator {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
    line-height: 0; }
    .footer-style--3 .vc_row-separator.center_curve svg {
      height: 100px;
      fill: #161953;
      width: 100%; }
    .footer-style--3 .vc_row-separator.top {
      top: 0; }
  .footer-style--3 .copyright-inner {
    margin-top: 60px; }
    .footer-style--3 .copyright-inner .content li {
      display: inline-block;
      padding: 3px 14px;
      color: #fff;
      font-size: 15px;
      font-weight: 400; }

/*======================
    Footer Style Four 
=======================*/
.footer-style--4 .footer-top .contact-btn {
  line-height: 1.23;
  color: #38cb89;
  font-size: 18px;
  margin-top: 35px;
  padding: 0;
  border: 0 none;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .footer-style--4 .footer-top .contact-btn {
      margin-top: 10px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-style--4 .footer-top .contact-btn {
      margin-top: 10px; } }
  .footer-style--4 .footer-top .contact-btn::after {
    content: '';
    width: 0;
    height: 1px;
    bottom: 0;
    position: absolute;
    left: auto;
    right: 0;
    z-index: -1;
    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    background: currentColor; }
  .footer-style--4 .footer-top .contact-btn:hover {
    color: #5945e6; }
    .footer-style--4 .footer-top .contact-btn:hover::after {
      width: 100%;
      left: 0;
      right: auto; }

.footer-style--4 .footer-widget h3.title {
  font-size: 16px; }

.footer-style--4 .footer-widget .inner p {
  font-size: 15px;
  font-weight: 400;
  color: #7e7e7e;
  margin-bottom: 10px; }
  .footer-style--4 .footer-widget .inner p a {
    color: #7e7e7e; }

/*======================
    Footer Style Five 
=======================*/
.footer-style--5 .newsletter-inner {
  width: 800px;
  max-width: 100%;
  margin: 0 auto; }
  .footer-style--5 .newsletter-inner input {
    background: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 3px solid #000;
    text-align: center;
    font-size: 48px;
    height: 67px;
    padding: 14px 0 23px;
    border-color: rgba(89, 69, 230, 0.2);
    color: #5945e6;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .footer-style--5 .newsletter-inner input {
        font-size: 26px;
        height: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .footer-style--5 .newsletter-inner input {
        font-size: 26px;
        height: 50px; } }
    .footer-style--5 .newsletter-inner input:focus {
      border-color: #5945e6; }
  .footer-style--5 .newsletter-inner label {
    display: block; }

.footer-style--5 .copyright-inner p {
  padding: 3px 14px;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .footer-style--5 .copyright-inner p {
      padding: 3px 6px; } }
  .footer-style--5 .copyright-inner p + p::before {
    position: absolute;
    top: 8px;
    left: -2px;
    content: '';
    height: 16px;
    width: 1px;
    background: rgba(0, 0, 0, 0.5); }
    @media only screen and (max-width: 767px) {
      .footer-style--5 .copyright-inner p + p::before {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .footer-style--5 .copyright-inner p + p::before {
        display: none; } }

.footer-style--5 .newsletter-inner input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #5945e6; }

.footer-style--5 .newsletter-inner input::-moz-placeholder {
  /* Firefox 19+ */
  color: #5945e6; }

.footer-style--5 .newsletter-inner input:-ms-input-placeholder {
  /* IE 10+ */
  color: #5945e6; }

/*==========================
    Copyright Area 
============================*/
.copyright-style--1 {
  border-top-width: 1px;
  border-top-color: #ededed;
  border-top-style: solid;
  padding-top: 31px;
  padding-bottom: 31px; }

.backto-top div {
  z-index: 9 !important; }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table; }

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }
